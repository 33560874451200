import React, {useState} from 'react';
import {Grid, Tab, Tabs} from "@mui/material";
import AdminCvOrdersTab from "../tabs/AdminCvOrdersTab";
import AdminCvUserTab from "../tabs/AdminCvUserTab";
import AdminHtmlEditor from "../tabs/AdminHtmlEditor";

const AdminCvOrders = ({wordsWithLang, control, setControl}) => {
    // const [control, setControl] = useState({callGetAllCvOrders: false, callGetAllUsers:false})
    const [tabVal, setTabVal] = useState("one")

    // apiUrls.getAdminCvOrders:"cvOrders/{page}",
    // apiUrls.postAdminCvOrders:"cvOrders",
    // apiUrls.postAdminCvData:"cvOrders/{id}/cvData/{comeForUpdate}",
    // apiUrls.getAdminGenPreview:"cvOrders/{id}/genPreview",
    // apiUrls.postAdminUploadHtml:"cvOrders/{id}/uploadHtml",
    // apiUrls.getAdminReplacePdf:"cvOrder/{id}/replacePdf",



    return (
        <div>
            <Grid container>
                <Grid item xs={12} mt={0} mb={1}>
                    <Tabs
                        value={tabVal}
                        onChange={(e, val) => {setTabVal(val);}}
                    >
                        <Tab value="one" label="CV ORDERS"/>
                        <Tab value="two" label="HTML EDITOR"/>
                        <Tab value="three" label="OTHER"/>
                    </Tabs>
                </Grid>
                <Grid container display={tabVal === "one" ? "grid" : "none"}>
                    <AdminCvOrdersTab setControl={setControl} control={control} wordsWithLang={wordsWithLang} />
                </Grid>
                <Grid container display={tabVal === "two" ? "grid" : "none"}>
                    {/*<AdminCvUserTab control={control} setControl={setControl} />*/}
                    {<AdminHtmlEditor/>}
                </Grid>
            </Grid>

        </div>
    );
};

//
// // --------------styles---------------
//
const dividerSx = {
    height: "1rem",
    marginRight: 1,
    marginLeft: 1,
    display: "inline-flex",
    verticalAlign: "middle",
}


export default AdminCvOrders;
