import React, { useState, useRef, useEffect } from 'react';
import { BottomNavigation } from '@mui/material';
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
    width: '42em',
    height: '5em',
    // backgroundColor: '#fefefe',
    background:"none",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1.9em',
    margin: '0 auto',
    overflow: 'hidden',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
    },
}));

const StyledButtonBase = styled('button')(({ theme }) => ({
    all: 'unset',
    margin: '0 0px',  // Adjust margin to bring icons closer
    flexGrow: 1,
    maxWidth: 'none',
    color: '#828282',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',  // Center items vertically
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '0.5em 0',
    transition: 'flex-grow 0.45s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    '& .icon': {
        fontSize: '1.5em',
        transition: 'transform 0.45s cubic-bezier(0.4, 0, 0.2, 1)',
        marginRight: '5px',
    },
    '& .label': {
        fontSize: '1.5em',
        textTransform: 'capitalize',
        letterSpacing: '.01em',
        opacity: 0,  // Hide text initially
        transition: 'transform 0.12s, opacity 0.12s',
    },
    '&.active': {
        flexGrow: 2.7,
        color: '#4C3198',
        '& .icon': {
            transform: 'translateX(-50%)',  // Adjust icon transform for proper alignment
        },
        '& .label': {
            opacity: 1,  // Show text when active
            transition: 'opacity 0.3s',
        },
        '&::after': {
            content: "''",
            position: 'absolute',
            left: '0',
            bottom: '0',
            height: '0.25em',
            width: 'calc(var(--lineWidth) + 5px)',
            backgroundColor: 'currentColor',
            borderRadius: '2em',
            transform: 'translateX(6.3em) scaleX(1)',
            transition: 'transform 0.45s cubic-bezier(0.4, 0, 0.2, 1)',
        },
    },
    '&::after': {
        content: "''",
        position: 'absolute',
        left: '-53px !important',
        bottom: '0',
        height: '0.25em',
        width: 'calc(var(--lineWidth) + 5px)',
        backgroundColor: 'currentColor',
        borderRadius: '2em',
        transform: 'translateX(3em) scaleX(0)',
        transition: 'transform 0.65s cubic-bezier(0.4, 0, 0.2, 1)',
    },
}));

const MenuWithIcons = () => {
    const [value, setValue] = useState(0);
    const buttonsRef = useRef([]);
    const labelsRef = useRef([]);

    const menuItems = [
        { label: 'home', icon: <HomeIcon /> },
        { label: 'strategy', icon: <SportsEsportsIcon /> },
        { label: 'period', icon: <CalendarTodayIcon /> },
        { label: 'security', icon: <SecurityIcon /> },
        { label: 'settings', icon: <SettingsIcon /> },
    ];

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const setWidths = () => {
            buttonsRef.current.forEach((button, index) => {
                if (button && labelsRef.current[index]) {
                    const labelWidth = labelsRef.current[index].offsetWidth + 'px';
                    button.style.setProperty('--lineWidth', labelWidth);
                }
            });
        };

        setWidths();
        window.addEventListener('resize', setWidths);

        return () => {
            window.removeEventListener('resize', setWidths);
        };
    }, []);

    return (
        <StyledBottomNavigation>
            {menuItems.map((item, index) => (
                <StyledButtonBase
                    key={index}
                    className={value === index ? 'active' : ''}
                    onClick={() => handleChange(index)}
                    ref={(el) => (buttonsRef.current[index] = el)}
                >
                    <div className="icon">{item.icon}</div>
                    <strong
                        className={`label ${value === index ? 'active' : ''}`}
                        ref={(el) => (labelsRef.current[index] = el)}
                    >
                        {item.label}
                    </strong>
                </StyledButtonBase>
            ))}
        </StyledBottomNavigation>
    );
};

export default MenuWithIcons;



// import React, { useState, useEffect, useRef } from 'react';
// import './style.css'; // Assuming you have a separate CSS file
//
// const AppBar = () => {
//     const [menuActive, setMenuActive] = useState(false);
//     const menuRef = useRef(null); // Ref for the menu
//     const burgerMenuRef = useRef(null); // Ref for the burger button
//
//     const toggleMenu = () => {
//         setMenuActive(!menuActive);
//     };
//
//     // Handle click outside the menu and burger button
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (
//                 menuRef.current &&
//                 !menuRef.current.contains(event.target) &&
//                 burgerMenuRef.current &&
//                 !burgerMenuRef.current.contains(event.target)
//             ) {
//                 setMenuActive(false); // Close the menu
//             }
//         };
//
//         // Add event listener for clicks
//         document.addEventListener('click', handleClickOutside);
//
//         // Cleanup the event listener
//         return () => {
//             document.removeEventListener('click', handleClickOutside);
//         };
//     }, []);
//
//     // Handle scroll to add/remove the "scrolled" class
//     useEffect(() => {
//         const handleScroll = () => {
//             const appBar = document.querySelector('.app-bar');
//             if (window.scrollY > 50) {
//                 appBar.classList.add('scrolled');
//             } else {
//                 appBar.classList.remove('scrolled');
//             }
//         };
//
//         window.addEventListener('scroll', handleScroll);
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, []);
//
//     return (
//         <div className="app-bar">
//             <div className="logo">
//                 <img src="path/to/logo.png" alt="Logo" />
//             </div>
//
//             <button
//                 className="burger-menu"
//                 onClick={toggleMenu}
//                 ref={burgerMenuRef} // Attach ref to the burger button
//             >
//                 <span></span>
//                 <span></span>
//                 <span></span>
//             </button>
//
//             <nav
//                 className={`menu ${menuActive ? 'active' : ''}`}
//                 ref={menuRef} // Attach ref to the menu
//             >
//                 <ul>
//                     <li><a href="#">Home</a></li>
//                     <li><a href="#">Products</a></li>
//                     <li><a href="#">Services</a></li>
//                     <li><a href="#">Contact</a></li>
//                 </ul>
//             </nav>
//
//             <div className="right-section">
//                 <select className="language-selector">
//                     <option value="en">English</option>
//                     <option value="es">Español</option>
//                     <option value="fr">Français</option>
//                 </select>
//
//                 <div className="avatar">
//                     <img src="path/to/avatar.png" alt="User Avatar" />
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// const HeroBanner = () => {
//     return (
//         <div className="hero-banner">
//             <div className="banner-content">
//                 <h1>Summer Collection 2024</h1>
//                 <p>Discover the latest trends and exclusive offers</p>
//                 <button className="cta-button">Shop Now</button>
//             </div>
//             <div className="banner-image">
//                 <img src="path/to/banner-image.jpg" alt="Summer Collection" />
//             </div>
//         </div>
//     );
// };
//
// const Content = () => {
//     return (
//         <div className="content">
//             <section>
//                 <h1>Welcome to Our Website</h1>
//                 <p>This is a sample content section to demonstrate scrolling.</p>
//             </section>
//
//             <section>
//                 <h2>Section 1</h2>
//                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
//             </section>
//
//             <section>
//                 <h2>Section 2</h2>
//                 <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//             </section>
//
//             <section>
//                 <h2>Section 3</h2>
//                 <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
//             </section>
//
//             <section>
//                 <h2>Section 4</h2>
//                 <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
//             </section>
//         </div>
//     );
// };
//
// const Appp = () => {
//     return (
//         <div>
//             <AppBar />
//             <HeroBanner />
//             <Content />
//         </div>
//     );
// };
//
// export default Appp;




// /* App.css */
// body{
//     margin: 0;
//     padding: 0 !important;
// }
// .app-bar {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0.7rem 2rem;
//     background-color: #ffffff;
//     box-shadow: 0 2px 4px rgba(0,0,0,0.1);
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     transition: all 0.3s ease;
//     height: 65px;
//     z-index: 1000;
// }
//
// .app-bar.scrolled {
//     height: 45px;
//     padding: 0.3rem 1.5rem;
// }
//
// .logo img {
//     height: 35px;
//     transition: all 0.3s ease;
// }
//
// .app-bar.scrolled .logo img {
//     height: 25px;
// }
//
// .burger-menu {
//     display: none;
//     flex-direction: column;
//     gap: 6px;
//     background: none;
//     border: none;
//     cursor: pointer;
//     padding: 5px;
// }
//
// .burger-menu span {
//     display: block;
//     width: 25px;
//     height: 3px;
//     background-color: #333;
//     transition: 0.3s;
// }
//
// .menu ul {
//     display: flex;
//     list-style: none;
//     gap: 2rem;
//     margin: 0;
//     padding: 0;
// }
//
// .menu a {
//     text-decoration: none;
//     color: #333;
//     font-weight: 500;
// }
//
// .right-section {
//     display: flex;
//     align-items: center;
//     gap: 1rem;
// }
//
// .language-selector {
//     padding: 0.5rem;
//     border-radius: 4px;
//     border: 1px solid #ddd;
// }
//
// .avatar img {
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//     object-fit: cover;
//     transition: all 0.3s ease;
// }
//
// .app-bar.scrolled .avatar img {
//     width: 25px;
//     height: 25px;
// }
//
// .app-bar.scrolled .language-selector {
//     padding: 0.3rem;
//     font-size: 0.9rem;
// }
//
// body {
//     padding-top: 100px;
// }
//
// @media (max-width: 768px) {
// .burger-menu {
//         display: flex;
//     }
//
// .menu {
//         position: fixed;
//         top: -100%;
//         left: 0;
//         right: 0;
//         background: white;
//         padding: 2rem;
//         transition: top 0.3s ease-in-out;
//         box-shadow: 0 2px 4px rgba(0,0,0,0.1);
//     }
//
// .menu.active {
//         top: 0;
//     }
//
// .menu ul {
//         flex-direction: column;
//         align-items: center;
//     }
//
// .menu li {
//         width: 100%;
//         text-align: center;
//         padding: 1rem 0;
//         border-bottom: 1px solid #eee;
//     }
//
// .banner {
//         font-size: 0.8rem;
//         padding: 0.4rem;
//     }
// }
//
// .content {
//     max-width: 800px;
//     margin: 0 auto;
//     padding: 2rem;
// }
//
// section {
//     margin-bottom: 3rem;
// }
//
// h1, h2 {
//     color: #333;
//     margin-bottom: 1rem;
// }
//
// p {
//     line-height: 1.6;
//     color: #666;
// }
//
// .banner {
//     position: fixed;
//     top: 65px;
//     left: 0;
//     right: 0;
//     background-color: #f8f9fa;
//     padding: 0.5rem;
//     text-align: center;
//     font-size: 0.9rem;
//     border-bottom: 1px solid #eee;
//     z-index: 999;
//     transition: all 0.3s ease;
// }
//
// .banner.scrolled {
//     top: 45px;
// }
//
// .banner p {
//     margin: 0;
//     color: #333;
// }
//
// .hero-banner {
//     margin-top: 65px; /* Match app bar height */
//     height: 400px;
//     width: 100%;
//     background-color: #f8f9fa;
//     display: flex;
//     align-items: center;
//     position: relative;
//     overflow: hidden;
// }
//
// .banner-content {
//     width: 50%;
//     padding: 0 5%;
//     z-index: 2;
// }
//
// .banner-content h1 {
//     font-size: 2.5rem;
//     margin-bottom: 1rem;
//     color: #333;
// }
//
// .banner-content p {
//     font-size: 1.2rem;
//     margin-bottom: 2rem;
//     color: #666;
// }
//
// .cta-button {
//     padding: 12px 30px;
//     font-size: 1.1rem;
//     background-color: #333;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
// }
//
// .cta-button:hover {
//     background-color: #555;
// }
//
// .banner-image {
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 50%;
//     height: 100%;
// }
//
// .banner-image img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }
//
// @media (max-width: 768px) {
// .hero-banner {
//         height: 500px;
//         flex-direction: column;
//         text-align: center;
//     }
//
// .banner-content {
//         width: 100%;
//         padding: 2rem;
//     }
//
// .banner-content h1 {
//         font-size: 2rem;
//     }
//
// .banner-image {
//         position: relative;
//         width: 100%;
//         height: 250px;
//     }
// }
