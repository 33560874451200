import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    Chip,
    Divider,
    Grid,
    styled,
    Tab, Tabs,
    Typography
} from "@mui/material";
import {
    ArrowBack,
    AutoFixHigh,
    ImageNotSupported,
    Info,
    InsertPhoto, ModeEdit,
    NoPhotography,
    TaskAlt, WarningAmber, Yard
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import ColorPicker from "../../miniComponents/colorPicker";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {useLocation, useNavigate} from "react-router-dom";
import TemplateScroll from "../../components/cvApp/templateScroll";
import LevelStyleScroll from "../../components/cvApp/levelStyleScroll";
import CsChip from "../../miniComponents/CsChip";
import {deepPurple} from "@mui/material/colors";
import CustomDialog from "../../miniComponents/CustomDialog";

const CvAppTemplate = ({wordsWithLang, adminCvOrder}) => {
    const dispatcher = useDispatch();
    const cvAppConfig = useSelector(state => state.cvAppConfig)
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)
    const userConfig = useSelector(state => state.userConfig)

    const [openActiveEdit, setOpenActiveEdit] = useState(false)
    const [cvOrderStatus, setCvOrderStatus] = useState(newCvOrder.status === "SUCCESS" || newCvOrder.status === "DRAFT2")

    const [isFreeSelected,setIsFreeSelected] = useState(false)

    useEffect(() => {
        if (adminCvOrder!==undefined && adminCvOrder!==null) {
            dispatcher({type:"SET_USER", payload:adminCvOrder.customUser})
            dispatcher({type:"CV_DATA_UPDATE", payload:adminCvOrder.cvData})
            dispatcher({type:"CV_INSTANCE_UPDATE", payload:adminCvOrder})
        }
    }, []);



    const isMobile = window.screen.width <= 700
    const [color, setColor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [buttonWithImage, setButtonWithImage] = useState(true)

    const [templates, setTemplates] = useState([])
    const [tempTemplates, setTempTemplates] = useState([])
    const [selectedLevelStyle, setSelectedLevelStyle] = useState(cvAppConfigCvData.levelStyleName)


    const selectTemplate = (id) => {
        let tempData = cvAppConfigCvData
        tempData.templateId = id
        dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
        updateCvDataInServer("templateId")
        dispatcher({type: "SELECTED_CV_UPDATE", payload: templates.filter(template => template.id === id)[0]})
    }

    const selectLevelStyle = (levelStyleName) => {
        let tempData = cvAppConfigCvData
            tempData.levelStyleName = levelStyleName
            dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
            setSelectedLevelStyle(levelStyleName)
        // }
        updateCvDataInServer("levelStyleName",tempData)
        // dispatcher({type: "SELECTED_CV_UPDATE", payload: levelStyleNames.filter(levelStyle => levelStyle === levelStyleName)[0]})
    }

    const updateColor = (customColor) => {
        if (cvAppConfigCvData.mainColor === null) {
            setColor("#3F51B5")
            dispatcher({type: "CV_DATA_UPDATE", payload: {...cvAppConfigCvData, mainColor: "#3F51B5"}})
            console.log("mainColor " + customColor)
            updateCvDataInServer("mainColor", {...cvAppConfigCvData, mainColor: "#3F51B5"})
        } else {
            setColor(customColor)
            dispatcher({type: "CV_DATA_UPDATE", payload: {...cvAppConfigCvData, mainColor: customColor}})
            updateCvDataInServer("mainColor", {...cvAppConfigCvData, mainColor: customColor})

        }

    }

    const updateCvDataInServer = (comeForUpdate, data) => {
        if (data !== undefined) {
            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), data,
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(resp => {
                // dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
            })
        } else {
            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), cvAppConfigCvData,
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(resp => {
                // dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
            })
        }

    }

    const continueCvGen = () => {
        setIsLoading(true)
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{CvOrderId}", newCvOrder.id).replace("{productId}", cvAppConfigCvData.templateId),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(resp1 => {
            // dispatcher({type: "CV_DATA_UPDATE", payload: resp1.data.cvData})
            // dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp1.data})


            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGeneratePreviewOfCv.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(resp => {
                setIsLoading(false)
                dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "preview"})
            })
        })
    }

    const getTemplates = () => {
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{productType}", "CV_TEMPLATE"), {withCredentials: true}).then(resp => {
            setTemplates(resp.data)
            if (cvAppConfigCvData.templateId !== null) {
                const selectedTemp = resp.data.filter(template => template.id === cvAppConfigCvData.templateId)[0];
                dispatcher({type: "SELECTED_CV_UPDATE", payload: selectedTemp})
                if (selectedTemp.specs.includes("image:true")) {
                    productFiltering(resp.data, "image:true")
                    return
                } else {
                    productFiltering(resp.data, "image:false")
                    return
                }
            }
            productFiltering(resp.data, "image:true")

        })
        // }
    }

    const productFiltering = (products, spec) => {
        setButtonWithImage(spec.includes("image:true") ? true : false)
        const filteredProducts = [];

        if (spec.trim() !== "") {
            if (spec.includes("_")) {
                const specs = spec.split("_");
                specs.forEach(s => {
                    products.forEach(product => {
                        if (product.specs.includes(s)) {
                            filteredProducts.push(product);
                        }
                    });
                });
            } else {
                products.forEach(product => {
                    if (product.specs.includes(spec)) {
                        filteredProducts.push(product);
                    }
                });
            }
            setTempTemplates(filteredProducts.sort((a, b) => a.title.az.localeCompare(b.title.az)))
        }
    }


    const BlinkingTypography = styled(Typography)`
        @keyframes blink {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        animation: blink 3s linear infinite;
    `;
    useEffect(() => {
        updateColor(cvAppConfigCvData.mainColor)
        selectLevelStyle(cvAppConfigCvData.levelStyleName)
    }, [])

    // useEffect(()=>{updateCvDataInServer("mainColor")}, [cvAppConfigCvData])


    useEffect(() => {
        getTemplates()
    }, [])

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, []);

    useEffect(() => {
        setCvOrderStatus(newCvOrder.status === "DONE" || newCvOrder.status === "DRAFT2"|| newCvOrder.status === "")
        // setCvOrderStatus(true)
    }, [newCvOrder.status]);

    useEffect(()=>{
        if(isFreeSelected===true){
            selectLevelStyle("createProgressBar")
        }
    },[isFreeSelected])

    window.onpopstate = function () {
        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
    }




    return (
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8} display={"flex"} flexDirection={"column"}
                  alignItems={"flex-start"}>
                <Button
                    color="violet"
                    variant="contained"
                    size="large"
                    style={{marginLeft: 23, borderRadius: 30, color: "white"}}
                    onClick={() => {
                        navigate({pathname: '/cv', hash: "edit"})
                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
                    }}
                >
                    <ArrowBack fontSize="small"/>
                    <Typography style={{marginLeft: 3, fontSize: isMobile ? "13px" : "15px",}} variant="button">
                        {wordsWithLang.vacancyBody.back}
                    </Typography>
                </Button>
            </Grid>
            <Grid container justifyContent="center" style={{padding: "10px", marginTop: "25px"}}>
                <Grid item xs={11}>
                    {/*<Typography fontSize={isMobile ? "25px" : "15"}*/}
                    {/*            ml={5}>{wordsWithLang.cvApp.cvAppForms.choseColor}</Typography>*/}
                    <Typography ml={2} style={{
                        fontSize: "1.2rem",
                        alignSelf: "flex-start",
                        marginBottom: 20
                    }}>{wordsWithLang.cvApp.cvAppForms.choseColor}</Typography>

                    <ColorPicker onColorSelect={updateColor} selectedColorExist={color ? color : null}/>
                    {color && (
                        <Box mt={2}>
                            {/*<Typography fontSize={isMobile?"25px":"15"} mb={1} ml={5} mt={2}>{wordsWithLang.cvApp.cvAppForms.chosenColor}</Typography>*/}
                            <Box
                                mr={1}
                                width={"25%"}
                                height={35}
                                onClick={() => {
                                    updateColor(color)
                                }}
                                style={{
                                    transition: 'all .3s',
                                    boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
                                    backgroundColor: color, margin: '0 auto', borderRadius: '35px', left: "10%"
                                }}
                            >
                                {/*<TaskAlt fontsize={"large"} style={{color: "#ffffff", marginLeft: "3px"}}/>*/}
                            </Box>
                        </Box>
                    )}

                </Grid>

                {/*<Grid item xs={12} style={{margin: 20}}>*/}
                {/*    <Divider/>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={11}>*/}
                {/*    stil secimi*/}
                {/*</Grid>*/}


                <Grid item xs={12} style={{margin: 20}}>
                    <Divider/>

                </Grid>

                <Grid item xs={11}>

                    <Button color={"secondary"} variant={"outlined"} sx={{borderRadius:20,marginBottom:1,display:cvOrderStatus?"flex":"none"}} startIcon={<ModeEdit/>}
                            onClick={()=>{setOpenActiveEdit(true)}}>{wordsWithLang.cvApp.cvAppForms.activateEditTemplate}</Button>

                    <CustomDialog wordsWithLang={wordsWithLang} open={openActiveEdit} onYes={()=>{
                        axios.get(apiUrls.baseApiUrl+apiUrls.updateCvOrderStatus.replace("{id}",newCvOrder.id),{withCredentials: true}).then(resp=>{
                            if(resp.data === "OK"){
                                setCvOrderStatus(false)
                                setOpenActiveEdit(false)
                                dispatcher({type: "CV_INSTANCE_UPDATE", payload: {...newCvOrder, status: "DRAFT", paymentStatus:"pending"}})
                            }
                        })
                    }} header={wordsWithLang.cvApp.cvAppForms.activateEditTemplate} question={wordsWithLang.cvApp.cvAppForms.activateEditPersonalInside} onClose={()=>{setOpenActiveEdit(false)}  }/>

                    <Alert fontSize={isMobile ? "1rem" : "0.6rem"} variant={"outlined"}
                           icon={<Info sx={{color: "#4C3198"}}/>}
                           sx={{
                               '& .MuiAlert-message': {textWrap: "auto", fontWeight: "500"},
                               color: "#4C3198",
                               borderColor: "#4C3198",
                               display:!cvOrderStatus?"flex":"none"
                           }}
                           style={{
                               borderRadius: '1rem',
                               width: "auto",
                               maxWidth: "100%",
                               marginTop: "15px",
                               marginBottom: "15px",
                               alignSelf: "flex-start"
                           }}>
                        {wordsWithLang.cvApp.cvAppForms.warningCanNotEditAfterBuyLevelStylesAndTemplates}
                    </Alert>

                    < div className={cvOrderStatus?"disabledSection":""}>

                    <Typography ml={2} style={{
                        fontSize: "1.2rem",
                        alignSelf: "flex-start",
                        marginBottom: 20
                    }}>{wordsWithLang.cvApp.cvAppForms.choseLevelStyle}</Typography>
                        {selectedLevelStyle && (<LevelStyleScroll
                        // style={{margin: "30px",}}
                        // templates={[]}
                        className={"scroll-container"}
                        selectTemplate={selectLevelStyle}
                        selectedTemplate={selectedLevelStyle}
                        isFreeSelected={isFreeSelected}
                        wordsWithLang={wordsWithLang}
                    />)}
                    </div>
                </Grid>
                <Grid item xs={11}>
                    < div className={cvOrderStatus?"disabledSection":""}>

                    <Typography ml={2} style={{
                        fontSize: "1.2rem",
                        alignSelf: "flex-start",

                    }}>{wordsWithLang.cvApp.cvAppForms.choseTemplate}</Typography>
                    <Box justifyContent={"center"} display={"flex"}>
                        <Tabs
                            variant={"fullWidth"}
                            value={buttonWithImage ? "one" : "two"}
                            color="violet"
                            textColor="violet"
                            sx={{
                                minWidth:"406px",
                                marginBottom: 2,
                                marginTop: 2,
                                // borderRadius: 40,


                            }}
                            TabIndicatorProps={{
                                style: {
                                    height: "2px",
                                    marginBottom: "4px",
                                    backgroundColor: "#4c3198",
                                },
                            }}
                        >
                            <Tab
                                sx={{
                                    borderColor: "#4c3198",
                                    color:!buttonWithImage ? "gray":"#4c3198",
                                }}
                                value="one"
                                label={<Typography fontWeight={"bold"} style={{display:"flex",alignItems:"center"}} >
                                            <InsertPhoto style={{margin: 5, marginLeft: 10}}/>
                                            {wordsWithLang.cvApp.cvAppForms.template.withPhotos}
                                </Typography>}
                                onClick={() => productFiltering(templates, "image:true")}
                            />
                            <Tab
                                sx={{
                                    borderColor: "#4c3198",
                                    color:buttonWithImage ? "gray":"#4c3198",
                                }}
                                value="two"
                                label={<Typography fontWeight={"bold"} style={{display:"flex",alignItems:"center"}} >
                                            {wordsWithLang.cvApp.cvAppForms.template.withoutPhotos}
                                            <ImageNotSupported style={{margin: 5, marginRight: 10}}/>
                                </Typography>}
                                onClick={() => productFiltering(templates, "image:false")}
                            />
                        </Tabs>

                    </Box>
                    <Alert
                        fontSize={isMobile ? "1rem" : "0.6rem"}
                        variant={"outlined"}
                        // icon={<Info sx={{color: "#4C3198"}}/>}
                        color={"warning"}
                        icon={<WarningAmber />}
                        sx={{
                            '& .MuiAlert-message': {textWrap: "auto", fontWeight: "500"},
                        }}
                        style={{
                            borderRadius: '1rem',
                            width: isMobile ? "87vw" : "auto",
                            maxWidth: "800px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            alignSelf: "flex-start",
                            display:!newCvOrder.cvData.avatarUrl && buttonWithImage?"flex":"none"
                        }}>
                        {wordsWithLang.cvApp.cvAppForms.noAvatarMessage}
                    </Alert>
                    <TemplateScroll
                        // style={{margin: "30px",}}
                        wordsWithLang={wordsWithLang}
                        isAvatar={newCvOrder.cvData.avatarUrl}
                        isWithPhotoTab={buttonWithImage}
                        cvLanguage={newCvOrder.language}
                        className={"scroll-container"}
                        templates={tempTemplates}
                        selectTemplate={selectTemplate}
                        selectedTemplate={cvAppConfigCvData.templateId} // or any default selected template ID
                        setIsFreeSelected={setIsFreeSelected}
                    />
                    </div>

                    <Alert fontSize={isMobile ? "1rem" : "0.6rem"} variant={"outlined"}
                           icon={<Info sx={{color: "#4C3198"}}/>}
                           sx={{
                               '& .MuiAlert-message': {textWrap: "auto", fontWeight: "500"},
                               color: "#4C3198",
                               borderColor: "#4C3198"
                           }}
                           style={{
                               borderRadius: '1rem',
                               width: isMobile ? "87vw" : "auto",
                               maxWidth: "800px",
                               marginTop: "15px",
                               marginBottom: "15px",
                               alignSelf: "flex-start"
                           }}>
                        {wordsWithLang.cvApp.cvAppForms.nextPageColored}
                    </Alert>


                    {/*{templates.map((temp) => (*/}
                    {/*    <CvAppTemplateCard key={temp.id} template={temp} selectTemplate={selectTemplate}*/}
                    {/*                       selectedTemplate={cvAppConfigCvData.templateId}/>*/}
                    {/*))}*/}
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                </Grid>


                {/*<Grid item xs={12} md={6} lg={6} p={3 }>*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}*/}
                {/*        className="addButton continueButton btn"*/}
                {/*        size="small"*/}
                {/*        style={{width: '100%', marginTop: 15}}*/}
                {/*        onClick={()=>{*/}
                {/*            if(cvAppConfigCvData.cvTemplateId === "" || cvAppConfigCvData.cvTemplateId === null){*/}
                {/*                dispatcher({*/}
                {/*                    type: "OPEN_SNACK",*/}
                {/*                    payload: {"isSnackOpen": true, snackType: "error", snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.templateNotChosen}*/}
                {/*                })*/}
                {/*                }*/}
                {/*            else {*/}

                {/*            }*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {wordsWithLang.cvApp.cvAppForms.complete}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}


                {/*<Grid item justifyContent="center" display={"flex"} xs={2} style={{margin: "30px"}}>*/}
                {/*</Grid>*/}

            </Grid>

            <Grid container justifyContent={"center"}
                  style={{
                      boxShadow: "0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3)",
                      position: isMobile !== null ? 'fixed' : 'static',
                      bottom: isMobile !== null ? 0 : 'auto',
                      left: isMobile !== null ? 0 : 'auto',
                      width: isMobile !== null ? '100%' : 'auto',
                      zIndex: isMobile !== null ? 999 : 'auto',
                      backgroundColor: "white"
                  }}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {/*<Grid item xs={10}>*/}
                {/*    <Grid container>*/}
                {/*        <Typography>Yekun mebleg: <span>{totalPrice()}</span></Typography>*/}
                {/*    </Grid>*/}

                {/*</Grid>*/}
                <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        variant="contained"
                        startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}
                        className={cvAppConfigCvData.templateId ? "addButton continueButton btn" : "disabledButton continueButton btn"}
                        size="small"
                        style={{width: isMobile !== null ? '85%' : '65%', margin: "20px 20px 40px 40px"}}
                        onClick={() => {
                            if (cvAppConfigCvData.templateId === "" || cvAppConfigCvData.templateId === null) {
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        isSnackOpen: true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.templateNotChosen
                                    }
                                });
                            } else {
                                // if(cvAppConfigCvData.mainColor === null) {
                                //     updateColor("#3F51B5")
                                // }
                                navigate({pathname: '/cv', hash: "preview"})
                                continueCvGen();
                            }
                        }}
                    >
                        {wordsWithLang.cvApp.cvAppForms.complete}
                    </Button>
                </Grid>
            </Grid>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300}}
                open={isLoading}
            >
                {/*<CircularProgress color="inherit"/><br/>*/}
                <div className="scan-container">
                    <img src="https://bakujobs.az/img/cv.svg" alt="Document" width={200} height={200}/>
                    <div className="scan-line"></div>
                    <BlinkingTypography align={"center"}
                                        width={"100%"}>{wordsWithLang.cvApp.cvAppForms.cvGettingReady}</BlinkingTypography>
                </div>
            </Backdrop>
        </Grid>
    );
};

export default CvAppTemplate;
