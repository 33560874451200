import React from 'react';
import {Dialog, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import LoginRegister from "../components/loginRegister";
import LoginLogin from "../components/loginLogin";


const LoginDialog = ({wordsWithLang}) => {
    const mainConfig = useSelector(state => state.mainConfig)
    const dialogPage = mainConfig.loginDialogPage


    const dispatcher = useDispatch()
    const theme = useTheme();
    const handleClose = () => {
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={mainConfig.isLoginOpen}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 15}
            }}
        >
            {dialogPage === 0 ? (
                <LoginLogin theme={theme} wordsWithLang={wordsWithLang}/>
            ) : dialogPage === 1 ? (
                <LoginRegister theme={theme} wordsWithLang={wordsWithLang}/>
            ) :(<div>ERROR</div>  )}
        </Dialog>
    );
};

export default LoginDialog;
