import React, {useState} from 'react';
import {Box, Button, Chip, Divider, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {Add, AutoAwesome, Clear, Delete, Edit, LockClock} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import GradientCircularProgress from "../../miniComponents/GradientCircularProgress";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {apiGetUserInfo} from "../../utils/apiInterface";

const NoteAccordion = ({wordsWithLang, cvAppConfigCvData,
                           checkAllErrors, updateCvDataInServer, cvLanguage, aiBalance}) => {
    const [note, setNote] = useState("");
    const dispatcher = useDispatch();
    const navigate = useNavigate()

    const [aiButtonClicked, setAiButtonClicked] = useState(false)

    const [aiSumData, setAiSumData] = useState({
        "sumType": "NOTES",
        // "summary": "NO_BALANCE",
        "language": cvLanguage,
        "message": "",
    })

    const getAiSum =  () => {
        aiSumData.message = note
        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostAISummary, aiSumData, {withCredentials: true}).then(async resp => {
            setAiSumData(resp.data)
            if (resp.data.summary !== "NO_BALANCE" && resp.data.summary !== "") {
                setNote(resp.data.summary)
                apiGetUserInfo(dispatcher)
                setAiButtonClicked(false)
            }
        })
    }

    const renderAIButtons = () => {
        // console.log(aiSumData)
        if (aiSumData.summary === "NO_BALANCE") {
            return (<Button className={"text-shine"} disabled variant={"outlined"} color="violet" sx={{
                minHeight:"26.5px",
                opacity: aiSumData.summary === "NO_BALANCE" ? "1" : "0",
                borderRadius: "20px",
                transition: "all 0.5s, display 0.5s allow-discrete;"
            }} startIcon={<LockClock/>}><Typography>{wordsWithLang.cvApp.cvAppForms.correctWithAiLimit}</Typography></Button>)
        }
        if (aiButtonClicked) {
            return (<Button variant={"outlined"} disabled color="violet"  onClick={(e) => {
                setAiButtonClicked(false)
            }} sx={{
                color:"black",
                minHeight:"26.5px",
                opacity: !aiButtonClicked ? "0" : "1",
                borderRadius: "20px",
                transition: "all 0.5s, display 0.5s allow-discrete;"
            }} startIcon={<GradientCircularProgress/>}><Typography
                className={"animate__animated animate__flash animate__slower animate__infinite"}>
                {wordsWithLang.cvApp.cvAppForms.correctWithAiWait}</Typography></Button>)
        }
        return (<Button color="violet"
                        variant="outlined"
                        onClick={(e) => {
                            getAiSum();
                            setAiButtonClicked(true)
                        }} className={"text-shine"}
                        sx={{
                            // marginLeft: 23, borderRadius: 30, color: "#4c3198",
                            // backgroundImage: "linear-gradient(to right, #4c3198 0, #824fff 30%, #4c3198 60%)",
                            borderColor: "#4c3198",
                            color:"#4c3198",
                            opacity: aiButtonClicked ? "0" : "1",
                            borderRadius: "20px",
                            transition: "all 0.5s, display 0.5s allow-discrete;"
                        }} startIcon={<AutoAwesome color={"secondary"} className={"animate__pulse animate__animated  animate__infinite"}/>}>
            {wordsWithLang.cvApp.cvAppForms.correctWithAi} <Tooltip title={"qalan limit sayi"}><Chip variant={"outlined"} color={"default"}
                                                                                                     style={{marginLeft: 10, height: 24}}
                                                                                                     label={aiBalance ? aiBalance.aiBalance : 10}/></Tooltip></Button>)
    }

    return (
        <>

            {cvAppConfigCvData.note == null || cvAppConfigCvData.note.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <Edit fontSize="small"
                              style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noAdditionalNotes}
                    </Box>
                </Box>
                :
                (<Box display={"block"} alignItems="center" marginY={2}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "13px",
                            fontStyle: "italic"

                        }}>
                            <IconButton  size={"small"} title={wordsWithLang.cvApp.cvAppForms.change} className={"successText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)", }} onClick={() => {
                                setNote(cvAppConfigCvData.note)
                            }}><Edit className={"successText"} fontSize={"small"}/></IconButton>
                            <IconButton size={"small"} title={wordsWithLang.cvApp.cvAppForms.delete} className={"errorText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)",}} onClick={() => {
                                    cvAppConfigCvData.note = ""
                                    dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                            }}><Delete className={"errorText"} fontSize={"small"}/></IconButton>

                            <Divider orientation="vertical" flexItem style={{marginRight:5, marginLeft:0}} />

                            {cvAppConfigCvData.note}
                            {/*<Button style={{marginLeft:10, color:"#e50914"}} onClick={() => {*/}
                            {/*    deleteRef(key)*/}
                            {/*}}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete style={{color:"#e50914"}}/></Button>*/}
                        </Box>
                    </Box>
                )}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <TextField id={"addNotes"}
                       value={note}
                       onChange={(e) => {
                           setNote(e.target.value)
                       }}
                       multiline minRows={4}
                // helperText={errorMessagesForRefData.refName}
                       helperText={note.length + "/300 "}

                // error={errorMessagesForRefData.refName !== ""}
                       fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.additionalNotes} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 300}}}/>
            {renderAIButtons()}

            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={7}>
                    {cvAppConfigCvData.note ?
                        (<Button
                            id={"noteCommit"}
                            onClick={() => {
                                cvAppConfigCvData.note = note
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setNote("")
                            }}
                            variant="contained"
                            startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                            className={note === "" ? "addButton disabledButton" : "addButton"}
                            size="small"
                            style={{marginTop: 15}}
                        >
                            {wordsWithLang.cvApp.cvAppForms.updateInfos}
                        </Button>) :
                        (<Button
                            id={"noteCommit"}
                            onClick={() => {
                                cvAppConfigCvData.note = note
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setNote("")
                            }}
                            variant="contained"
                            startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                            className={note === "" ? "addButton disabledButton" : "addButton"}
                            size="small"
                            style={{marginTop: 15}}
                        >
                            {wordsWithLang.cvApp.cvAppForms.addInfos}
                        </Button>)}

                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
                    {/*        style={{marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.note = ""*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}

                </Grid>
                <Grid item xs={12} lg={5}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<AutoAwesome fontSize="medium"
                                                        style={{color: 'white'}}/>}
                                className="addButton continueButton btn"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    if (checkAllErrors()) {
                                        updateCvDataInServer("note");
                                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
                                        navigate({pathname:'/cv',hash: "template"})

                                    }
                                }
                                }
                            >
                                {wordsWithLang.cvApp.cvAppForms.choseTemplate}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </>
    );
};

export default NoteAccordion;
