import React, {useEffect, useState} from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import FeedbackIcon from "@mui/icons-material/Feedback";
import {Button, Stack} from "@mui/material";
import {ThumbDown, ThumbUp} from "@mui/icons-material";
import apiUrls from "../apiUrls";

const FeedbackButton = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");

    const translations = {
        az: {
            sendFeedback: "Rəy və təkliflər",
            email: "Email",
            yourFeedback: "Rəyiniz",
            send: "Göndər",
            cancel: "Ləğv et",
            desc: "Saytımızı daha da yaxşılaşdırmaq üçün rəy və təkliflərinizi, həmçinin üzləşdiyiniz problemləri bizimlə bölüşün! 🚀",
            sendSuccess: "Uğurla göndərildi!"
        },
        en: {
            sendFeedback: "Feedback & Suggestions",
            email: "Email",
            yourFeedback: "Your Feedback",
            send: "Send",
            cancel: "Cancel",
            desc: "Share your feedback and let us know about any issues to help us improve the site! 🚀",
            sendSuccess: "Sent successfully!"
        },
        ru: {
            sendFeedback: "Отзывы и предложения",
            email: "Электронная почта",
            yourFeedback: "Ваш отзыв",
            send: "Отправить",
            cancel: "Отмена",
            desc: "Чтобы улучшить наш сайт, поделитесь с нами своими отзывами и предложениями, а также проблемами, с которыми вы столкнулись! 🚀",
            sendSuccess: "Успешно отправлено!"
        }
    };
    const [dict, setDict] = useState(translations[localStorage.getItem("pageLang") || "az"])


    const handleSubmit = async () => {
        try {
            let feedbackObj = {
                email:email,
                feedback:feedback,
                type:"general",
            }

            await axios.post(apiUrls.baseApiUrl+apiUrls.postFeedback,  feedbackObj);
            alert(dict.sendSuccess);
            setEmail("");
            setFeedback("");
            setOpen(false);
        } catch (error) {
            alert("Error!");
        }
    };

    return (
        <>
            {/* Dark Background Overlay */}
            {open && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1257,
                    }}
                    onClick={() => setOpen(false)}
                ></div>
            )}

            {/* Vertical Feedback Button */}
            <Button
                variant="contained"
                color="violet"
                startIcon={<Stack direction="column" spacing={1}>
                    <ThumbUp />
                    <ThumbDown />
                </Stack>}
                style={{
                    color:"white",
                    position: "fixed",
                    right: "-25px",
                    top: "50%",
                    // transform: "translateY(-50%) rotate(-90deg)",
                    padding: "15px 20px",
                    fontSize: "16px",
                    borderRadius: "100px",
                    boxShadow: "0px 4px 6px rgba(0,0,0,0.2)",
                    zIndex: 1257,
                }}
                onClick={() => setOpen(true)}
            >

            </Button>

            {/* Feedback Dialog */}
            {open && (
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        padding: "40px",
                        borderRadius: "12px",
                        boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
                        width: "400px",
                        zIndex: 1258,
                        textAlign: "center",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h2 style={{ marginBottom: "20px", color: "#1976d2", marginTop: 0 }}>{dict.sendFeedback}</h2>
                    <span style={{display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        alignItems: "center",
                        marginBottom: "15px"}}>{dict.desc}</span>

                    <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
                        <input
                            type="email"
                            placeholder={dict.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
                        />
                        <textarea
                            placeholder={dict.yourFeedback}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            style={{ width: "100%", height: "120px", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", resize: "none" }}
                        ></textarea>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", gap: "10px" }}>
                        <Button variant="outlined" sx={{borderRadius:10}} color="error" style={{ flex: 1 }} onClick={() => setOpen(false)}>
                            {dict.cancel}
                        </Button>
                        <Button variant="outlined" sx={{borderRadius:10}} color="violet" endIcon={<SendIcon />} style={{ flex: 1 }} onClick={handleSubmit}>
                            {dict.send}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default FeedbackButton;
