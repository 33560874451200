import React from 'react';
import './blogCardItem.css'
import {Box, Button, Paper, Typography} from "@mui/material";
import {AccessTime} from "@mui/icons-material";


const BlogCartItem = ({blogItem}) => {

    return (
            <div className="projcard-container">
                <a href={"https://bakujobs.az/blog/"+blogItem.slug}>
                    <Paper  sx={{justifyContent:"space-between", borderRadius:4, padding:2, display:"flex",  flexDirection:"row", flexWrap:"wrap", transition: '0.2s ease',"&:hover":{cursor:"pointer", scale:"1.01"}}}>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"40%"}>
                            <Typography variant={"h6"}>{blogItem.gtitle}</Typography>
                            <Typography color={"gray"} variant={"body"}  overflow={"hidden"}>
                                {blogItem.gdesc.length > 200 ? `${blogItem.gdesc.substring(0, 180)}...` : blogItem.gdesc}
                            </Typography>
                            <Button variant={"text"} color={"violet"} sx={{width:120, mt:1,fontSize:10, p:.3, borderRadius:10, mr:2}} startIcon={<AccessTime/>}>{blogItem.addDate}</Button>
                        </Box>
                        <Box sx={{mr:2, borderRadius:4, backgroundColor:"#e9e9e9", backgroundPosition:"center", maxWidth:"350px", width:"50%", height:"200px", backgroundSize:"cover", backgroundImage: `url(https://bakujobs.az/api/public/blog/image/${blogItem.blogPostImageSlug}?t=1)` }} />
                        <Box width={"100%"} sx={{mt:2}}>
                            {blogItem.keywords.split(",").map((item,index)=>{
                                return(<Button key={index} variant={"contained"} color={"violet"}  sx={{color:"white",fontSize:10, p:.5, borderRadius:10, mr:2,"&:hover":{cursor:"auto"}}}  >{"#" + item}</Button>)
                            })}
                        </Box>
                    </Paper>
                </a>
            </div>


);
};

export default BlogCartItem;
