import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ArrowForward, Close, Create, Login, VerifiedUser} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";

const LoginRegister = ({theme, wordsWithLang}) => {

    const mainConfig = useSelector(state => state.mainConfig)
    const mount = useRef(false)
    const [page, setPage] = useState(0)
    const [categories, setCategories] = useState([])
    const [policyColor, setPolicyColor] = useState("gray")
    const [registerUser, setRegisterUser] = useState({
        age: "18-23",
        gender: "male",
        fullName: "",
        email: "",
        phoneNumber: "",
        businessCategorySlug: "all",
        password: "",
        repass: "",
        approvePolicy: false,
    })
    const [errorName, setErrorName] = useState("")
    const [errorNumber, setErrorNumber] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [errorPass, setErrorPass] = useState("")
    const [errorRePass, setErrorRePass] = useState("")
    const [errorVerificationCode, setErrorVerificationCode] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isValidationOk, setIsValidationOk] = useState(false)
    const [renderPage, setRenderPage] = useState(false)
    const [verificationCode, setVerificationCode] = useState(false)


    const dispatcher = useDispatch()
    const handleClose = () => {
        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0})
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
    };

    const containsAnyLetters = (str) => {
        return /[a-zA-Z]/.test(str);
    }
    const checkEmailIsValid = () => {
        if (customValidator() === false) {
            return false
        }
        setRenderPage(!renderPage)

        if (registerUser.email === "") {
            setErrorEmail(wordsWithLang.loginDialog.errorEmail);
            return false;
        }
        if (registerUser.email.length > 8 && registerUser.email.includes("@") && registerUser.email.includes(".")) {
            registerUser.email = registerUser.email.toLowerCase();
            axios.post(apiUrls.baseApiUrl + apiUrls.checkEmailIsAvailable, registerUser).then(resp => {
                if (!resp.data) {
                    setErrorEmail(wordsWithLang.loginDialog.errorUsedEmail);
                    return false;
                }
                setIsEmailValid(true)
                setErrorEmail("");
                return resp.data;
            })

        } else {
            setErrorEmail(wordsWithLang.loginDialog.errorEmailInvalid);
            return false;
        }
    }
    const checkNameIsValid = () => {
        if (registerUser.fullName === "") {
            setErrorName(wordsWithLang.loginDialog.errorName)
            return false;
        }
        if (registerUser.fullName.length < 8 || !registerUser.fullName.includes(" ")) {
            setErrorName(wordsWithLang.loginDialog.errorNameInvalid)
            return false;
        }
        setErrorName("")
        return true;
    }
    const checkPassIsValid = () => {
        if (registerUser.password === "") {
            setErrorPass(wordsWithLang.loginDialog.errorPass)
            return false;
        }
        if (registerUser.password.length < 5) {
            setErrorPass(wordsWithLang.loginDialog.errorPassInvalid)
            return false;
        }
        setErrorPass("")
        return true;
    }

    const formChange = (e) => {

        if (
            e === "18-23" ||
            e === "23-33" ||
            e === "33-43" ||
            e === "43+") {
            setRegisterUser({...registerUser, age: e});
            return true
        }

        if (e === "male" || e === "female") {
            setRegisterUser({...registerUser, gender: e});
            return true
        }


        switch (e.target.id) {
            case "fullName" : {
                setRegisterUser({...registerUser, fullName: e.target.value});
                return true;
            }
            case "email" : {
                setRegisterUser({...registerUser, email: e.target.value});
                return true;
            }

            case "phoneNumber" : {
                setRegisterUser({...registerUser, phoneNumber: e.target.value});
                return true;
            }
            case "pass" : {
                setRegisterUser({...registerUser, password: e.target.value});
                return true;
            }
            case "repass" : {
                setRegisterUser({...registerUser, repass: e.target.value});
                return true;
            }
            case "verificationCode" : {
                setRegisterUser({...registerUser, verifyCode: e.target.value});
                return true;
            }
            case "privacy" : {
                setRegisterUser({...registerUser, approvePolicy: !registerUser.approvePolicy})
                return true;
            }
        }
        switch (e.target.name) {
            case "businessCategorySlug" : {
                setRegisterUser({...registerUser, businessCategorySlug: e.target.value});
                return true;
            }

        }
    }

    const customValidator = () => {
        if (isEmailValid) {
            if (!checkNameIsValid()) {
                return false;
            }
            // if (containsAnyLetters(registerUser.phoneNumber) || registerUser.phoneNumber.length < 10) {
            //     if (registerUser.phoneNumber !== "") {
            //         setErrorNumber(wordsWithLang.loginDialog.errorNumber)
            //         return false;
            //     }
            //     setErrorNumber("")
            // } else {
            //     setErrorNumber("")
            // }

            if (!checkPassIsValid()) {
                return false;
            }

            // if (registerUser.repass === "") {
            //     setErrorRePass(wordsWithLang.loginDialog.errorRePass)
            //     return false;
            // } else {
            //     setErrorRePass("")
            // }
            //
            // if (registerUser.password !== registerUser.repass) {
            //     setErrorPass(wordsWithLang.loginDialog.errorSamePass)
            //     setErrorRePass(wordsWithLang.loginDialog.errorSamePass)
            //     return false;
            // } else {
            //     setErrorPass("");
            //     setErrorRePass("")
            // }

            if (registerUser.approvePolicy === false || registerUser.approvePolicy === undefined) {
                setPolicyColor("red")
                return false;
            } else {
                setPolicyColor("black")
            }

            setIsValidationOk(true)
        }
    }

    const continueRegister = () => {
        if (isValidationOk) {
            dispatcher({type: "LOADING_ALL_PAGE"})
            axios.post(apiUrls.baseApiUrl + apiUrls.registerNewUser, registerUser).then(resp => {
                if (resp) {
                    dispatcher({type: "LOADING_ALL_PAGE"})
                    dispatcher({type: "SET_USER", payload: resp.data})
                    mount.current = false
                    setPage(1)
                }
            })
        }

    }

    const doneRegister = () => {
        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 3})

    }

    const loginNow = () => {

    }

    const initComponent = () => {
        setRegisterUser({
            age: null,
            gender: null,
            name: "",
            email: "",
            phoneNumber: null,
            businessCategorySlug: "all",
            password: "",
            repass: "",
        })
        axios.get(apiUrls.baseApiUrl + apiUrls.getCategories).then(resp => {
            let arr = [];
            arr.push({catName: wordsWithLang.general.other, catSlug: "all"})
            resp.data.sort((a, b) => a.pop - b.pop);
            resp.data.map((item, i) => arr.push({catName: item.category, catSlug: item.slug}))
            setCategories(arr)
        })
    }

    const checkVerificationCodeIsValid = async () => {
        if (registerUser.email === "") {
            setErrorEmail(wordsWithLang.loginDialog.errorEmail);
            return false;
        }
        if (registerUser.email.length > 8 && registerUser.email.includes("@") && registerUser.email.includes(".")) {
            axios.post(apiUrls.baseApiUrl + apiUrls.verifyEmail, registerUser).then(resp => {
                if (!resp.data) {
                    setErrorVerificationCode(wordsWithLang.loginDialog.errorVerificationCode);
                    return false;
                }
                setErrorVerificationCode("");
                setPage(2)
                return resp.data;
            })

        } else {
            setErrorVerificationCode(wordsWithLang.loginDialog.errorVerificationCode);
            return false;
        }
    }

    const RegistrationDone = ({registerUser}) => {
        useEffect(() => {
            registerUser.email = registerUser.email.toLowerCase()
            axios.post(apiUrls.baseApiUrl + apiUrls.login, registerUser, {withCredentials: "include"}).then(resp => {
                dispatcher({type: "SET_USER", payload: resp.data})
                setTimeout(()=>{
                    dispatcher({type: "CHANGE_LOGIN", payload: true})
                    dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
                    window.location.reload();
                },3000)
            })
        }, [])
        return (
            <div>
                <DialogTitle sx={{padding:1.5}}><Typography sx={{fontSize:"1.1rem"}} color={"violet"} fontWeight={500}><Create fontSize={"medium"} color={"violet"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10,
                    marginLeft:10
                }}/>{wordsWithLang.loginDialog.header}</Typography></DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>
                            {/*<Button fullWidth variant={"contained"} color="primary"*/}
                            {/*        style={{marginBottom: 10, borderRadius: 20}}*/}
                            {/*        onClick={() => {*/}
                            {/*            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0});*/}
                            {/*            setPage(0);*/}
                            {/*        }}>{wordsWithLang.header.login}</Button>*/}
                            <FormControl id={"registerForm"} fullWidth>
                                {/*<FormLabel  id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                <Typography display={"inline"} align={"center"}><VerifiedUser
                                    className={"verificationIcon"}/> </Typography>
                                <Typography display={"inline"}
                                            align={"center"}>{wordsWithLang.loginDialog.successfulRegistered}</Typography>
                                <Typography display={"inline"}
                                            align={"center"}>{wordsWithLang.loginDialog.wishSuccessfulWork}</Typography>
                                <Button fullWidth variant={"contained"} color={"secondary"}
                                        style={{marginBottom: 10, marginTop: 20, borderRadius: 20}}
                                        onClick={() => {
                                            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0});
                                            setPage(0);
                                        }}>{wordsWithLang.header.login}</Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                    <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}
                    </Button>
                    {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*        variant={"outlined"} color="primary"*/}
                    {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                    {/*    fontSize="small"/></Button>*/}
                </DialogActions>

            </div>
        )
    }

    const verificationPage = () => {
        return (
            <div>
                <DialogTitle sx={{padding:1.5}}><Typography sx={{fontSize:"1.1rem"}} color={"violet"} fontWeight={500}><Create fontSize={"medium"} color={"violet"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10,
                    marginLeft:10
                }}/>{wordsWithLang.loginDialog.header}</Typography></DialogTitle>
                <DialogContent style={{minHeight: 120}}>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>
                            <FormControl id={"registerForm"} fullWidth>
                                <TextField  InputProps={{
                                    style: { borderRadius: 20 } // Apply border-radius here
                                }} style={{marginTop: 15, marginBottom: 10}}
                                           size="small" margin={"dense"} fullWidth
                                           variant={"outlined"} label={wordsWithLang.loginDialog.verificationCode}
                                           error={errorVerificationCode !== ""} helperText={errorVerificationCode}
                                           id={"verificationCode"} placeholder={"1234"} required
                                           onChange={(e) => formChange(e)}/>

                                <Button sx={{borderRadius: 20, color:"white", mt:1}} onClick={checkVerificationCodeIsValid} variant={"contained"}
                                        color="violet"><ArrowForward
                                    fontSize="small"/> {wordsWithLang.loginDialog.checkButton}</Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{boxShadow: "0px 0 2px rgba(0, 0, 0, 0.2)"}}>
                    <Button style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}
                    </Button>
                    {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*        variant={"outlined"} color="primary"*/}
                    {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                    {/*    fontSize="small"/></Button>*/}
                </DialogActions>

            </div>
        )
    }

    useEffect(initComponent, [mainConfig.isLoginOpen])
    useEffect(() => {
        if (mount.current === true) {
            customValidator()
        }
    }, [isEmailValid, renderPage])
    useEffect(() => {
        if (mount.current === false) {
            mount.current = true
        } else {
            continueRegister()
        }
    }, [isValidationOk])

    return (
        page === 0 ? (<div>
                <DialogTitle sx={{padding:1.5}}><Typography sx={{fontSize:"1.1rem"}} color={"violet"} fontWeight={500}><Create fontSize={"medium"} color={"violet"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10,
                    marginLeft:10
                }}/>{wordsWithLang.loginDialog.header}</Typography></DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>

                            <FormControl id={"registerForm"}>
                                {/*<FormLabel id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                <TextField InputProps={{ style: { borderRadius: 20 }}} autoFocus size="small" margin={"dense"} fullWidth style={{marginTop: 20, borderRadius:20}}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.name}
                                           error={errorName !== ""}
                                           helperText={errorName}
                                           id={"fullName"} required
                                           onChange={(e) => formChange(e)}/>
                                <TextField InputProps={{ style: { borderRadius: 20 }}} size="small" margin={"dense"} fullWidth inputMode={"email"}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.email}
                                           error={errorEmail !== ""} helperText={errorEmail}
                                           id={"email"} required
                                           onChange={(e) => formChange(e)}/>
                                {/*<TextField size="small" margin={"dense"} fullWidth*/}
                                {/*           variant={"outlined"} label={wordsWithLang.loginDialog.phoneNumber}*/}
                                {/*           error={errorNumber !== ""} helperText={errorNumber}*/}
                                {/*           id={"phoneNumber"}*/}
                                {/*           onChange={(e) => formChange(e)}/>*/}

                                {/*{categories.length > 1 ? (<Select*/}
                                {/*    size="small"*/}
                                {/*    // select*/}
                                {/*    // style={{marginTop:30}}*/}
                                {/*    id={"businessCategorySlug"}*/}
                                {/*    margin={"dense"}*/}
                                {/*    name={"businessCategorySlug"}*/}
                                {/*    // defaultValue={"all"}*/}
                                {/*    label={wordsWithLang.loginDialog.businessCategorySlug}*/}
                                {/*    value={registerUser.businessCategorySlug}*/}
                                {/*    onChange={(e) => formChange(e)}*/}
                                {/*    variant={"outlined"}*/}
                                {/*>*/}
                                {/*    {categories.map((item, i) => (*/}
                                {/*        <MenuItem key={i} value={item.catSlug}>{item.catName}</MenuItem>*/}
                                {/*    ))}*/}
                                {/*</Select>) : null}*/}

                                <TextField InputProps={{ style: { borderRadius: 20 }}} size="small" margin={"dense"} fullWidth type={"password"}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.password}
                                           error={errorPass !== ""} helperText={errorPass}
                                           id={"pass"} placeholder={"*******"} required
                                           onChange={(e) => formChange(e)}/>
                                {/*<TextField size="small" margin={"dense"} fullWidth type={"password"}*/}
                                {/*           variant={"outlined"} label={wordsWithLang.loginDialog.rePassword}*/}
                                {/*           error={errorRePass !== ""} helperText={errorRePass}*/}
                                {/*           id={"repass"} placeholder={"******"} required*/}
                                {/*           onChange={(e) => formChange(e)}/>*/}

                                {/*<Grid item style={{marginTop: 10}}>*/}
                                {/*    <FormLabel id="gender-radio">{wordsWithLang.loginDialog.gender}</FormLabel>*/}
                                {/*    <RadioGroup*/}
                                {/*        row*/}
                                {/*        aria-labelledby="gender-radio"*/}
                                {/*        defaultValue={registerUser.gender}*/}
                                {/*        id="gender"*/}
                                {/*        onChange={(e) => formChange(e.target.value)}*/}
                                {/*    >*/}
                                {/*        <FormControlLabel id={"genderFemale"} value="female" control={<Radio/>}*/}
                                {/*                          label={wordsWithLang.loginDialog.female}/>*/}
                                {/*        <FormControlLabel id={"genderMale"} value="male" control={<Radio/>}*/}
                                {/*                          label={wordsWithLang.loginDialog.male}/>*/}
                                {/*    </RadioGroup>*/}
                                {/*</Grid>*/}

                                {/*<Grid item>*/}
                                {/*    <FormLabel id="age-radio">{wordsWithLang.loginDialog.age}</FormLabel>*/}
                                {/*    <RadioGroup*/}
                                {/*        row*/}
                                {/*        aria-labelledby="age-radio"*/}
                                {/*        defaultValue={registerUser.age}*/}
                                {/*        id={"age"}*/}
                                {/*        onClick={(e) => formChange(e)}*/}
                                {/*    >*/}
                                {/*        <FormControlLabel value={"18-23"} control={<Radio/>} label="18-23"/>*/}
                                {/*        <FormControlLabel value={"23-33"} control={<Radio/>} label="23-33"/>*/}
                                {/*        <FormControlLabel value={"33-43"} control={<Radio/>} label="33-43"/>*/}
                                {/*        <FormControlLabel value={"43+"} control={<Radio/>} label="43+"/>*/}
                                {/*    </RadioGroup>*/}

                                {/*</Grid>*/}

                                <Grid item sx={{paddingTop:2}}>
                                    <FormControlLabel
                                        value={registerUser.approvePolicy ? registerUser.approvePolicy : false}
                                        control={
                                            <Checkbox
                                                name={"policy"}
                                                id="privacy"
                                                checked={registerUser.approvePolicy ? registerUser.approvePolicy : false}
                                                onClick={(e) => formChange(e)}
                                            />
                                        }
                                        label={
                                            <Typography fontSize={12}>
                                                <a style={{textDecoration: "underline", color: policyColor}}
                                                   href="https://bakujobs.az/docs/terms" target="_blank"
                                                   rel="noopener noreferrer">
                                                    {" " + wordsWithLang.general.term + " "}
                                                </a>
                                                {" " + wordsWithLang.general.and + " "}
                                                <a style={{textDecoration: "underline", color: policyColor}}
                                                   href="https://bakujobs.az/docs/privacy" target="_blank"
                                                   rel="noopener noreferrer">
                                                    {wordsWithLang.general.policy}
                                                </a>
                                                {" " + wordsWithLang.general.iReadPolicy + " "}

                                            </Typography>
                                        }
                                    />
                                </Grid>

                                {/*<Select value={age} fullWidth onChange={(e) => setAge(e.target.value)}>*/}
                                {/*    <MenuItem value={"18-23"}>18-23</MenuItem>*/}
                                {/*    <MenuItem value={"23-33"}>23-33</MenuItem>*/}
                                {/*    <MenuItem value={"33-43"}>33-43</MenuItem>*/}
                                {/*    <MenuItem value={"43+"}>43+</MenuItem>*/}
                                {/*</Select>*/}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                    variant={"outlined"} color="violet"
                                    onClick={checkEmailIsValid}>{wordsWithLang.loginDialog.continueButton}<ArrowForward
                                fontSize="small"/></Button>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{display:"flex",boxShadow: "0px 0 2px rgba(0, 0, 0, 0.2)"}}>
                    <Button variant={"outlined"} color={"violet"}
                            style={{left:0, marginLeft: 10, marginBottom: 10, marginTop: 10, borderRadius: 20, marginRight:"auto"}}
                            onClick={() => {
                                dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0})
                            }}>{wordsWithLang.header.login}</Button>

                    <Button style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>

                </DialogActions>

            </div>)
            : page === 1 ? verificationPage()
                : page === 2 ? <RegistrationDone registerUser={registerUser}/>
                    : (<div>ERROR</div>)
    );
};

export default LoginRegister;
