import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {words} from "../words";

const InfoDialog = ({open, onClose, message}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{words.cvApp.infoHeader}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {words.cvApp.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
