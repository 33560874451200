// src/ColorPicker.js
import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, useMediaQuery} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';


const colors = [
    '#E42416', '#E91E63', '#9C27B0', '#673AB7',
    '#A76E6E','#4F1919',
    '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4',
    '#009688', '#4CAF50', '#6C7869',
    '#FF5722','#444444',
    '#795548', '#607D8B', '#000000'
];

const ColorPicker = ({ onColorSelect, selectedColorExist }) => {
    const [selectedColor, setSelectedColor] = useState(null);
    const isMobile = useMediaQuery(theme =>theme.breakpoints.down('sm'));

    const handleColorClick = (color) => {
        setSelectedColor(color);
        if (onColorSelect) {
            onColorSelect(color);
        }
    };

    useEffect(() => {
        if (selectedColorExist) {
            setSelectedColor(selectedColorExist);
        }
    }, []);

    return (
        <Box display="flex" justifyContent="center" mt={2}>
            <Grid container spacing={2} maxWidth="100%" justifyContent="center">
                {colors.map((color) => (
                    <Grid item xs={1.1} md={.55} key={color}>
                        <IconButton
                            onClick={() => handleColorClick(color)}
                            sx={{
                                backgroundColor: color,
                                width: isMobile?40:30,
                                height: isMobile?40:30,
                                borderRadius: isMobile?40/2:30/2,
                                border: selectedColor === color ? '2px solid black' : 'none',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: "transform 0.2s ease-in-out",

                                ":hover": {
                                    backgroundColor: color,
                                    transform: "scale(1.3,1.3)",
                                    // width:"130%",
                                    boxShadow: " 0 2px 4px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
                                    // borderRadius: "10px",
                                }

                            }}
                        >
                            {selectedColor === color && <CheckIcon style={{ color: '#FFF' }} />}
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ColorPicker;
