import React from "react";
import "./VioletOcean.css"; // Import the CSS file

const VioletOcean = () => {
    return (
        <div className="container">
            <div className="ocean">
                {/* Waves */}
                <div className="wave"></div>
                <div className="wave wave-2"></div>
                <div className="wave wave-3"></div>

                {/* Blur Layer */}
                <div className="blur-layer"></div>
            </div>
        </div>
    );
};

export default VioletOcean;
