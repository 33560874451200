export const languages = {
    "en" : {
        "mandarin_chinese": {"language": "Mandarin Chinese", "flag": "🇨🇳"},
        "spanish": {"language": "Spanish", "flag": "🇪🇸"},
        "english": {"language": "English", "flag": "🇬🇧"},
        "hindi": {"language": "Hindi", "flag": "🇮🇳"},
        "arabic": {"language": "Arabic", "flag": "🇸🇦"},
        "bengali": {"language": "Bengali", "flag": "🇧🇩"},
        "portuguese": {"language": "Portuguese", "flag": "🇵🇹"},
        "russian": {"language": "Russian", "flag": "🇷🇺"},
        "japanese": {"language": "Japanese", "flag": "🇯🇵"},
        "punjabi_lahnda": {"language": "Punjabi/Lahnda", "flag": "🇮🇳"},
        "marathi": {"language": "Marathi", "flag": "🇮🇳"},
        "telugu": {"language": "Telugu", "flag": "🇮🇳"},
        "wu_chinese": {"language": "Wu Chinese (Shanghai)", "flag": "🇨🇳"},
        "turkish": {"language": "Turkish", "flag": "🇹🇷"},
        "korean": {"language": "Korean", "flag": "🇰🇷"},
        "french": {"language": "French", "flag": "🇫🇷"},
        "german": {"language": "German", "flag": "🇩🇪"},
        "vietnamese": {"language": "Vietnamese", "flag": "🇻🇳"},
        "tamil": {"language": "Tamil", "flag": "🇮🇳"},
        "yue_chinese": {"language": "Yue Chinese (Cantonese)", "flag": "🇨🇳"},
        "urdu": {"language": "Urdu", "flag": "🇵🇰"},
        "javanese": {"language": "Javanese", "flag": "🇮🇩"},
        "italian": {"language": "Italian", "flag": "🇮🇹"},
        "western_punjabi": {"language": "Western Punjabi", "flag": "🇵🇰"},
        "thai": {"language": "Thai", "flag": "🇹🇭"},
        "gujarati": {"language": "Gujarati", "flag": "🇮🇳"},
        "jin_chinese": {"language": "Jin Chinese", "flag": "🇨🇳"},
        "southern_min": {"language": "Southern Min (Hokkien-Taiwan)", "flag": "🇨🇳"},
        "persian": {"language": "Persian", "flag": "🇮🇷"},
        "polish": {"language": "Polish", "flag": "🇵🇱"},
        "pashto": {"language": "Pashto", "flag": "🇦🇫"},
        "kannada": {"language": "Kannada", "flag": "🇮🇳"},
        "xiang_chinese": {"language": "Xiang Chinese", "flag": "🇨🇳"},
        "malayalam": {"language": "Malayalam", "flag": "🇮🇳"},
        "sundanese": {"language": "Sundanese", "flag": "🇮🇩"},
        "hausa": {"language": "Hausa", "flag": "🇳🇬"},
        "burmese": {"language": "Burmese", "flag": "🇲🇲"},
        "hakka_chinese": {"language": "Hakka Chinese", "flag": "🇨🇳"},
        "ukrainian": {"language": "Ukrainian", "flag": "🇺🇦"},
        "bhojpuri": {"language": "Bhojpuri", "flag": "🇮🇳"},
        "tagalog": {"language": "Tagalog (Philippines)", "flag": "🇵🇭"},
        "yoruba": {"language": "Yoruba", "flag": "🇳🇬"},
        "maithili": {"language": "Maithili", "flag": "🇮🇳"},
        "uzbek": {"language": "Uzbek", "flag": "🇺🇿"},
        "sindhi": {"language": "Sindhi", "flag": "🇵🇰"},
        "amharic": {"language": "Amharic", "flag": "🇪🇹"},
        "fula": {"language": "Fula", "flag": "🇸🇳"},
        "romanian": {"language": "Romanian", "flag": "🇷🇴"},
        "oromo": {"language": "Oromo", "flag": "🇪🇹"},
        "igbo": {"language": "Igbo", "flag": "🇳🇬"},
        "azerbaijani": {"language": "Azerbaijani", "flag": "🇦🇿"},
        "awadhi": {"language": "Awadhi", "flag": "🇮🇳"},
        "cebuano": {"language": "Cebuano", "flag": "🇵🇭"},
        "dutch": {"language": "Dutch", "flag": "🇳🇱"},
        "kurdish": {"language": "Kurdish", "flag": "🇹🇷"},
        "serbo_croatian": {"language": "Serbo-Croatian", "flag": "🇷🇸"},
        "malagasy": {"language": "Malagasy", "flag": "🇲🇬"},
        "saraiki": {"language": "Saraiki", "flag": "🇵🇰"},
        "nepali": {"language": "Nepali", "flag": "🇳🇵"},
        "sinhalese": {"language": "Sinhalese", "flag": "🇱🇰"},
        "chittagonian": {"language": "Chittagonian", "flag": "🇧🇩"},
        "zhuang": {"language": "Zhuang", "flag": "🇨🇳"},
        "khmer": {"language": "Khmer", "flag": "🇰🇭"},
        "somali": {"language": "Somali", "flag": "🇸🇴"},
        "maldivian": {"language": "Maldivian (Dhivehi)", "flag": "🇲🇻"},
        "madurese": {"language": "Madurese", "flag": "🇮🇩"},
        "hungarian": {"language": "Hungarian", "flag": "🇭🇺"},
        "rundi": {"language": "Rundi", "flag": "🇧🇮"},
        "czech": {"language": "Czech", "flag": "🇨🇿"},
        "greek": {"language": "Greek", "flag": "🇬🇷"},
        "swedish": {"language": "Swedish", "flag": "🇸🇪"},
        "hmong": {"language": "Hmong", "flag": "🇨🇳"},
        "shona": {"language": "Shona", "flag": "🇿🇼"},
        "uyghur": {"language": "Uyghur", "flag": "🇨🇳"},
        "mossi": {"language": "Mossi", "flag": "🇧🇫"},
        "xhosa": {"language": "Xhosa", "flag": "🇿🇦"},
        "belarussian": {"language": "Belarusian", "flag": "🇧🇾"},
        "balochi": {"language": "Balochi", "flag": "🇵🇰"},
        "konkani": {"language": "Konkani", "flag": "🇮🇳"},
        "wolof": {"language": "Wolof", "flag": "🇸🇳"},
        "afrikaans": {"language": "Afrikaans", "flag": "🇿🇦"},
        "tigrinya": {"language": "Tigrinya", "flag": "🇪🇷"},
        "hebrew": {"language": "Hebrew", "flag": "🇮🇱"},
        "tswana": {"language": "Tswana", "flag": "🇧🇼"},
        "slovak": {"language": "Slovak", "flag": "🇸🇰"},
        "basque": {"language": "Basque", "flag": "🇪🇸"},
        "latvian": {"language": "Latvian", "flag": "🇱🇻"},
        "sotho": {"language": "Sotho", "flag": "🇱🇸"},
        "nyanja": {"language": "Nyanja", "flag": "🇲🇼"},
        "kirundi": {"language": "Kirundi", "flag": "🇧🇮"},
        "mongolian": {"language": "Mongolian", "flag": "🇲🇳"},
        "kazakh": {"language": "Kazakh", "flag": "🇰🇿"},
        "sylheti": {"language": "Sylheti", "flag": "🇧🇩"},
        "norwegian": {"language": "Norwegian", "flag": "🇳🇴"},
        "lao": {"language": "Lao", "flag": "🇱🇦"},
        "estonian": {"language": "Estonian", "flag": "🇪🇪"},
        "maltese": {"language": "Maltese", "flag": "🇲🇹"},
        "tajik": {"language": "Tajik", "flag": "🇹🇯"},
        "sesotho": {"language": "Sesotho", "flag": "🇱🇸"}
    },


"ru": {
    "mandarin_chinese": {"language": "Мандаринский китайский", "flag": "🇨🇳"},
    "spanish": {"language": "Испанский", "flag": "🇪🇸"},
    "english": {"language": "Английский", "flag": "🇬🇧"},
    "hindi": {"language": "Хинди", "flag": "🇮🇳"},
    "arabic": {"language": "Арабский", "flag": "🇸🇦"},
    "bengali": {"language": "Бенгальский", "flag": "🇧🇩"},
    "portuguese": {"language": "Португальский", "flag": "🇵🇹"},
    "russian": {"language": "Русский", "flag": "🇷🇺"},
    "japanese": {"language": "Японский", "flag": "🇯🇵"},
    "punjabi_lahnda": {"language": "Панджаби/Лахнда", "flag": "🇮🇳"},
    "marathi": {"language": "Маратхи", "flag": "🇮🇳"},
    "telugu": {"language": "Телугу", "flag": "🇮🇳"},
    "wu_chinese": {"language": "У-ди (Шанхайский)", "flag": "🇨🇳"},
    "turkish": {"language": "Турецкий", "flag": "🇹🇷"},
    "korean": {"language": "Корейский", "flag": "🇰🇷"},
    "french": {"language": "Французский", "flag": "🇫🇷"},
    "german": {"language": "Немецкий", "flag": "🇩🇪"},
    "vietnamese": {"language": "Вьетнамский", "flag": "🇻🇳"},
    "tamil": {"language": "Тамильский", "flag": "🇮🇳"},
    "yue_chinese": {"language": "Юй (Кантонский)", "flag": "🇨🇳"},
    "urdu": {"language": "Урду", "flag": "🇵🇰"},
    "javanese": {"language": "Яванский", "flag": "🇮🇩"},
    "italian": {"language": "Итальянский", "flag": "🇮🇹"},
    "western_punjabi": {"language": "Западный панджаби", "flag": "🇵🇰"},
    "thai": {"language": "Тайский", "flag": "🇹🇭"},
    "gujarati": {"language": "Гуджарати", "flag": "🇮🇳"},
    "jin_chinese": {"language": "Цзинь", "flag": "🇨🇳"},
    "southern_min": {"language": "Сюань (Хоккиен-Тайваньский)", "flag": "🇨🇳"},
    "persian": {"language": "Персидский", "flag": "🇮🇷"},
    "polish": {"language": "Польский", "flag": "🇵🇱"},
    "pashto": {"language": "Пушту", "flag": "🇦🇫"},
    "kannada": {"language": "Каннада", "flag": "🇮🇳"},
    "xiang_chinese": {"language": "Сян", "flag": "🇨🇳"},
    "malayalam": {"language": "Малаялам", "flag": "🇮🇳"},
    "sundanese": {"language": "Сунданский", "flag": "🇮🇩"},
    "hausa": {"language": "Хауса", "flag": "🇳🇬"},
    "burmese": {"language": "Бирманский", "flag": "🇲🇲"},
    "hakka_chinese": {"language": "Хакка", "flag": "🇨🇳"},
    "ukrainian": {"language": "Украинский", "flag": "🇺🇦"},
    "bhojpuri": {"language": "Бходжпури", "flag": "🇮🇳"},
    "tagalog": {"language": "Тагалог (Филиппинский)", "flag": "🇵🇭"},
    "yoruba": {"language": "Йоруба", "flag": "🇳🇬"},
    "maithili": {"language": "Майтхили", "flag": "🇮🇳"},
    "uzbek": {"language": "Узбекский", "flag": "🇺🇿"},
    "sindhi": {"language": "Синди", "flag": "🇵🇰"},
    "amharic": {"language": "Амхарский", "flag": "🇪🇹"},
    "fula": {"language": "Фула", "flag": "🇸🇳"},
    "romanian": {"language": "Румынский", "flag": "🇷🇴"},
    "oromo": {"language": "Оромо", "flag": "🇪🇹"},
    "igbo": {"language": "Игбо", "flag": "🇳🇬"},
    "azerbaijani": {"language": "Азербайджанский", "flag": "🇦🇿"},
    "awadhi": {"language": "Авади", "flag": "🇮🇳"},
    "cebuano": {"language": "Себуано", "flag": "🇵🇭"},
    "dutch": {"language": "Голландский", "flag": "🇳🇱"},
    "kurdish": {"language": "Курдский", "flag": "🇹🇷"},
    "serbo_croatian": {"language": "Сербохорватский", "flag": "🇷🇸"},
    "malagasy": {"language": "Малагасийский", "flag": "🇲🇬"},
    "saraiki": {"language": "Сараики", "flag": "🇵🇰"},
    "nepali": {"language": "Непали", "flag": "🇳🇵"},
    "sinhalese": {"language": "Сингальский", "flag": "🇱🇰"},
    "chittagonian": {"language": "Читтагонский", "flag": "🇧🇩"},
    "zhuang": {"language": "Чжуан", "flag": "🇨🇳"},
    "khmer": {"language": "Кхмерский", "flag": "🇰🇭"},
    "somali": {"language": "Сомалийский", "flag": "🇸🇴"},
    "maldivian": {"language": "Мальдивский", "flag": "🇲🇻"},
    "madurese": {"language": "Мадурский", "flag": "🇮🇩"},
    "hungarian": {"language": "Венгерский", "flag": "🇭🇺"},
    "rundi": {"language": "Рунди", "flag": "🇧🇮"},
    "czech": {"language": "Чешский", "flag": "🇨🇿"},
    "greek": {"language": "Греческий", "flag": "🇬🇷"},
    "swedish": {"language": "Шведский", "flag": "🇸🇪"},
    "hmong": {"language": "Хмонг", "flag": "🇨🇳"},
    "shona": {"language": "Шона", "flag": "🇿🇼"},
    "uyghur": {"language": "Уйгурский", "flag": "🇨🇳"},
    "mossi": {"language": "Мосси", "flag": "🇧🇫"},
    "xhosa": {"language": "Коса", "flag": "🇿🇦"},
    "belarussian": {"language": "Белорусский", "flag": "🇧🇾"},
    "balochi": {"language": "Балочи", "flag": "🇵🇰"},
    "konkani": {"language": "Конкани", "flag": "🇮🇳"},
    "wolof": {"language": "Волоф", "flag": "🇸🇳"},
    "afrikaans": {"language": "Африкаанс", "flag": "🇿🇦"},
    "tigrinya": {"language": "Тигринья", "flag": "🇪🇷"},
    "hebrew": {"language": "Иврит", "flag": "🇮🇱"},
    "tswana": {"language": "Цвана", "flag": "🇧🇼"},
    "slovak": {"language": "Словацкий", "flag": "🇸🇰"},
    "basque": {"language": "Баскский", "flag": "🇪🇸"},
    "latvian": {"language": "Латышский", "flag": "🇱🇻"},
    "sotho": {"language": "Сото", "flag": "🇱🇸"},
    "nyanja": {"language": "Ньянджа", "flag": "🇲🇼"},
    "kirundi": {"language": "Кирунди", "flag": "🇧🇮"},
    "mongolian": {"language": "Монгольский", "flag": "🇲🇳"},
    "kazakh": {"language": "Казахский", "flag": "🇰🇿"},
    "sylheti": {"language": "Сильхети", "flag": "🇧🇩"},
    "norwegian": {"language": "Норвежский", "flag": "🇳🇴"},
    "lao": {"language": "Лаосский", "flag": "🇱🇦"},
    "estonian": {"language": "Эстонский", "flag": "🇪🇪"},
    "maltese": {"language": "Мальтийский", "flag": "🇲🇹"},
    "tajik": {"language": "Таджикский", "flag": "🇹🇯"},
    "sesotho": {"language": "Сесото", "flag": "🇱🇸"}
},
    "az":{
        "mandarin_chinese": {"language": "Mandarin Çin dili", "flag": "🇨🇳"},
        "spanish": {"language": "İspan dili", "flag": "🇪🇸"},
        "english": {"language": "İngilis dili", "flag": "🇬🇧"},
        "hindi": {"language": "Hind dili", "flag": "🇮🇳"},
        "arabic": {"language": "Ərəb dili", "flag": "🇸🇦"},
        "bengali": {"language": "Benqal dili", "flag": "🇧🇩"},
        "portuguese": {"language": "Portuqal dili", "flag": "🇵🇹"},
        "russian": {"language": "Rus dili", "flag": "🇷🇺"},
        "japanese": {"language": "Yapon dili", "flag": "🇯🇵"},
        "punjabi_lahnda": {"language": "Pəncab dili", "flag": "🇮🇳"},
        "marathi": {"language": "Marati dili", "flag": "🇮🇳"},
        "telugu": {"language": "Teluqu dili", "flag": "🇮🇳"},
        "wu_chinese": {"language": "Vu Çin dili (Şanxay)", "flag": "🇨🇳"},
        "turkish": {"language": "Türk dili", "flag": "🇹🇷"},
        "korean": {"language": "Kore dili", "flag": "🇰🇷"},
        "french": {"language": "Fransız dili", "flag": "🇫🇷"},
        "german": {"language": "Alman dili", "flag": "🇩🇪"},
        "vietnamese": {"language": "Vyetnam dili", "flag": "🇻🇳"},
        "tamil": {"language": "Tamil dili", "flag": "🇮🇳"},
        "yue_chinese": {"language": "Yue Çin dili (Kanton dili)", "flag": "🇨🇳"},
        "urdu": {"language": "Urdu dili", "flag": "🇵🇰"},
        "javanese": {"language": "Yava dili", "flag": "🇮🇩"},
        "italian": {"language": "İtalyan dili", "flag": "🇮🇹"},
        "western_punjabi": {"language": "Qərbi Pəncab dili", "flag": "🇵🇰"},
        "thai": {"language": "Tay dili", "flag": "🇹🇭"},
        "gujarati": {"language": "Qucarat dili", "flag": "🇮🇳"},
        "jin_chinese": {"language": "Cin Çin dili", "flag": "🇨🇳"},
        "southern_min": {"language": "Cənubi Min dili (Hokkien-Tayvan)", "flag": "🇨🇳"},
        "persian": {"language": "Fars dili", "flag": "🇮🇷"},
        "polish": {"language": "Polyak dili", "flag": "🇵🇱"},
        "pashto": {"language": "Puştu dili", "flag": "🇦🇫"},
        "kannada": {"language": "Kannada dili", "flag": "🇮🇳"},
        "xiang_chinese": {"language": "Xianq Çin dili (Hunan dili)", "flag": "🇨🇳"},
        "malayalam": {"language": "Malayalam dili", "flag": "🇮🇳"},
        "sundanese": {"language": "Sunda dili", "flag": "🇮🇩"},
        "hausa": {"language": "Hausa dili", "flag": "🇳🇬"},
        "burmese": {"language": "Birma dili", "flag": "🇲🇲"},
        "hakka_chinese": {"language": "Hakka Çin dili", "flag": "🇨🇳"},
        "ukrainian": {"language": "Ukrayna dili", "flag": "🇺🇦"},
        "bhojpuri": {"language": "Bhojpuri dili", "flag": "🇮🇳"},
        "tagalog": {"language": "Taqaloq dili (Filippin dili)", "flag": "🇵🇭"},
        "yoruba": {"language": "Yoruba dili", "flag": "🇳🇬"},
        "maithili": {"language": "Maytihili dili", "flag": "🇮🇳"},
        "uzbek": {"language": "Özbək dili", "flag": "🇺🇿"},
        "sindhi": {"language": "Sindhi dili", "flag": "🇵🇰"},
        "amharic": {"language": "Amhar dili", "flag": "🇪🇹"},
        "fula": {"language": "Fula dili", "flag": "🇸🇳"},
        "romanian": {"language": "Rumın dili", "flag": "🇷🇴"},
        "oromo": {"language": "Oromo dili", "flag": "🇪🇹"},
        "igbo": {"language": "İqbo dili", "flag": "🇳🇬"},
        "azerbaijani": {"language": "Azərbaycan dili", "flag": "🇦🇿"},
        "awadhi": {"language": "Avadhi dili", "flag": "🇮🇳"},
        "cebuano": {"language": "Sebuano dili", "flag": "🇵🇭"},
        "dutch": {"language": "Hollandiya dili", "flag": "🇳🇱"},
        "kurdish": {"language": "Kürd dili", "flag": "🇹🇷"},
        "serbo_croatian": {"language": "Serb-Xorvat dili", "flag": "🇷🇸"},
        "malagasy": {"language": "Malaqas dili", "flag": "🇲🇬"},
        "saraiki": {"language": "Saraiki dili", "flag": "🇵🇰"},
        "nepali": {"language": "Nepal dili", "flag": "🇳🇵"},
        "sinhalese": {"language": "Sinhala dili", "flag": "🇱🇰"},
        "chittagonian": {"language": "Çittaqon dili", "flag": "🇧🇩"},
        "zhuang": {"language": "Çuanq dili", "flag": "🇨🇳"},
        "khmer": {"language": "Xmer dili", "flag": "🇰🇭"},
        "somali": {"language": "Somali dili", "flag": "🇸🇴"},
        "maldivian": {"language": "Maldiv dili", "flag": "🇲🇻"},
        "madurese": {"language": "Madura dili", "flag": "🇮🇩"},
        "hungarian": {"language": "Macar dili", "flag": "🇭🇺"},
        "rundi": {"language": "Rundi dili", "flag": "🇧🇮"},
        "czech": {"language": "Çex dili", "flag": "🇨🇿"},
        "greek": {"language": "Yunan dili", "flag": "🇬🇷"},
        "swedish": {"language": "İsveç dili", "flag": "🇸🇪"},
        "hmong": {"language": "Hmonq dili", "flag": "🇨🇳"},
        "shona": {"language": "Şona dili", "flag": "🇿🇼"},
        "uyghur": {"language": "Uyğur dili", "flag": "🇨🇳"},
        "mossi": {"language": "Mossi dili", "flag": "🇧🇫"},
        "xhosa": {"language": "Xosa dili", "flag": "🇿🇦"},
        "belarussian": {"language": "Belarus dili", "flag": "🇧🇾"},
        "balochi": {"language": "Bəluc dili", "flag": "🇵🇰"},
        "konkani": {"language": "Konkan dili", "flag": "🇮🇳"},
        "wolof": {"language": "Volof dili", "flag": "🇸🇳"},
        "afrikaans": {"language": "Afrikaans dili", "flag": "🇿🇦"},
        "tigrinya": {"language": "Tiqrinya dili", "flag": "🇪🇷"},
        "hebrew": {"language": "İvrit dili", "flag": "🇮🇱"},
        "tswana": {"language": "Tsvana dili", "flag": "🇧🇼"},
        "slovak": {"language": "Slovak dili", "flag": "🇸🇰"},
        "basque": {"language": "Bask dili", "flag": "🇪🇸"},
        "latvian": {"language": "Latış dili", "flag": "🇱🇻"},
        "sotho": {"language": "Soto dili", "flag": "🇱🇸"},
        "nyanja": {"language": "Nyanja dili", "flag": "🇲🇼"},
        "kirundi": {"language": "Kirundi dili", "flag": "🇧🇮"},
        "mongolian": {"language": "Monqol dili", "flag": "🇲🇳"},
        "kazakh": {"language": "Qazax dili", "flag": "🇰🇿"},
        "sylheti": {"language": "Silheti dili", "flag": "🇧🇩"},
        "norwegian": {"language": "Norveç dili", "flag": "🇳🇴"},
        "lao": {"language": "Laos dili", "flag": "🇱🇦"},
        "estonian": {"language": "Eston dili", "flag": "🇪🇪"},
        "maltese": {"language": "Malta dili", "flag": "🇲🇹"},
        "tajik": {"language": "Tacik dili", "flag": "🇹🇯"},
        "sesotho": {"language": "Sesoto dili", "flag": "🇱🇸"}
    }}
