import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Box, Button, Divider, Grid, IconButton, TextField, Typography} from "@mui/material";
import {Add, ArrowDownward, Delete, Edit, WorkspacePremium} from "@mui/icons-material";

const CertificatesAccordion = ({
                                   cvAppConfigCvData,
                                   wordsWithLang,
                                   expanded,
                                   setExpanded,
                                   updateCvDataInServer,
                                   errorMessagesForCertData,
                                   certInfoValidate,
                               startCheckingCert}) => {
    const dispatcher = useDispatch();
    const [certificate, setCertificate] = useState({
        id: null,
        name: "",
        about: "",
        issuance: null
    });

    const deleteCert = (key) => {
        cvAppConfigCvData.certificates.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    const editCert = (key) => {
        setCertificate(cvAppConfigCvData.certificates[key])
        deleteCert(key)
    }
    useEffect(() => {
        certInfoValidate(false, certificate)
    }, [certificate, startCheckingCert])
    return (
        <>
            {cvAppConfigCvData.certificates.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <WorkspacePremium fontSize="small"
                                          style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noCertificateAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.certificates.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <IconButton size={"small"} title={wordsWithLang.cvApp.cvAppForms.delete} className={"errorText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)",}} onClick={() => {
                                deleteCert(key)
                            }}><Delete className={"errorText"} fontSize={"small"}/></IconButton>
                            <IconButton  size={"small"} title={wordsWithLang.cvApp.cvAppForms.change} className={"successText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)", }} onClick={() => {
                                editCert(key)
                            }}><Edit className={"successText"} fontSize={"small"}/></IconButton>
                            <Divider orientation="vertical" flexItem style={{marginRight:5, marginLeft:0}} />


                            <WorkspacePremium fontSize="small"
                                              style={{marginRight: 5}}/>
                            <Typography>{value.name} | <span style={{
                                fontSize: 12,
                                fontStyle: "italic"
                            }}>{value.issuance}</span></Typography>


                        </Box>
                    </Box>
                )))}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <TextField id={"certName"}
                       value={certificate.name}
                       onChange={(e) => {
                           setCertificate({...certificate, name: e.target.value})
                       }} helperText={errorMessagesForCertData.name}
                       error={errorMessagesForCertData.name !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.certificateName} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField id={"certAbout"} onChange={(e) => {
                setCertificate({...certificate, about: e.target.value})
            }} helperText={certificate.about.length + "/200 " + errorMessagesForCertData.about}
                       multiline maxRows={10} minRows={3}
                       value={certificate.about}
                       error={errorMessagesForCertData.about !== ""} fullWidth
                       label={wordsWithLang.cvApp.cvAppForms.certificateAbout} variant="outlined"
                // inputProps={{ maxLength: 150 }}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>
            <TextField id={"certIssuance"} type={"date"} onChange={(e) => {
                setCertificate({...certificate, issuance: e.target.value})
            }}
                       onClick={(e)=>{e.target.showPicker && e.target.showPicker();}}
                       helperText={errorMessagesForCertData.issuance}
                       value={certificate.issuance}
                       error={errorMessagesForCertData.issuance !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.certificateIssuance} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {max: new Date().toISOString().split('T')[0]}
                       }} InputLabelProps={{shrink: true}}/>


            <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item xs={12} md={12} lg={4}>
                    <Button
                        id={"certCommit"}
                        onClick={() => {
                            if(certInfoValidate(true,certificate)){
                                setCertificate({
                                    id: "",
                                    name: "",
                                    about: "",
                                    issuance: ""
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className={certificate.name === "" || certificate.issuance === "" ? "addButton disabledButton" : "addButton"}
                        size="small"
                        style={{marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
                    {/*        style={{ marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.langs = []*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}

                </Grid>
                {/*<Grid item xs={12} lg={4}>*/}

                {/*<Grid container justifyContent={"flex-end"}>*/}
                {/*        <Grid item xs={12} md={6} lg={12}>*/}
                {/*            <Button*/}
                {/*                variant="contained"*/}
                {/*                startIcon={<ArrowDownward fontSize="medium"*/}
                {/*                                          style={{color: 'white'}}/>}*/}
                {/*                className="addButton continueButton animate__animated animate__slow animate__pulse animate__infinite"*/}
                {/*                size="small"*/}
                {/*                style={{width: '100%', marginTop: 15}}*/}
                {/*                onClick={() => {*/}
                {/*                    updateCvDataInServer("certificates");*/}
                {/*                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                {wordsWithLang.cvApp.cvAppForms.continue}*/}
                {/*            </Button>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    );
};

export default CertificatesAccordion;
