import axios from "axios";
import apiUrls from "../apiUrls";
import {words} from "../words";

let wordsWithLang = words;
export const changeUtilsLang = () => {
    switch (window.localStorage.getItem("pageLang")?window.localStorage.getItem("pageLang"):"az"){
        case "az":
            import("../words").then(module => {
                wordsWithLang = module.words;
            });
            break;
        case "ru":
            import("../words-ru").then(module => {
                wordsWithLang = module.words;

            });
            break;

        case "en":
            import("../words-en").then(module => {
                wordsWithLang = module.words;

            });
            break;
        default:
            break;

    }
}

export const companyLogo = (vacancyItem) => {
    return apiUrls.baseApiUrl + apiUrls.getCompanyLogo
        .replace(":logoName",
            vacancyItem.company.miniLogo.split("/")[
            vacancyItem.company.miniLogo.split("/").length - 1
                ])
}

export const favoriteHandler = (id, dispatcher, userConfig,e) => {
    e.stopPropagation();
    id = id.toString()
    if (!userConfig.isLogin) {
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
    } else {
        if (userConfig.favorites && userConfig.favorites.includes(id)) {
            axios.get(apiUrls.baseApiUrl + apiUrls.removeFromFavorite +"/" + id, {withCredentials: true}).then((resp) => {
                if (resp.data) {
                    dispatcher({type: "ADD_TO_FAVORITES", payload: id})
                }
            })
        } else {
            if (userConfig.favorites && !userConfig.favorites.includes(id)) {
                axios.get(apiUrls.baseApiUrl + apiUrls.addToFavorite +"/"+ id, {withCredentials: true}).then((resp) => {
                    if (resp.data) {
                        dispatcher({type: "ADD_TO_FAVORITES", payload: id})
                    }
                })
            }
        }
    }
}

export const shareHandler = (dispatcher,url,e, vacancyItem) => {
    navigator.share({
        title: vacancyItem.title,
        url: url,
        text: "Ən yeni vakansiyalar BakuJobs.az-da",  // Optional, but useful
    })
    e.stopPropagation();
    window.navigator.clipboard.writeText(url)
    dispatcher({
        type: "OPEN_SNACK",
        payload: {"isSnackOpen": true, snackType: "success", snackMessage: wordsWithLang.vacancyItem.shared}
    })

}

export const copyDeadline = (dispatcher, deadline) => {
    window.navigator.clipboard.writeText(deadline)
    dispatcher({
        type: "OPEN_SNACK",
        payload: {"isSnackOpen": true, snackType: "success", snackMessage: wordsWithLang.vacancyItem.copyDeadline}
    })
}


const thisMonth = new Date().getMonth() + 1 >= 10 ? (new Date().getMonth() + 1).toString().trim() : "0".concat((new Date().getMonth() + 1).toString()).trim()
const today = new Date().getDate() >= 10 ? new Date().getDate().toString().trim() : "0".concat((new Date().getDate()).toString()).trim()
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).getDate() >= 10 ? new Date(new Date().setDate(new Date().getDate() - 1)).getDate().toString().trim() : "0".concat((new Date(new Date().setDate(new Date().getDate() - 1)).getDate()).toString()).trim()
export const dateCorrector = (date) => {
    let correctedDate = "";
    if(today==="01"){
        correctedDate = date.split("-")[2] === today ? wordsWithLang.vacancyItem.today : date.split("-")[2] === yesterday ? wordsWithLang.vacancyItem.yesterday : date.split("-")[1] + "/" + date.split("-")[2];
    }
    else if (date.split("-")[1] === thisMonth) {
        correctedDate = date.split("-")[2] === today ? wordsWithLang.vacancyItem.today : date.split("-")[2] === yesterday ? wordsWithLang.vacancyItem.yesterday : date.split("-")[1] + "/" + date.split("-")[2];
    }
    else{
        correctedDate =  date.split("-")[1] + "/" + date.split("-")[2]
    }

    return correctedDate;

}

export const setDocTitle = (data)=>{
    // console.log("")
    document.title = data;

}
export const setDocDesc = (data)=>{
    // console.log("")
    data = data.replace(/<[^>]*>/g, '').replace(/\n/g,' ').replace(/\t/g,' ').substring(0,160)
    document.querySelector('meta[name="description"]').setAttribute("content", data);
}

export const setOgImage = (data)=>{
    // console.log("")
    // data = data.replace(/<[^>]*>/g, '').replace(/\n/g,' ').replace(/\t/g,' ').substring(0,160)
    document.querySelector('meta[name="og:image"]').setAttribute("content", "https://bakujobs.az/api/public/vacancyPreview/"+data);
}

export const removeLastDuplicatesById = (arr) => {
    const idMap = new Map();
    const result = [];

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const id = item.id;

        if (idMap.has(id)) {
            // This id is a duplicate, so we skip it.
            idMap.set(id, idMap.get(id) + 1);
        } else {
            // First occurrence of this id, so we include it.
            idMap.set(id, 1);
            result.push(item);
        }
    }
    return result;
}






