import React, {useEffect, useState} from 'react';
import apiUrls from "../../apiUrls";
import axios from "axios";
import {Box, Card, Grid, Paper} from "@mui/material";

const AdminFeedbackTab = () => {
    const [page, setPage] = useState(1);
    const [feedbackList, setFeedbackList] = useState([]);
    useEffect(() => {

        axios.get(apiUrls.baseApiUrl + apiUrls.getAdminFeedBacks.replace("{page}", page-1), {withCredentials: true}).then((resp) => {
            console.log(resp.data)
            setFeedbackList(resp.data)
        })
    }, []);
    return (
        <div>
            <Grid container spacing={1} padding={2}>
                {feedbackList.map((item)=><FeedbackItem key={item.id} item={item} />)}
            </Grid>
        </div>
    );
};

const FeedbackItem = ({item}) => {
    return (
        <Paper sx={{width:"100%", padding:2, borderRadius:2}}>
            <Grid container>
                <Grid item xs={1} md={1}>{item.id}</Grid>
                <Grid item xs={3} md={2}>{item.email}</Grid>
                <Grid item xs={2} md={1}>{item.type}</Grid>
                <Grid item xs={2} md={2}>{item.status}</Grid>
                <Grid item xs={4} md={6}>{item.feedback}</Grid>
            </Grid>
        </Paper>
    )
}

export default AdminFeedbackTab;
