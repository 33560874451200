import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {words} from "../words";
import {ArrowBack, Close} from "@mui/icons-material";
import {Link} from "react-router-dom";

const Subscriptions = ({wordsWithLang}) => {
    const [category,setCategory] = useState("nothing")
    const mainConfig = useSelector(state=>state.mainConfig)
    const userConfig = useSelector(state=>state.userConfig)
    const dispatcher = useDispatch()

    const checkForLogin = () => {
        document.title = words.general.documentTitleFromSubscription
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
        else{
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
            if(userConfig.favorites && userConfig.favorites.length>0){
                axios.post(apiUrls.baseApiUrl + apiUrls.getFavorites, {favorites:userConfig.favorites},{withCredentials:true})
                    .then(resp=>{
                        dispatcher({type:"SET_FAV_VACANCIES",payload:resp.data})
                    })
            }

        }
    }

    const addToNewsletters = () => {
        if(category!=="nothing" && !userConfig.newsletterCategories.includes(category)){
            dispatcher({type:"ADD_TO_NEWSLETTERS", payload:category})
            axios.post(apiUrls.baseApiUrl + apiUrls.setNewslettersCategories,userConfig.newsletterCategories,{withCredentials:true}).then(
                (resp)=>{
                    let user = userConfig
                    user.newsletterCategories = resp.data.newsletterCategories;
                    dispatcher({type:"SET_USER",payload:user})
                    dispatcher({type:"OPEN_SNACK",payload:{
                            isSnackOpen:true,
                            snackType:"success",
                            snackMessage:words.general.addedSuccess
                        }})
                }
            )

        }
    }
    const removeFromNewsletters = (v) => {
        if(userConfig.newsletterCategories.includes(v)){
            dispatcher({type:"REMOVE_FROM_NEWSLETTERS", payload:v})
            axios.post(apiUrls.baseApiUrl + apiUrls.setNewslettersCategories,userConfig.newsletterCategories,{withCredentials:true}).then(
                (resp)=>{
                    let user = userConfig
                    user.newsletterCategories = resp.data.newsletterCategories;
                    dispatcher({type:"SET_USER",payload:user})
                    dispatcher({type:"OPEN_SNACK",payload:{
                            isSnackOpen:true,
                            snackType:"warning",
                            snackMessage:words.general.deletedSuccess
                        }})
                }
            )

        }
    }

    useEffect(checkForLogin,[userConfig.email])

    return (
        <div>
            {userConfig.isLogin && mainConfig.categories.length>1 ? (
                <Grid  container justifyContent={"center"} alignItems={"flex-end"} style={{marginBottom:100,borderRadius:10,backgroundColor:"white", padding:20, boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"}}>
                {/*<Stack direction={"row"} width={"100%"} alignItems={"center"} gap={2} justifyContent={"center"}>*/}
                    <Grid item xs={12}>
                        <Link to={-1}><Button style={{ borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                            <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
                    </Grid>

                    <Grid item xs={12} sm={7} md={7} style={{marginBottom:20}}>
                        {/*<Typography style={{marginTop:20}} variant={"h5"} color={"textPrimary"} >{words.header.subscriptionHeader}</Typography>*/}
                        {/*<Typography variant={"caption"} color={"textPrimary"} >{words.header.subscriptionHeader}</Typography>*/}
                        <FormControl fullWidth style={{marginTop:20}}>
                            <InputLabel>{words.search.category}</InputLabel>
                            {mainConfig.categories.length>1?(
                                <Select
                                    name={"categoryListSub"}
                                    defaultValue={category}
                                    label={words.search.category}
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                >
                                    <MenuItem key={"999"} value={"nothing"}>{words.general.nothing}</MenuItem>
                                    {mainConfig.categories.map((item, i) => (
                                        <MenuItem key={i} style={{display:item.catSlug==="all"?"none":"block"}} value={item.catSlug}>{item.catName}</MenuItem>
                                    ))}
                                </Select>):null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                        <Button fullWidth disabled={userConfig.newsletterCategories.length>2
                        ||category==="nothing"
                        ||userConfig.newsletterCategories.includes(category)}
                                 onClick={addToNewsletters} variant={"contained"} color="primary">{
                            userConfig.newsletterCategories.length>2?words.general.noMoreAddToSubs:words.general.add}</Button>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                    {userConfig.newsletterCategories?userConfig.newsletterCategories.map((v,k)=>(
                        <Button sx={{ textTransform: 'none' }} key={k+"-selected"} style={{ marginTop:15}} size="small" fullWidth
                                startIcon={<Close style={{borderRight:"1px solid lightgray", paddingRight:5}}
                                                  onClick={()=>{removeFromNewsletters(v)}} color={"secondary"} fontSize="small" />} variant={"outlined"} >
                            <Typography style={{width:"100%"}} align={"left"} id={v+"-sub"}>
                                {
                                    mainConfig.categories.length>1?mainConfig.categories.filter(val=>val.catSlug===v)[0].catName:null
                                }</Typography>

                        </Button>



                    )):null}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </Grid>
                {/*</Stack>*/}
                </Grid>
            ):(<Typography style={{marginLeft:50}} display={"block"} align={"left"} variant={"h5"}>{words.header.pleaseLoginFirst}</Typography>)}
        </div>
    );
};

export default Subscriptions;
