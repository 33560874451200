import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid, IconButton,
    TextField,
    Typography
} from "@mui/material";
import {Add, ArrowDownward, Delete, Edit, EditNote, WorkHistory} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const WorkExperienceAccordion = ({wordsWithLang,
                                     cvAppConfigCvData,
                                     updateCvDataInServer,
                                     expanded,
                                     setExpanded, workInfoValidate, startCheckingWork,
                                     errorMessagesForWorkData}) => {

    const [workExperience, setWorkExperience] = useState({
        id: null,
        workplace: "",
        branch: "",
        position: "",
        about: "",
        startDate: "",
        endDate: "",
        isContinue: false
    });
    const dispatcher = useDispatch();


    const deleteWork = (key) => {
        cvAppConfigCvData.workExperiences.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }

    const editWork = (key) => {
      setWorkExperience(cvAppConfigCvData.workExperiences[key])
        deleteWork(key)
    }


    useEffect(() => {
        workInfoValidate(false,workExperience)
    }, [workExperience, startCheckingWork])



    return (
        <>
            {cvAppConfigCvData.workExperiences.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <WorkHistory fontSize="small"
                                     style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noWorkAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.workExperiences.sort((a, b) => a.orderNumber - b.orderNumber) && cvAppConfigCvData.workExperiences.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <IconButton size={"small"} title={wordsWithLang.cvApp.cvAppForms.delete} className={"errorText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)",}} onClick={() => {
                                deleteWork(key)
                            }}><Delete className={"errorText"} fontSize={"small"}/></IconButton>
                            <IconButton  size={"small"} title={wordsWithLang.cvApp.cvAppForms.change} className={"successText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)", }} onClick={() => {
                                editWork(key)
                            }}><Edit className={"successText"} fontSize={"small"}/></IconButton>
                            <Divider orientation="vertical" flexItem style={{marginRight:5, marginLeft:0}} />


                            <WorkHistory fontSize="small"
                                         style={{marginRight: 5}}/>
                            <Typography>{value.workplace} | <span style={{
                                fontSize: 12,
                                fontStyle: "italic"
                            }}>{value.startDate} - {value.isContinue === true ? wordsWithLang.cvApp.cvAppForms.workIsContinue : value.endDate}</span></Typography>

                        </Box>
                    </Box>
                )))}

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <TextField id={"workWorkplace"} value={workExperience.workplace} onChange={(e) => {
                setWorkExperience({...workExperience, workplace: e.target.value})
            }} helperText={errorMessagesForWorkData.workWorkplace}
                       error={errorMessagesForWorkData.workWorkplace !== ""} fullWidth
                       margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workplaceName} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"workBranch"} value={workExperience.branch} onChange={(e) => {
                setWorkExperience({...workExperience, branch: e.target.value})
            }} helperText={errorMessagesForWorkData.workBranch}
                       error={errorMessagesForWorkData.workBranch !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workBranch} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField id={"workPosition"}
                       value={workExperience.position}
                       onChange={(e) => {
                           setWorkExperience({...workExperience, position: e.target.value})
                       }} helperText={errorMessagesForWorkData.workPosition}
                       error={errorMessagesForWorkData.workPosition !== ""} fullWidth
                       margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workPosition} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField id={"workAbout"}
                       onChange={(e) => {
                           setWorkExperience({...workExperience, about: e.target.value})
                       }}
                       helperText={workExperience.about.length + "/350 " + errorMessagesForWorkData.workAbout}
                       value={workExperience.about}
                       multiline maxRows={10} minRows={3}
                       error={errorMessagesForWorkData.workAbout !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workAbout} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 350}}}/>
            <TextField id={"workStartDate"} type={"date"} onChange={(e) => {
                setWorkExperience({...workExperience, startDate: e.target.value})
            }} helperText={errorMessagesForWorkData.workStartDate}
                       onClick={(e)=>{e.target.showPicker && e.target.showPicker();}}

                       value={workExperience.startDate}
                       error={errorMessagesForWorkData.workStartDate !== ""} fullWidth
                       margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workStartDate} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {max: new Date().toISOString().split('T')[0]}
                       }} InputLabelProps={{shrink: true}}/>
            <TextField id={"workEndDate"} type={"date"} disabled={workExperience.isContinue}
                       onChange={(e) => {
                           setWorkExperience({...workExperience, endDate: e.target.value})
                       }} helperText={errorMessagesForWorkData.workEndDate}
                       onClick={(e)=>{e.target.showPicker && e.target.showPicker();}}

                       value={workExperience.endDate}
                       error={errorMessagesForWorkData.workEndDate !== "" && !workExperience.isContinue}
                       fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.workEndDate} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {
                               min: workExperience.startDate ? workExperience.startDate : "",
                               max: new Date().toISOString().split('T')[0]
                           }
                       }} InputLabelProps={{shrink: true}}

            />

            <FormControl margin="normal" error={errorMessagesForWorkData.workIsContinue !== ""}
                         variant="outlined">
                <FormControlLabel
                    control={
                        <Checkbox
                            id={"workIsContinue"}
                            checked={workExperience.isContinue}
                            onChange={(e) => {
                                setWorkExperience({
                                    ...workExperience,
                                    isContinue: !workExperience.isContinue
                                })
                            }}
                            sx={{borderRadius: 5}}
                        />
                    }
                    label={wordsWithLang.cvApp.cvAppForms.workIsContinue}
                />
                <FormHelperText>{errorMessagesForWorkData.workIsContinue}</FormHelperText>
            </FormControl>

            <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item xs={12} md={6} lg={5}>
                    <Button
                        id={"workCommit"}
                        onClick={() => {
                            if(workInfoValidate(true, workExperience)) {
                                updateCvDataInServer("workExperiences");
                                setWorkExperience({
                                    id: null,
                                    workplace: "",
                                    branch: "",
                                    position: "",
                                    about: "",
                                    startDate: "",
                                    endDate: "",
                                    isContinue: false
                                })
                            }
                            else{
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        "isSnackOpen": true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                                    }})
                            }


                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className="addButton"
                        size="small"
                        style={{width: '100%', marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                </Grid>
                {/*<Grid item xs={12} md={7} lg={4}>*/}
                {/*<Button*/}
                {/*        variant="contained"*/}
                {/*        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}*/}
                {/*        className="addButton continueButton animate__animated animate__slow animate__pulse animate__infinite"*/}
                {/*        size="small"*/}
                {/*        style={{width: '100%', marginTop: 15}}*/}
                {/*        onClick={() => {*/}
                {/*            updateCvDataInServer("workExperiences");*/}
                {/*            setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {wordsWithLang.cvApp.cvAppForms.continue}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>



        </>
    );
};

export default WorkExperienceAccordion;
