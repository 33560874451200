import React, {useEffect} from 'react';
import {words} from "../words";
import {setDocDesc, setDocTitle} from "../utils/Utils";
import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import MiniContact from "../miniComponents/miniContact";
import {Link} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";

const AboutUs = ({wordsWithLang}) => {
    useEffect(() => {
        setDocTitle(words.general.documentTitleFromAboutUs);
        setDocDesc(words.content.aboutUs)
    }, [])
    return (
        <Grid container justifyContent={"center"} alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            <Grid item xs={12}>
                <Link to={-1}><Button style={{ borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                    <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                <MiniContact viewType={"onlyContact"} wordsWithLang={wordsWithLang} />
                {useMediaQuery(theme => theme.breakpoints.down("sm"))
                    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :
                    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}
                <Box
                    display="flex"
                    flexDirection={"column"}
                    justifyContent="center"
                    alignItems="center"
                    height="12vh"  // Full viewport height for vertical centering
                >
                    <Typography variant={"caption"} color={"gray"} align={"center"} justifyContent={"center"} style={{ fontStyle:"italic"}} dangerouslySetInnerHTML={{__html: wordsWithLang.general.footer}}>
                    </Typography>
                    <Typography variant={"caption"} color={"gray"} align={"center"} justifyContent={"center"} style={{fontStyle:"italic"}}><br/>{wordsWithLang.general.voen}</Typography>

                </Box>
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}

        </Grid>


    );
};

export default AboutUs;
