import React from 'react';

const AdminForm = () => {
    return (
        <div>
            loading forms
        </div>
    );
};

export default AdminForm;
