import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl, FormControlLabel, FormLabel,
    Grid,
    InputLabel, LinearProgress,
    MenuItem,
    Paper, Radio, RadioGroup,
    Select,
    Typography
} from "@mui/material";
import {
    AccountCircleOutlined,
    ArrowBack,
    ArrowDownward,
    ArrowUpward,
    AutoAwesomeMosaic,
    AutoFixHigh,
    BusinessCenterOutlined,
    Cancel,
    Check,
    CheckCircle, Close,
    CloseOutlined,
    CorporateFareOutlined, Directions,
    InfoOutlined, Notes, TextIncrease,
    Timelapse
} from "@mui/icons-material";
import {words} from "../../words";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../../apiUrls";
import Confetti from 'confetti-react';
import {useLocation, useNavigate} from "react-router-dom";
import CvAppPreviewDetailedViewDialog from "../../components/cvApp/cvAppPreviewDetailedViewDialog";
import GradientCircularProgress from "../../miniComponents/GradientCircularProgress";

;


const CvAppPreview = ({wordsWithLang, userBalance, updateUserBalance, adminCvOrder}) => {

    const dispatcher = useDispatch()
    const cvAppConfig = useSelector(state => state.cvAppConfig)
    const newCvOrder = useSelector(state=>state.cvAppConfig.cvOrder)
    const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)
    const userConfig = useSelector(state => state.userConfig)

    useEffect(() => {
        if (adminCvOrder !== undefined && adminCvOrder !== null) {
            dispatcher({type: "SET_USER", payload: adminCvOrder.customUser})
            dispatcher({type: "CV_DATA_UPDATE", payload: adminCvOrder.cvData})
            dispatcher({type: "CV_INSTANCE_UPDATE", payload: adminCvOrder})
        }
    }, []);



    const isMobile = window.screen.width <= 700
    const [agreeWithRules, setAgreeWithRules] = useState(true)
    // const selectedTemplate = useSelector(state => state.cvAppConfig.selectedTemplate)
    const [cvTemplateProduct, setCvTemplateProduct] = useState(null)
    const [hrAdviceCheck, setHrAdviceCheck] = useState(false)
    const [selectedPackId, setSelectedPackId] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [paymentStatusDialog, setPaymentStatusDialog] = useState("");
    const [marginDialogOpen, setMarginDialogOpen] = useState(true);
    const [marginWait, setMarginWait] = useState(false);
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * (3 - 1 + 1) + 1))
    const [ip, setIp] = useState('');
    const [paymentUrl, setPaymentUrl] = useState('');
    const iframeRef = useRef(null);
    const [iframeVisible, setIframeVisible] = useState(false);

    const usedLang = window.localStorage.getItem("pageLang") ? window.localStorage.getItem("pageLang") : "az";
    // const [cvPackOneTranslate,setCvPackOneTranslate] = useState()
    // const [cvPackTwoTranslate,setCvPackTwoTranslate] = useState("")
    // const [hrAdviceTranslate,setHrAdviceTranslate] = useState("")


    const [cvPack1, setCvPack1] = useState({
        id: null,
        productType: "CV_PACK_1",
        name: "cvPack1",
        lang: "az",
        currency: "azn",
        title: "Peşəkar",
        description: "10 CV-lik paket",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "50",
        calcPrice: "50",
        discount: "",
        specs: "",
        status: "active",
    })
    const [cvPack2, setCvPack2] = useState({
        id: null,
        productType: "CV_PACK_2",
        name: "cvPack2",
        lang: "az",
        currency: "azn",
        title: "Korporativ",
        description: "100 CV-lik paket",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "350",
        calcPrice: "350",
        discount: "",
        specs: "",
        status: "active",
    })
    const [hrAdvicePack, setHrAdvicePack] = useState({
        id: null,
        productType: "HR_ADVICE_PACK",
        name: "hrAdvicePack",
        lang: "az",
        currency: "azn",
        title: "Peşəkar HR Məsləhəti",
        description: "Karyeranız üçün Ekspert Təlimatı. Məsləhətləri email və ya telefon vasitəsi ilə alacaqsınız. Düzəliş üçün əlavə 1 pulsuz CV haqqı veriləcək.",
        previewUrl: "",
        filePath: "",
        createDate: "",
        badge: "",
        price: "19.9",
        calcPrice: "19.9",
        discount: "",
        specs: "",
        status: "active",
    })
    const [isGetCvDone,setIsGetCvDone] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCvData = async () => {
            try {
                const resp = await axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id), { withCredentials: true });

                await Promise.all([
                    dispatcher({ type: "CV_INSTANCE_UPDATE", payload: resp.data }),
                    dispatcher({ type: "CV_DATA_UPDATE", payload: resp.data.cvData })
                ]);

                setCvTemplateProduct(resp.data.products.find(item => item.productType === "CV_TEMPLATE"));
                setIsGetCvDone(true);

            } catch (error) {
                console.error("Justice failed 😔", error);
            }
        };

        fetchCvData();
    }, []);




    const changePackTo = (cvPack) => {
        let temp = newCvOrder;
        if (cvPack === "cvPack1") {
            setSelectedPackId(cvPack1.id)

            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products = temp.products.filter(product => product.id !== cvPack2.id);
            temp.products.push(cvPack1)

            setTotalPrice(null)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvPack1.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    temp = resp.data;
                    totalPriceCalc(temp);
                    // setCvPack1(resp.data)
                }
            )

            // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

        } else if (cvPack === "cvPack2") {
            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products = temp.products.filter(product => product.id !== cvPack1.id);
            temp.products.push(cvPack2)

            setTotalPrice(null)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvPack2.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    temp = resp.data;
                    totalPriceCalc(temp);
                    // setCvPack2(resp.data)
                }
            )

            // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            setSelectedPackId(cvPack2.id)

        } else if (cvPack === "cvTemplate") {

            setSelectedPackId(cvTemplateProduct.id)

            temp.products = temp.products.filter(product => product.id !== cvPack1.id);
            temp.products = temp.products.filter(product => product.id !== cvPack2.id);
            temp.products = temp.products.filter(product => product.id !== cvTemplateProduct.id);
            temp.products.push(cvTemplateProduct)

            setTotalPrice(null)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", cvTemplateProduct.id).replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    temp = resp.data;
                    totalPriceCalc(temp);

                    // setCvPack1(resp.data)
                }
            )

            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentValidity.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    if (resp.data === 0.0) {
                        setTotalPrice(0)
                    }}
                )

            // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            setSelectedPackId(cvTemplateProduct.id)


        } else if (cvPack === "hrAdvice") {
            if (!hrAdviceCheck === true) {
                temp.products.push(hrAdvicePack)
                setTotalPrice(null)
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", hrAdvicePack.id).replace("{CvOrderId}", newCvOrder.id),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                        temp = resp.data;
                        totalPriceCalc(temp);
                    }
                )

            } else {
                temp.products = temp.products.filter(product => product.id !== hrAdvicePack.id);
                setTotalPrice(null)
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppSetProductToCvOrder.replace("{productId}", hrAdvicePack.id).replace("{CvOrderId}", newCvOrder.id),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                        temp = resp.data;
                        totalPriceCalc(temp);

                        // setCvPack1(resp.data)
                    }
                )
                // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});

            }
            setHrAdviceCheck(!hrAdviceCheck)

        }

    }

    const updateCvDataInServer = (comeForUpdate) => {

        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), cvAppConfigCvData,
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(resp => {

        })
    }

    const totalPriceCalc = (cvOrder) => {
        let total = 0.0;
        let currency = "";
        cvOrder.products.forEach(product => {
            if (product.productType === "CV_TEMPLATE" && userBalance.balance > 0) {
                product.price = 0;
                product.calcPrice = 0;
            }
            // total = parseFloat(total) + product.calcPrice;
            currency = product.currency;

        })
        total = cvOrder.price
        setTotalPrice(total);
        if (total === 0.0) {
            // changeAgreeWithRules()
        }
        return total + " " + currency;
    }

    const changeAgreeWithRules = () => {
        let temp = newCvOrder;
        temp.agreeWithRules = !agreeWithRules;
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp});
        setAgreeWithRules(!agreeWithRules)
    }

    const checkIsPaid = (clickFrom) => {
        let counter = 0
        let startInterval = false

        if (clickFrom === "button") {
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    setPaymentStatusDialog(resp.data.paymentStatus)
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                }
            )
            if (paymentStatusDialog === "SUCCESS") {
                let temp = newCvOrder;
                temp.pdfUrl = "https://bakujobs.az/api/private/cvapp/orders/" + newCvOrder.id + "/pdfFile"
                dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                updateUserBalance();


            } else if (paymentStatusDialog === "ERROR") {
                let temp = newCvOrder;
                temp.pdfUrl = null
                dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})


            }
            if (counter === 1000) {
                setPaymentStatusDialog("TIMEOUT")
                let temp = newCvOrder;
                temp.pdfUrl = null
                dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
            }
            return
        }

        if (clickFrom === "iframe") {
            setTimeout(() => {
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        setPaymentStatusDialog(resp.data.paymentStatus)
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    }
                )
                if (paymentStatusDialog === "SUCCESS") {
                    let temp = newCvOrder;
                    temp.pdfUrl = "https://bakujobs.az/api/private/cvapp/orders/" + newCvOrder.id + "/pdfFile"
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                    updateUserBalance();


                } else if (paymentStatusDialog === "ERROR") {
                    let temp = newCvOrder;
                    temp.pdfUrl = null
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})


                }
                if (counter === 1000) {
                    setPaymentStatusDialog("TIMEOUT")
                    let temp = newCvOrder;
                    temp.pdfUrl = null
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                }
                return
            }, 2000)

        }


    }



    const checkForPayment = (clickFrom, paymentStatusDialog) => {
        // if user balance is above 0 then send api checkPayment interval update cvorderobj
        // else send api checkPayment wait succes with interval cvorderobj
        setPaymentStatusDialog("WAIT")
        let isFree = true;
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentValidity.replace("{CvOrderId}", newCvOrder.id),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                if (resp.data === 0.0) {
                    setPaymentStatusDialog("SUCCESS")
                    updateUserBalance();
                    return;
                    // dont send req to pasha
                } else {
                    isFree = false;
                }
            }).then((resp) => {
            if (!isFree) {
                axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetPaymentInitiate.replace("{CvOrderId}", newCvOrder.id).replace("{ipAddress}", ip),
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(
                    resp => {
                        if (resp.status === 500) {
                            alert("Server error. Please try again later.\n Xəta bas verdi. Zəhmət olmasa daha sonra yenidən cəhd edin.\n")
                        }
                        setPaymentUrl(resp.data)
                        setPaymentStatusDialog("PROCESSING")
                        openPaymentPopup(resp.data, checkIsPaid)

                    }).catch(err => {
                    setPaymentStatusDialog("ERROR")
                })
            } else {
                // axios.get()
            }
        })


        // setPaymentStatusDialog(paymentStatus)
        // let temp = newCvOrder;
        // temp.pdfUrl = "https://bakujobs.az/api/private/cvapp/orders/"+newCvOrder.id+"/pdfFile"
        // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
        //
        // setPaymentStatusDialog("SUCCESS")

    }


    window.onpopstate = function () {
        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
    }


    useEffect(() => {
        axios.get('https://api.ipify.org?format=json').then((response) => {
            setIp(response.data.ip);
        })

    }, [])

    useEffect(() => {
        if(isGetCvDone!==true){return}
        if (cvPack1.id === null) {
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{productType}", "CV_PACK_1"),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    setCvPack1(resp.data[0])
                }
            )
        }
        if (cvPack2.id === null) {
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{productType}", "CV_PACK_2"),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    setCvPack2(resp.data[0])
                }
            )
        }
        if (hrAdvicePack.id === null) {
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllProducts.replace("{productType}", "HR_ADVICE_PACK"),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    setHrAdvicePack(resp.data[0])
                }
            )
        }

        if (newCvOrder.products.length > 0) {
            if (newCvOrder.products.filter(product => product.productType === "HR_ADVICE_PACK")[0]) {
                setHrAdviceCheck(true)
            }
            if (newCvOrder.products.filter(product => product.productType === "CV_TEMPLATE")[0]) {
                changePackTo("cvTemplate")
            }
        } else {
            changePackTo("cvTemplate")
        }
    }, [isGetCvDone])

    useEffect(() => {
        if (paymentStatusDialog === "PROCESSING") {
            localStorage.setItem("startCheckingPayment", "false")
            let interval = setInterval(() => {
                // console.log("interval " + paymentStatusDialog)
                // console.log("start checking  " + localStorage.getItem("startCheckingPayment"))
                if (localStorage.getItem("startCheckingPayment") === "true") {
                    checkIsPaid("iframe")
                    localStorage.setItem("startCheckingPayment", "false")
                    clearInterval(interval); // Stop polling
                }
            }, 1000);
        }
    }, [paymentStatusDialog]);


    //     useEffect(() => {
    //     if(iframeVisible===false){
    //         checkForPayment("iframe")
    //     }
    // }, [iframeVisible]);

    const openPaymentPopup = (paymentUrl, checkIsPaid) => {
        let windowOptions = "width=800, height=600, resizable, scrollbars=yes, noopener,noreferrer";
        let popup = window.open(paymentUrl, "_blank", windowOptions);
    }


    return cvTemplateProduct!== null && (
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                <Button
                    color="violet"
                    variant="contained"
                    size="large"
                    style={{marginLeft: 23, borderRadius: 30, color: "white"}}
                    onClick={() => {
                        // let temp = {
                        //     id: null,
                        //     status: "",
                        //     paymentStatus: "",
                        //     paymentDateTime: null,
                        //     cvUrl: "",
                        //     previewUrl: "",
                        //     pdfUrl: "",
                        //     isPublic: false,
                        //     language: "az",
                        //     cvData: {},
                        //     price: null,
                        //     products: [],
                        // }
                        // dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
                        navigate({pathname: '/cv', hash: "template"})
                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
                    }
                    }
                >
                    <ArrowBack fontSize="small"/>
                    <Typography style={{marginLeft: 3, fontSize: isMobile ? "13px" : "15px"}} variant="button">
                        {wordsWithLang.vacancyBody.back}
                    </Typography>
                </Button>
            </Grid>
            <Grid container justifyContent="center" style={{margin: "5px", marginTop: "5px"}}>
                <Grid item xs={12}>
                    <Typography ml={7} mt={2}>
                        {wordsWithLang.cvApp.cvAppForms.generalPreview}:</Typography>
                    <Grid container justifyContent={"center"}>
                        {newCvOrder.previewUrl !== null ? (
                            <>

                                <Grid item xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                    <Box component="img"
                                        // src={newCvOrder.previewUrl}
                                         src={marginWait ? Math.random() : apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", newCvOrder.id).replace("{page}", 0) + "?t=" + Date.now().toString().substring(7)}
                                         sx={{
                                             // padding: 2,
                                             width: 595 / 1.5,
                                             position: "relative",
                                             // width: "100%",
                                             boxShadow: "0 6px 15px rgba(0,0,0,.1), 0 0 6px rgba(0,0,0,.1);",
                                             height: 842 / 1.5,
                                             backgroundSize: "contain",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "center",
                                             // backgroundImage: "url('"  +"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png"+ "')",
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center",
                                             margin: "25px auto",
                                         }}
                                    />
                                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                        <Typography variant={"subtitle2"} fontSize={"small"} color={"gray"}
                                        >{"ID: " + newCvOrder.id}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={8} display={"flex"} justifyContent={"center"}>
                                    <Button variant={"contained"}
                                            color={"violet"}
                                            size={"small"}
                                            onClick={() => setMarginDialogOpen(true)}
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                margin: 10,
                                                padding: '5px 25px',
                                                borderRadius: 30
                                            }}
                                    >
                                        <Notes style={{marginRight: 10}}/>
                                        <Typography>{wordsWithLang.cvApp.cvAppForms.template.headerOfMargin}</Typography>
                                    </Button>
                                </Grid>

                            </>
                        ) : (
                            <Typography m={5} color={"error"} fontSize={20} sx={{
                                animation: "fadeIn 2s linear infinite",
                                animationDirection: "alternate-reverse"
                            }}>
                                {wordsWithLang.cvApp.cvAppForms.noPreview}
                            </Typography>
                        )}
                    </Grid>

                </Grid>

                <Grid item xs={12} style={{margin: 20}}>
                    <Divider/>
                </Grid>


                <Grid item xs={12} marginLeft={5} marginRight={2}>
                    <Typography variant="h4" align="center" style={{marginBottom: 20, marginTop: 20}}>
                        {wordsWithLang.cvApp.cvAppForms.choseCvPack}

                    </Typography>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} sm={4}>
                            <Paper
                                elevation={3}
                                style={{
                                    padding: 20,
                                    textAlign: 'center',
                                    borderRadius: '15px',
                                    borderColor: selectedPackId === cvTemplateProduct.id ? "#4c3198" : "#ffffff",
                                    borderWidth: 3,
                                    borderStyle: "solid",
                                    transition: 'all .3s',
                                    // boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
                                }}
                            ><Grid></Grid>
                                <Typography variant="h5"
                                            style={{marginBottom: 10, width: "100%", overflowWrap: "anywhere"}}>
                                    <AccountCircleOutlined style={{
                                        fontSize: 25,
                                        marginBottom: 8,
                                        borderBottom: "2px solid #676767",
                                        color: "#676767",
                                        padding: 2
                                    }} thickness="thin"/>
                                    <br/>
                                    {wordsWithLang.cvApp.cvAppForms.individualPack}
                                </Typography>
                                <Typography variant="h6" color="textSecondary"
                                            style={{marginBottom: 20, fontSize: 18}}>
                                    {wordsWithLang.cvApp.cvAppForms.individualDesc}
                                </Typography>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <CloseOutlined style={{fontSize: 20, marginRight: 8}} color="error"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.editableCv}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <CloseOutlined style={{fontSize: 20, marginRight: 8}} color="error"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.onePrice}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.oneTimeUse}
                                    </Typography>
                                </Box>
                                <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvTemplateProduct.price + cvTemplateProduct.currency}</span>
                                    {"  " + cvTemplateProduct.calcPrice + " " + cvTemplateProduct.currency}
                                </Typography>
                                <Button
                                    disabled={selectedPackId === cvTemplateProduct.id}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    style={{color: 'white', borderRadius: 20}}
                                    onClick={() => {
                                        changePackTo("cvTemplate")
                                    }}
                                >
                                    {wordsWithLang.cvApp.cvAppForms.choosePack}
                                </Button>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Paper
                                elevation={3}
                                style={{
                                    padding: 20,
                                    textAlign: 'center',
                                    borderRadius: '15px',
                                    borderColor: selectedPackId === cvPack1.id ? "#4c3198" : "#ffffff",
                                    borderWidth: 3,
                                    borderStyle: "solid",
                                    transition: 'all .3s',
                                }}
                            >
                                <Typography variant="h5"
                                            style={{marginBottom: 10, width: "100%", overflowWrap: "anywhere"}}>
                                    <BusinessCenterOutlined style={{
                                        fontSize: 25,
                                        marginBottom: 8,
                                        borderBottom: "2px solid #676767",
                                        color: "#676767",
                                        padding: 2
                                    }} thickness="thin"/>
                                    <br/>
                                    {newCvOrder && cvPack1.title[usedLang]}
                                </Typography>
                                <Typography variant="h6" color="textSecondary"
                                            style={{marginBottom: 20, fontSize: 18}}>
                                    {cvPack1.description[usedLang]}
                                </Typography>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.editableCv}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.onePrice}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.sixMonthUse}
                                    </Typography>
                                </Box>
                                <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvPack1.price + cvPack1.currency}</span>

                                    {"  " + cvPack1.calcPrice + " " + cvPack1.currency}
                                </Typography>
                                <Button
                                    disabled={selectedPackId === cvPack1.id}
                                    variant="contained"
                                    fullWidth
                                    color="success"
                                    style={{color: 'white', borderRadius: 20}}
                                    onClick={() => {
                                        changePackTo("cvPack1")
                                    }}
                                >
                                    {wordsWithLang.cvApp.cvAppForms.choosePack}
                                </Button>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Paper
                                elevation={3}
                                style={{
                                    padding: 20,
                                    textAlign: 'center',
                                    borderRadius: '15px',
                                    borderColor: selectedPackId === cvPack2.id ? "#4c3198" : "#ffffff",
                                    borderWidth: 3,
                                    borderStyle: "solid",
                                    transition: 'all .3s',
                                }}
                            >
                                <Typography variant="h5"
                                            style={{marginBottom: 10, width: "100%", overflowWrap: "anywhere"}}>
                                    <CorporateFareOutlined style={{
                                        fontSize: 25,
                                        marginBottom: 8,
                                        borderBottom: "2px solid #676767",
                                        color: "#676767",
                                        padding: 2
                                    }} thickness="thin"/>
                                    <br/>
                                    {newCvOrder && cvPack2.title[usedLang]}
                                </Typography>
                                <Typography variant="h6" color="textSecondary"
                                            style={{marginBottom: 20, fontSize: 18}}>
                                    {cvPack2.description[usedLang]}
                                </Typography>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.choosingColor}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.editableCv}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.onePrice}
                                    </Typography>
                                </Box>
                                <Box style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <Check style={{fontSize: 20, marginRight: 8}} color="success"/>
                                    <Typography variant="body1" color="textSecondary" align={"left"}>
                                        {wordsWithLang.cvApp.cvAppForms.yearUse}
                                    </Typography>
                                </Box>
                                <Typography variant="h6" color="primary" style={{marginBottom: 20}}>
                                    <span style={{
                                        color: "darkgray",
                                        textDecoration: "line-through",
                                        fontSize: "18px"
                                    }}>{cvPack2.price + cvPack2.currency}</span>
                                    {"  " + cvPack2.calcPrice + " " + cvPack2.currency}
                                </Typography>
                                <Button
                                    disabled={selectedPackId === cvPack2.id}
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    style={{color: 'white', borderRadius: 20}}
                                    onClick={() => {
                                        changePackTo("cvPack2")
                                    }}
                                >
                                    {wordsWithLang.cvApp.cvAppForms.choosePack}
                                </Button>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Paper
                                elevation={3}
                                style={{padding: 20, textAlign: 'left', borderRadius: '15px'}}

                            >
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item xs={12} style={{width: "100%"}}>
                                        <Typography variant="h6" style={{marginRight: 20, cursor: "pointer"}}
                                                    onClick={() => {
                                                        changePackTo("hrAdvice")
                                                    }}>
                                            <Checkbox checked={hrAdviceCheck} color="primary" size={"medium"}/>
                                            {newCvOrder && hrAdvicePack.title[usedLang]}
                                        </Typography>
                                        <Typography variant={"body2"} color="textSecondary"
                                                    style={{marginLeft: 40}}>
                                            {hrAdvicePack.description[usedLang]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} justifyContent={"flex-end"}>
                                        {/*<Box alignItems={"flex-end"} display={"block"} style={{width:"100%"}}>*/}
                                        <Typography variant="h6" display={"block"} align={"right"}
                                                    color="primary">
                                            <span style={{
                                                color: "darkgray",
                                                textDecoration: "line-through",
                                                fontSize: "18px"
                                            }}>{hrAdvicePack.price + hrAdvicePack.currency}</span>

                                            {"  " + hrAdvicePack.calcPrice + " " + hrAdvicePack.currency}
                                        </Typography>
                                        {/*</Box>*/}
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>


                    </Grid>
                </Grid>


                <Grid item xs={12} style={{marginTop: 200}}>
                    <Divider/>
                </Grid>


                <Grid container justifyContent={"center"}
                      style={{
                          boxShadow: "0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3)",
                          position: isMobile !== null ? 'fixed' : 'static',
                          bottom: isMobile !== null ? 0 : 'auto',
                          left: isMobile !== null ? 0 : 'auto',
                          width: isMobile !== null ? '100%' : 'auto',
                          zIndex: isMobile !== null ? 999 : 'auto',
                          backgroundColor: "white"
                      }}>

                    <Grid item xs={10} p={2}>
                        <Grid container>
                            <Box display={"flex"} alignItems={"center"} height={"1.2rem"}>
                                <span style={{display: "flex"}}>{wordsWithLang.cvApp.cvAppForms.totalPrice + ":"}</span>
                                <span style={{
                                    display: "flex",
                                    marginLeft: "1rem"
                                }}>{totalPrice !== null ? totalPrice + " " + cvTemplateProduct.currency :
                                    <CircularProgress size={20} color={"secondary"}/>}</span></Box>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} md={6} p={1}>
                        <Button
                            variant="contained"
                            startIcon={<AutoFixHigh fontSize="medium" style={{color: 'white'}}/>}
                            className={newCvOrder.previewUrl === "" || newCvOrder.previewUrl === null || !agreeWithRules ? "addButton continueButton btn disabledButton" : "addButton continueButton btn"}
                            size="small"
                            disabled={newCvOrder.previewUrl === "" || newCvOrder.previewUrl === null || !agreeWithRules}
                            style={{width: isMobile !== null ? '100%' : '65%', marginTop: 15, marginBottom: 15}}
                            onClick={() => {
                                checkForPayment("button", paymentStatusDialog)
                            }}
                        >
                            {wordsWithLang.cvApp.cvAppForms.complete}
                        </Button>
                    </Grid>
                </Grid>


                {/*preview margin editor*/}

                <CvAppPreviewDetailedViewDialog newCvOrder={newCvOrder} marginDialogOpen={marginDialogOpen}
                                                setMarginDialogOpen={setMarginDialogOpen} wordsWithLang={wordsWithLang}
                                                marginWait={marginWait} setMarginWait={setMarginWait}/>

                {/*preview margin editor*/}


                <Dialog PaperProps={{style: {overflow: 'hidden'}}}
                        open={paymentStatusDialog === "PROCESSING" || paymentStatusDialog === "WAIT" || paymentStatusDialog === "SUCCESS" || paymentStatusDialog === "ERROR" || paymentStatusDialog === "TIMEOUT"}>
                    {/*<DialogTitle>{wordsWithLang.header.notification}</DialogTitle>*/}
                    <DialogContent style={{padding: "15", overflow: "hidden"}}>
                        {paymentStatusDialog === "SUCCESS" ? (successDialog(wordsWithLang, newCvOrder)) : null}
                        {paymentStatusDialog === "ERROR" ? (errorDialog(wordsWithLang)) : null}
                        {/*{paymentStatusDialog === "PROCESSING" ? (<ProcessingDialog(iframeRef,paymentUrl,iframeVisible,setIframeVisible, checkForPayment)) : null}*/}
                        {paymentStatusDialog === "PROCESSING" ? (
                            <ProcessingDialog iframeVisible={iframeVisible} paymentUrl={paymentUrl}
                                              setIframeVisible={setIframeVisible} iframeRef={iframeRef}
                                              checkForPayment={checkForPayment} wordsWithLang={wordsWithLang}/>) : null}
                        {paymentStatusDialog === "TIMEOUT" ? (timeoutDialog(wordsWithLang)) : null}
                        {paymentStatusDialog === "WAIT" ? (waitDialog(wordsWithLang)) : null}
                    </DialogContent>
                    <DialogActions style={{padding: 15}}>
                        <Button onClick={() => {
                            if (paymentStatusDialog === "SUCCESS") {
                                window.location.reload()
                            }
                            if (paymentStatusDialog === "ERROR") {
                                updateCvDataInServer("mainColor")
                            }
                            setIframeVisible(false);
                            setPaymentStatusDialog("")

                        }} color="primary">
                            {wordsWithLang.cvApp.cancel}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}

const successDialog = (wordsWithLang, newCvOrder) => {

    return (<span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                            <CheckCircle color="success" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.successCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.successCvGenerated2}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "black",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }} onMouseEnter={(e) => {
                                    e.currentTarget.style.color = 'red'
                                }} onMouseLeave={(e) => {
                                    e.currentTarget.style.color = 'black'
                                }}
                                   onClick={() => {
                                       window.open("https://bakujobs.az/api/private/cvapp/orders/" + newCvOrder.id + "/pdfFile", '_blank');
                                   }}>{wordsWithLang.cvApp.cvAppForms.canDownloadFromHere}</p>
                                <Box
                                    display={newCvOrder.products.some(product => product.productType === 'HR_ADVICE_PACK') ? "flex" : "none"}
                                    alignItems="center" style={{color: "darkgray"}}><InfoOutlined color="primary"
                                                                                                  style={{margin: 10}}/> <p>{wordsWithLang.cvApp.cvAppForms.hrWillConnect}</p></Box>
                        <Confetti width={window.screen.width} height={window.screen.width}/>
                            </span>)

}

const errorDialog = (wordsWithLang) => {
    return (
        <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Cancel color="error" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated2}</p>
                            </span>
    )
}

const ProcessingDialog = ({iframeRef, paymentUrl, iframeVisible, setIframeVisible, checkForPayment, wordsWithLang}) => {
    return (
        <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
            <Typography variant={"h4"} display={"flex"} flexDirection={"column"} alignItems={"center"}
                        textAlign={"center"}>
        <Directions sx={{fontSize:85, marginBottom:2}} color={"success"}/>

    <Box fontWeight={500} display={"flex"} alignItems={"center"} gap={1}>
        {wordsWithLang.cvApp.cvAppForms.redirectedToPayment}
    </Box>
                <Box>
    <CircularProgress sx={{fontSize:140, marginTop: 3}} color={"success"} />
                </Box>
</Typography>

            {/*<iframe*/}
            {/*    id={"paymentIframe"}*/}
            {/*    ref={iframeRef}*/}
            {/*    src={paymentUrl}*/}
            {/*    width="auto"*/}
            {/*    height="600px"*/}
            {/*    title="Payment Processing"*/}
            {/*    style={{border: 'none', display: iframeVisible ? "block" : "none", minWidth: "350px"}}*/}
            {/*/>*/}

            {/*<embed*/}
            {/*    id={"paymentIframe"}*/}
            {/*    src={paymentUrl}*/}
            {/*    ref={iframeRef}*/}
            {/*    width="100%"*/}
            {/*    height="700px"*/}
            {/*    style={{border: 'none', display: iframeVisible ? "block" : "none", minWidth: "350px"}}*/}
            {/*/>*/}
            {/*{openPaymentPopup(paymentUrl,iframeVisible)}*/}
            {/*{openPaymentPopup(paymentUrl, iframeVisible, setIframeVisible,() => {*/}
            {/*    setIframeVisible(false);*/}
            {/*    checkForPayment("iframe")*/}
            {/*})}*/}

                            </span>

    )
}

const timeoutDialog = (wordsWithLang) => {
    return (

        <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Cancel color="error" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.errorCvGenerated2}</p>
                            </span>

    )
}

const waitDialog = (wordsWithLang) => {
    return (
        <span style={{display: "block", textAlign: "center", fontWeight: "bold"}}>
                                <Timelapse color="warning" style={{fontSize: 70}}/>
                                <p style={{fontSize: 24}}>{wordsWithLang.cvApp.cvAppForms.waitCvGenerated}</p>
                                <p style={{
                                    fontSize: 18,
                                    color: "darkgray"
                                }}>{wordsWithLang.cvApp.cvAppForms.waitCvGenerated2}</p>
                            </span>
    )
}


export default CvAppPreview;



