import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Pagination, Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {CancelOutlined, CheckCircleOutline, CloseOutlined} from "@mui/icons-material";
import AdminCvUserTab from "../tabs/AdminCvUserTab";

const AdminUsers = ({control, setControl}) => {
    const [tabVal, setTabVal] = useState("one")
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                {/*<Typography m={1} variant={"h5"}>Users</Typography>*/}
                </Grid>

                {/*<Divider sx={{width: "100%"}}/>*/}

                <Grid item xs={12} mt={0} mb={1}>
                    <Tabs
                        value={tabVal}
                        onChange={(e, val) => {setTabVal(val);}}
                    >
                        <Tab value="one" label="USERS"/>
                        <Tab value="two" label="OTHER"/>
                        {/*<Tab value="two" label="CV ORDERS"/>*/}
                    </Tabs>
                </Grid>
                <Grid container display={tabVal === "one" ? "grid" : "none"}>
                    <AdminCvUserTab control={control} setControl={setControl} />
                    {/*<AdminCvOrdersTab setControl={setControl} control={control} wordsWithLang={wordsWithLang} />*/}
                </Grid>
                <Grid container display={tabVal === "two" ? "grid" : "none"}>
                    <Box>Empty</Box>

                </Grid>
            </Grid>

            {/*<AdminCvUserTab control={control} setControl={setControl}/>*/}
        </div>
    );
}

export default AdminUsers;
