import React, {useEffect} from 'react';
import {setDocDesc, setDocTitle} from "../utils/Utils";
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import MiniContact from "../miniComponents/miniContact";
import {Link} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";

const Services = ({wordsWithLang}) => {
    useEffect(()=>{
        setDocTitle(wordsWithLang.general.documentTitleFromServices);
        setDocDesc(wordsWithLang.content.services)
    },[])

    return (
        <Grid container justifyContent={"center"} alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            <Grid item xs={12}>
                <Link to={-1}><Button style={{ borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                    <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                <MiniContact viewType={"onlyContact"} wordsWithLang={wordsWithLang}/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {useMediaQuery(theme => theme.breakpoints.down("sm"))
                    ? (<div dangerouslySetInnerHTML={{__html: wordsWithLang.content.servicesXs}}></div>) :
                    (<div dangerouslySetInnerHTML={{__html: wordsWithLang.content.services}}></div>)}
                <div dangerouslySetInnerHTML={{__html: wordsWithLang.content.services2}}></div>
                <br/>
                <br/>
                <br/>
                <br/>
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}

        </Grid>
    );
};

export default Services;
