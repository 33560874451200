import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Chip,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    Divider,
    Grid, IconButton,
    Pagination,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";
import {ArrowDownward, DeleteForever, RefreshOutlined} from "@mui/icons-material";
import axios from "axios";
import apiUrls from "../../apiUrls";
import CvOrderEdit from "../../wrapperComponents/cvApp/cvOrderEdit";

const AdminCvOrdersTab = ({wordsWithLang, control, setControl}) => {

    const [showMore, setShowMore] = useState(null);
    const [showAsUserDialog, setShowAsUserDialog] = useState(false);
    const [dialogCvOrder, setDialogCvOrder] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(5);
    const [cvOrderList, setCvOrderList] = useState([]);

    const getAllCvOrders = () => {
        setCvOrderList([])
        axios.get(apiUrls.baseApiUrl + apiUrls.getAdminCvOrders.replace("{page}", page), {withCredentials: true}).then((resp) => {
            if (resp.data !== [] || resp.data !== null) {
                setCvOrderList(resp.data)
            } else {
                alert("Results ended!")
            }
        })
    }

    useEffect(() => {
        console.log("running ")
        getAllCvOrders();
        setCount(page + 3)
    }, [page, control.callGetAllCvOrders]);

    return (
        <>
            <Grid item xs={12}>
                <IconButton m={1} onClick={getAllCvOrders}><RefreshOutlined/></IconButton>
                {cvOrderList.length > 0 ? cvOrderList.map((item, index) => {
                        return <AdminCvOrderItem
                            key={index}
                            setControl={setControl}
                            control={control}
                            index={index}
                            value={item}
                            showMore={showMore}
                            setShowMore={setShowMore}
                            setShowAsUserDialog={setShowAsUserDialog}
                            setDialogCvOrder={setDialogCvOrder}/>
                    }
                ) : <Typography sx={{padding: 5}} align={"center"} variant={"h4"}>
                    <CircularProgress size={50} color={"violet"}/>
                </Typography>}
            </Grid>

            <Grid item xs={12} margin={5}>
                <Pagination onChange={(e, value) => {
                    setPage(value)
                }} count={count} variant="outlined"/>
            </Grid>
            {showAsUserDialog &&
                (<AdminAsUserDialog
                        wordsWithLang={wordsWithLang}
                        showAsUserDialog={showAsUserDialog}
                        dialogCvOrder={dialogCvOrder}
                        setShowAsUserDialog={setShowAsUserDialog}/>
                )}
        </>

    );
}

const AdminCvOrderItem = ({
                              index,
                              value,
                              showMore,
                              setShowMore,
                              setShowAsUserDialog,
                              setDialogCvOrder,
                              control,
                              setControl
                          }) => {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const fileInputRef = useRef(null);

    const [uploadLoading, setUploadLoading] = useState(false)

    const handleFileChange = (event, orderId) => {
        const file = event.target.files[0];
        if (!file || file.type !== "text/html") {
            alert("Please upload a valid HTML file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            setUploadLoading(true)
            axios.post(
                apiUrls.baseApiUrl + apiUrls.postAdminUploadHtml.replace("{id}", orderId),
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true
                }
            ).then(resp => {
                alert("File uploaded successfully!");
                console.log("Upload response:", resp.data);
                setUploadLoading(false)
            })

        } catch (error) {
            console.error("Upload failed:", error);
            alert("File upload failed. Please try again.");
        }
    };

    return (
        <Card style={{
            fontSize: ".5rem",
            // borderBottom: ".5px solid gray",
            flexWrap: "wrap",
            boxShadow: "",
            borderRadius: 10,
            margin: 7,
            padding: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly"
        }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}>
                <Box fontSize={"small"} display={"inline-flex"}
                     style={{justifyContent: "center", maxWidth: "80px", flexWrap: "wrap"}}>
                    <Box sx={{display: "flex", flexWrap: "wrap", fontSize: 10, padding: .3}} color={"info"}
                         variant={"text"}>
                        <Chip size={"small"} label={value.language.toUpperCase()} variant={"outlined"} sx={{fontSize: 10, height: 15}}
                              color={"secondary"}/>

                        <Chip size={"small"} label={value.status} variant={"outlined"} sx={{fontSize: 10, height: 15}}
                              color={value.status === "DRAFT" || value.status === "DRAFT2" ? "warning" : value.status === "DELETED" ? "secondary" : "success"}/>



                        {value.lastPreviewGenTime && (<Chip sx={{fontSize: 10, marginTop: 1}}
                                                            color={value.lastPreviewGenTime.split("T")[0] === new Date().toISOString().split("T")[0] ? "success" : "info"}
                                                            size={"small"} variant={"outlined"}
                                                            label={value.lastPreviewGenTime.split("T")[0] + " - " + value.lastPreviewGenTime.split("T")[1].substring(0, 5)}/>)}
                    </Box>


                </Box>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Typography fontSize={11} variant={"button"} display={"inline-flex"}>{value.id}</Typography>
                <Typography fontSize={9} variant={"button"} display={"inline-flex"}>{"T:"+value.cvData.templateId}</Typography>
                {/*<Typography fontSize={10} sx={{color:"red"}} display={"inline-flex"}>{value.status}</Typography>*/}
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Typography fontSize={"small"}
                            display={"inline-flex"}>{value.customUser && value.customUser.email}</Typography>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Box fontSize={"small"} display={"inline-flex"}>
                    <Chip size={"small"} sx={{fontSize: 10, height: 20, color: "white"}}
                          color={value.paymentStatus === "SUCCESS" ? "success" : "warning"}
                          label={value.paymentStatus}/>
                </Box>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Box fontSize={"small"} display={"inline-flex"}
                     style={{justifyContent: "center", maxWidth: "80px", flexWrap: "wrap"}}>
                    <Button
                        disabled={uploadLoading}
                        sx={{display: "flex", flexWrap: "wrap", fontSize: 10, padding: 0.3}}
                        color="info"
                        variant="text"
                        onClick={() => fileInputRef.current.click()}
                    >
                        Upload HTML
                    </Button>
                    <input
                        type="file"
                        accept=".html"
                        ref={fileInputRef}
                        style={{display: "none"}}
                        onChange={(e) => {
                            if (window.confirm("SURE ?")) {
                                handleFileChange(e, value.id)
                            }
                        }}
                    />
                    <Button sx={{display: "flex", flexWrap: "wrap", fontSize: 10, padding: .3}} color={"secondary"}
                            onClick={() => {
                                if (window.confirm("SURE ?")) {
                                    axios.get(apiUrls.baseApiUrl + apiUrls.getAdminReplacePdf.replace("{id}", value.id), {
                                        withCredentials: true
                                    }).then((resp) => {
                                        console.log(resp.data)
                                    })
                                }
                            }}
                            disabled={uploadLoading}
                            variant={"text"}>Replace PDF</Button>
                </Box>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Box fontSize={"small"} display={"inline-flex"} style={{maxWidth: "50px", flexWrap: "wrap"}}>
                    <Button sx={{display: "flex", flexWrap: "wrap", fontSize: 10, padding: .3}} color={"info"}
                            onClick={() => {
                                openInNewTab("https://bakujobs.az/api/private/cvapp/orders/" + value.id + "/htmlFileString")
                            }}
                            variant={"text"}>HTML</Button>
                    <Button sx={{display: "flex", flexWrap: "wrap", fontSize: 10, padding: .3}} color={"secondary"}
                            onClick={() => {
                                openInNewTab("https://bakujobs.az/api/private/cvapp/orders/" + value.id + "/pdfFileAdmin")
                            }}
                            variant={"text"}>PDF</Button>
                </Box>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx}/>

                <Box fontSize={"small"} display={"inline-flex"}>
                    <Button onClick={() => {
                        axios.get(apiUrls.baseApiUrl+"/api/admin/cv/asUser/"+value.customUser.email,{withCredentials:true}).then(()=>{
                            setDialogCvOrder(value)
                            setShowAsUserDialog(true)
                        })

                    }} sx={{fontSize: 10, padding: .3}} color={"warning"} variant={"outlined"}>
                        As User</Button></Box>
                <Divider orientation="vertical" variant={"fullWidth"} sx={dividerSx} style={{marginRight: 0}}/>

                <Button sx={{
                    maxWidth: "40px",
                    width: "30px",
                    minWidth: "0px",
                    display: "flex",
                    flexWrap: "wrap",
                    fontSize: 10,
                }} color={"secondary"}
                        variant={"text"}><ArrowDownward fontSize={"small"}
                                                        onClick={() => {
                                                            index === showMore ? setShowMore(null) : setShowMore(index)
                                                        }}/></Button>
            </Box>


            <Grid container style={{display: showMore !== index ? "none" : "flex", width: "100%", flexWrap: "wrap"}}>
                <AdminCvOrderMore key={"m" + index} cvOrder={value} control={control} setControl={setControl}/>
            </Grid>

        </Card>
    )
}

const AdminCvOrderMore = ({cvOrder, control, setControl}) => {
    const deleteCvOrderForever = () => {
        axios.delete(apiUrls.baseApiUrl + apiUrls.deleteAdminCvOrderDelete.replace("{id}", cvOrder.id), {withCredentials: true}).then((reps) => {
            console.log(reps.data)
            // setControl({...control, callGetAllCvOrders: !control.callGetAllCvOrders})
        })
    }
    return (<Grid item xs={12} display={"flex"} flexWrap={"wrap"} style={{padding: 10}}>
        <Divider variant={"fullWidth"}/>
        <Box width={"100%"}>
            <Button color={"secondary"} onClick={() => {
                if (window.confirm("REALLY DELETE?")) {
                    deleteCvOrderForever()
                }
            }}><DeleteForever/> DELETE FOREVER</Button>
        </Box>
        <Box width={"100%"} display={"flex"} alignItems={"flex-start"} flexWrap={"wrap"} mt={2}>
            <img width={"250"} border={"1px solid gray"}
                 src={cvOrder.previewUrl ? apiUrls.baseApiUrl + apiUrls.getAdminCvOrderPreview.replace("{id}", cvOrder.id) + "/0?t=1" : "https://placehold.co/300x450?text=No+Image"}
                 alt=""/>
            <img width={"250"} border={"1px solid gray"}
                 src={cvOrder.previewUrl ? apiUrls.baseApiUrl + apiUrls.getAdminCvOrderPreview.replace("{id}", cvOrder.id) + "/1?t=1" : "https://placehold.co/300x450?text=No+Image"}
                 alt=""/>
            <img width={"250"} border={"1px solid gray"}
                 src={cvOrder.previewUrl ? apiUrls.baseApiUrl + apiUrls.getAdminCvOrderPreview.replace("{id}", cvOrder.id) + "/2?t=1" : "https://placehold.co/300x450?text=No+Image"}
                 alt=""/>
        </Box>
        <Box display={"flex"} width={"100%"} mt={1}>
            <details
                style={{
                    width: "100%",
                    border: "1px solid greenyellow",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "#111"
                }}>
                <summary style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "greenyellow",
                    padding: "5px",
                    userSelect: "none"
                }}>
                    JSON Object - POST: <a style={{color: "darkgray"}}
                                           href={apiUrls.baseApiUrl + apiUrls.postAdminCvOrders}>{apiUrls.baseApiUrl + apiUrls.postAdminCvOrders}</a>
                </summary>

                <pre style={{
                    padding: 10,
                    fontSize: 11,
                    color: 'greenyellow',
                    backgroundColor: 'black',
                }}>
                {JSON.stringify(cvOrder, null, 2)}
            </pre>
            </details>
        </Box>
    </Grid>)
}

const AdminAsUserDialog = ({wordsWithLang, showAsUserDialog, setShowAsUserDialog, dialogCvOrder}) => {
    console.log(dialogCvOrder)
    return (
        <Dialog open={showAsUserDialog} fullScreen onClose={() => {
            setShowAsUserDialog(false)
        }}>
            <DialogActions sx={{padding: 2, display: "flex", justifyContent: "space-between"}}>
                <Typography display={"flex"} variant={"h5"} justifyContent={"flex-start"}>EDIT as User</Typography>
                <Button variant={"outlined"} color={"secondary"} size={"large"} onClick={() => {
                    setShowAsUserDialog(false)
                }}>Close</Button>
            </DialogActions>
            <DialogContent>
                <CvOrderEdit wordsWithLang={wordsWithLang} adminCvOrder={dialogCvOrder}/>
            </DialogContent>

        </Dialog>
    )
}

// --------------styles---------------

const dividerSx = {
    height: "1rem",
    marginRight: 1,
    marginLeft: 1,
    display: "inline-flex",
    verticalAlign: "middle",
};

export default AdminCvOrdersTab;
