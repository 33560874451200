import React, {useEffect, useMemo, useState} from 'react';
import {AppBar, Box, Button, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector} from "react-redux";
import {DocumentScanner, Edit, Email, Favorite, Home, ListAlt} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import Search from "./search";
import axios from "axios";
import apiUrls from "../apiUrls";
import MiniContact from "../miniComponents/miniContact";


const MyAppBar = ({wordsWithLang}) => {
    const theme = useTheme()
    const mainConfig = useSelector(state => state.mainConfig)
    const userConfig = useSelector(state => state.userConfig)
    // const mobileClass = useMediaQuery() ? "-m" : "";
    // const [mobileClass,setMobileClass] = useState("")
    // const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    //
    // useEffect(() => {
    //     setMobileClass(isSM ? "-m" : "");
    // },[isSM])

    const mobileClass = useMediaQuery(theme.breakpoints.down("sm")) ? "-m" : "";


    const dispatcher = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const palette = useTheme().palette;

    const [anchorElLang, setAnchorElLang] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const avatarMenuClick = (event) => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const avatarMenuClose = () => {
        setAnchorEl(null);
    };
    const logOut = () => {
        dispatcher({type: "CHANGE_LOGIN", payload: false})
        dispatcher({type: "SET_USER", payload: {}})

        axios.post(apiUrls.baseApiUrl + apiUrls.logout, userConfig,{withCredentials:"include"}).then(resp => {
            if (resp.data) {
                    dispatcher({type: "SET_USER", payload: null})
                    dispatcher({type: "CHANGE_LOGIN", payload: false})
                    dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})

                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";

            }

        })

        alert(wordsWithLang.header.logouted)
        setAnchorEl(null);

    }

    const handleClickLang = (event) => {
        setAnchorElLang(event.currentTarget);
    };

    const handleCloseLang = () => {
        setAnchorElLang(null);
    };
    const handleLanguageChange = (lang) => {
        window.localStorage.setItem("pageLang",lang)
        handleCloseLang();
        window.location.reload();
    };


    const checkAuthAndNavigateTo = (path) => {
        if (userConfig.isLogin) {
            navigate(path)
        } else {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
    }

    const vacancyHeader = (wordsWithLang) => {
        return (<MiniContact viewType={"onlyLinks"} wordsWithLang={wordsWithLang}/>)
    }
    const favoritesHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2} style={{marginTop:"1rem"}}>
                    <Favorite style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 50,marginBottom: 0}}
                              color={"secondary"}/>
                    <Typography  display={"inline"} fontWeight={"600"}
                                 style={{marginBottom:0, fontSize:mainConfig.isMobile?"1.5rem":"2rem", color:"#240085"}}
                                 align={"center"} variant={"h3"}>{wordsWithLang.header.favoriteAds}</Typography>

                </Stack>
                {/*<br/>*/}

                {/*<Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}*/}
                {/*        variant={"contained"} size="small" display={"block"} align={"right"}>*/}
                {/*    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}*/}
                {/*          titleAccess={wordsWithLang.header.mainPage}/>*/}
                {/*    {wordsWithLang.header.mainPage}*/}
                {/*</Button>*/}
            </Box>)
    }

    const formsHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <ListAlt style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 40, marginBottom: 10}}
                              color={"primary"}/>
                    {/*<Typography style={{color: "white", textShadow: "-2px 2px 5px #1A237E"}} display={"inline"}*/}
                    {/*            align={"center"} variant={"h4"}>{wordsWithLang.header.formHeader}</Typography>*/}
                    <Typography  display={"inline"} fontWeight={"900"}
                                style={{marginBottom:10, fontSize:mainConfig.isMobile?"1.5rem":"2rem", color:"#240085"}}
                                align={"center"} variant={"h3"}>{wordsWithLang.header.formHeader}</Typography>

                </Stack>

                <Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}
                        variant={"contained"} size="small" display={"block"} align={"right"}>
                    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}
                          titleAccess={wordsWithLang.header.mainPage}/>
                    {wordsWithLang.header.mainPage}
                </Button>
            </Box>)
    }

    const blogHeader = (wordsWithLang) => {

        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2} style={{marginTop:"1rem"}}>
                    <Edit style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 50,marginBottom: 0}}
                              color={"violet"}/>
                    <Typography  display={"inline"} fontWeight={"600"}
                                 style={{marginBottom:0, fontSize:mainConfig.isMobile?"1.5rem":"2rem", color:"#240085"}}
                                 align={"center"} variant={"h3"}>{wordsWithLang.header.blogNotes}</Typography>

                </Stack>
                {/*<br/>*/}

                {/*<Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}*/}
                {/*        variant={"contained"} size="small" display={"block"} align={"right"}>*/}
                {/*    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}*/}
                {/*          titleAccess={wordsWithLang.header.mainPage}/>*/}
                {/*    {wordsWithLang.header.mainPage}*/}
                {/*</Button>*/}
            </Box>)
    }


    const subscribeHeader = () => {
        return (<Box style={{width: "100%", verticalAlign: "middle"}}>

            <Stack direction="row" alignItems="center" gap={2} style={{marginTop:"1rem"}}>
                <Email style={{textShadow: "-2px 2px 10px #ffffff", fontSize: 50,marginBottom: 0}}
                          color={"info"}/>
                <Typography  display={"inline"} fontWeight={"600"}
                             style={{marginBottom:0, fontSize:mainConfig.isMobile?"1.5rem":"2rem", color:"#240085"}}
                             align={"center"} variant={"h3"}>{wordsWithLang.header.subscriptionsAds}</Typography>

            </Stack>
        </Box>)
    }

    const cvAppHeader = (wordsWithLang) => {
        return (
            <Box style={{width: "100%", verticalAlign: "middle"}}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <DocumentScanner  style={{textShadow: "-2px 2px 10px #837BE8", fontSize: 50, color:"#4c3198"}}
                          // color="primary"
                    />
                    <Typography className={"typewriter"} display={"inline"} fontWeight={"900"}
                                style={{fontSize:mainConfig.isMobile?"1.2rem":"1.5rem", color:"#240085"}}
                                align={"center"} variant={"h4"}>{wordsWithLang.cvApp.cvAppHeader}</Typography>

                </Stack>
                {/*<br/>*/}
                {/*<Button style={{backgroundColor: "#837BE8", color: "white"}} onClick={() => navigate("/")}*/}
                {/*        variant={"contained"} size="small" display={"block"} align={"right"}>*/}
                {/*    <Home style={{fontSize: "28px", color: "white", paddingRight: "5px"}}*/}
                {/*          titleAccess={wordsWithLang.header.mainPage}/>*/}
                {/*    {wordsWithLang.header.mainPage}*/}
                {/*</Button>*/}
            </Box>)
    }


    const renderSearch = () => {
        if(mainConfig.isFilterOpen){
            if (location.pathname.split("/")[1]==="" || location.pathname.split("/")[1]==="company"|| location.pathname.split("/")[1]==="category"){
                document.getElementsByClassName("header").length>0 ? document.getElementsByClassName("header")[0].style.height = "290px" :console.log()
                document.getElementsByClassName("header-m").length>0 ? document.getElementsByClassName("header-m")[0].style.height = "420px" :console.log()
            }
            else {
                document.getElementsByClassName("header").length>0 ? document.getElementsByClassName("header")[0].style.height = "200px" :console.log()
                document.getElementsByClassName("header-m").length>0 ? document.getElementsByClassName("header-m")[0].style.height = "200px" :console.log()
            }
        }
        let comp = null;
        switch (location.pathname.split("/")[1]) {
            case "" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;

            case "vacancy" :
                comp = vacancyHeader(wordsWithLang);
                break;

            case "cv" :
                comp = cvAppHeader(wordsWithLang);
                break;


            case "favorites" :
                comp = favoritesHeader(wordsWithLang)
                break;

            case "forms" :
                comp = formsHeader(wordsWithLang)
                break;

            case "subscriptions" :
                comp = subscribeHeader()
                // comp = vacancyHeader(wordsWithLang)
                break;
            case "aboutUs" :
                comp = vacancyHeader(wordsWithLang);
                break;

            case "services" :
                comp = vacancyHeader(wordsWithLang);
                break;
            case "company" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;
            case "category" :
                comp = <Search wordsWithLang={wordsWithLang}/>;
                break;
            case "blog" :
                comp = blogHeader(wordsWithLang);
                break;
            default :
                comp = null
        }
        return comp
    }
    useEffect(() => {
        // console.log(navigator.userAgent.toString())
    }, [])




    return (
        <div>
            <AppBar style={{display: navigator.userAgent.toString().includes("render") ? "none" : "block"}}
                    position={"relative"} className={"appBar"} color={"default"}>
                <Grid className={"header" + mobileClass} container direction={"row"} alignItems={"flex-start"}
                      style={{boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)"}}
                      justifyContent={"center"}>

                    <Grid item xs={1}>
                        <Grid container direction={"column"} alignItems={"flex-start"} justifyContent={"center"}>
                            <Box component={Grid} item xs={1} display={{
                                md: "none",
                                //menu is hidden
                                xs: "none"
                                //menu is hidden
                            }}>
                                <IconButton
                                    size="medium"
                                    onClick={() => {
                                        dispatcher({
                                            type: "IS_OPEN_DRAWER",
                                            payload: !mainConfig.isDrawerOpen
                                        })
                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={11} md={10}>
                        <Grid container direction={"row"} justifyContent={"center"} alignItems={"flex-start"}
                              style={{marginTop: 30}}>
                        </Grid>
                    </Grid>

                    <Grid item xs={10} sm={10} md={9} lg={8} style={{marginTop: 55}}>
                        {renderSearch()}
                    </Grid>

                </Grid>

            </AppBar>


        </div>
    );
};

export default MyAppBar
