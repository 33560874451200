import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Grid, IconButton, TextField, Typography} from "@mui/material";
import {Add, ArrowDownward, Delete, Edit, PeopleAlt} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const ReferenceAccordion = ({
                                wordsWithLang,
                                cvAppConfigCvData,
                                errorMessagesForRefData,
                                refInfoValidate,
                                updateCvDataInServer,
                                expanded,
                                setExpanded
                            }) => {
    const [reference, setReference] = useState({
        id: null,
        refName: "",
        contact: ""
    });
    const dispatcher = useDispatch();
    // const deleteRef = (key) => {
    //     cvAppConfigCvData.references.splice(key, 1)
    //     dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    // }

    const deleteRef = (key) => {
        const updatedReferences = cvAppConfigCvData.references.filter((_, index) => index !== key); // Create a new array without the item
        const updatedCvData = { ...cvAppConfigCvData, references: updatedReferences }; // Create a new object with updated references
        dispatcher({ type: "CV_DATA_UPDATE", payload: updatedCvData }); // Dispatch the updated object

        updateCvDataInServer("references",updatedCvData);
    };

    const editRef = (key) => {
        setReference(cvAppConfigCvData.references[key])
        deleteRef(key)
    }
    //
    // useEffect(() => {
    //     console.log(cvAppConfigCvData.references)
    //     updateCvDataInServer("references");
    // }, [cvAppConfigCvData.references]);

    return (
        <>

            {cvAppConfigCvData.references.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <PeopleAlt fontSize="small"
                                   style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noReferenceAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.references.map((value, key) => (
                    <Box display={"flex"} alignItems="center" marginY={2} key={key}>
                        <IconButton size={"small"} title={wordsWithLang.cvApp.cvAppForms.delete} className={"errorText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)",}} onClick={() => {
                            deleteRef(key)
                        }}><Delete className={"errorText"} fontSize={"small"}/></IconButton>
                        <IconButton  size={"small"} title={wordsWithLang.cvApp.cvAppForms.change} className={"successText"} style={{marginRight: 5, border:"1px solid rgba(0, 0, 0, 0.2)", }} onClick={() => {
                            editRef(key)
                        }}><Edit className={"successText"} fontSize={"small"}/></IconButton>
                        <Divider orientation="vertical" flexItem style={{marginRight:5, marginLeft:0}} />

                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <PeopleAlt fontSize="small"
                                       style={{marginRight: 5}}/>
                            <Typography>{value.refName} | <span style={{
                                fontSize: 12,
                                fontStyle: "italic"
                            }}>{value.contact}</span></Typography>



                        </Box>
                    </Box>
                )))}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <TextField id={"refName"}
                       value={reference.refName}
                       onChange={(e) => {
                           setReference({...reference, refName: e.target.value})
                       }} helperText={errorMessagesForRefData.refName}
                       error={errorMessagesForRefData.refName !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.referenceName} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField id={"refContact"} onChange={(e) => {
                setReference({...reference, contact: e.target.value})
            }} helperText={reference.contact.length + "/200 " + errorMessagesForRefData.contact}
                       value={reference.contact}
                       error={errorMessagesForRefData.contact !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.referenceContact} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>

            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={12} md={12} lg={4}>
                    <Button
                        id={"referenceCommit"}
                        onClick={() => {
                            if(refInfoValidate(true, reference)){
                                updateCvDataInServer("references");
                                setReference({ id: null,
                                    refName: "",
                                    contact: ""})
                            }
                            else {
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        "isSnackOpen": true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                                    },
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className={reference.refName === "" || reference.contact === "" ? "addButton disabledButton" : "addButton"}
                        size="small"
                        style={{marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
                    {/*        style={{ marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.langs = []*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}

                </Grid>
                {/*<Grid item xs={12} lg={4}>*/}
                    {/*<Grid container justifyContent={"flex-end"}>*/}
                    {/*    <Grid item xs={12} md={6} lg={12}>*/}
                    {/*        <Button*/}
                    {/*            variant="contained"*/}
                    {/*            startIcon={<ArrowDownward fontSize="medium"*/}
                    {/*                                      style={{color: 'white'}}/>}*/}
                    {/*            className="addButton continueButton animate__animated animate__slow animate__pulse animate__infinite"*/}
                    {/*            size="small"*/}
                    {/*            style={{width: '100%', marginTop: 15}}*/}
                    {/*            onClick={() => {*/}
                    {/*                updateCvDataInServer("references");*/}
                    {/*                setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            {wordsWithLang.cvApp.cvAppForms.continue}*/}
                    {/*        </Button>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                {/*</Grid>*/}
            </Grid>


        </>
    );
};

export default ReferenceAccordion;
