import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Announcement, Close, Facebook, Instagram, LinkedIn, Telegram} from "@mui/icons-material";
import {words} from "../words";
import {useNavigate} from "react-router-dom";

const RekPopUp = ({wordsWithLang}) => {
    const navigate = useNavigate();
    const mainConfig = useSelector(state => state.mainConfig)
    const dispatcher = useDispatch()
    const handleClose = () => {
        dispatcher({type: "SHOW_REK_POPUP", payload: false})
    };
    const theme = useTheme();
    const isBot = () => {
        if(window.navigator.userAgent.includes("render")||window.navigator.userAgent.includes("bot")||window.navigator.userAgent.includes("google")||window.navigator.userAgent.includes("house")){
            return true;
        }
        return false
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={mainConfig.showPopUp}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 15}
            }}
        >
            {/*<DialogTitle style={{*/}
            {/*    backgroundColor: theme.palette.primary.main,*/}
            {/*    color: "white"*/}
            {/*}}><Typography display={"block"}><Announcement fontSize={"medium"} style={{*/}
            {/*    verticalAlign: "bottom",*/}
            {/*    marginRight: 10*/}
            {/*}}/>{wordsWithLang.header.notification}</Typography></DialogTitle>*/}
            <DialogTitle sx={{padding:1.5}}>
                <Typography sx={{fontSize:"1.1rem"}} color={"violet"} fontWeight={500}><Announcement fontSize={"medium"} color={"violet"} style={{
                verticalAlign: "bottom",
                marginRight: 10,
                marginLeft:10
            }}/>{wordsWithLang.header.notification}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {/*<DialogContentText>*/}
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} sm={10}>
                        <Grid container justifyContent={"center"}>
                            <br/>
                            {mainConfig.isMobile && !isBot()  ? (
                                <Grid item xs={3} style={{
                                    marginTop: "15px",
                                    backgroundImage: "url('https://bakujobs.az/img/logo192.jpg')",
                                    backgroundSize: "cover",
                                    width: "110px",
                                    height: "110px"
                                }}/>
                            ) : (!isBot() ? (
                                <Grid item xs={4} style={{
                                    marginTop: "15px",
                                    backgroundImage: "url('https://bakujobs.az/img/logo192.jpg')",
                                    backgroundSize: "cover",
                                    width: "100px",
                                    height: "170px"
                                }}/>): (<Grid item xs={4} style={{
                                    display:"none",
                                }}/>)
                            )}
                            <Grid item xs={12}>
                                <Typography align={"center"}
                                            variant={"h5"} >{wordsWithLang.header.welcome.toUpperCase()}</Typography>
                                <br/>
                                <Typography align={"center"}
                                            variant={"body1"}>{wordsWithLang.header.forNewVacanciesNotification.toUpperCase()}</Typography>
                                <Typography align={"left"} fontWeight={700} marginTop={1}
                                            variant={"body2"}><span style={{fontSize:20, marginRight:5}}>✅</span>{wordsWithLang.header.forNewVacanciesNotification5.toUpperCase()}</Typography>
                                <Typography align={"left"}
                                                        variant={"body2"}><span style={{fontSize:20, marginRight:5}}>✅</span>{wordsWithLang.header.forNewVacanciesNotification1.toUpperCase()}</Typography>
                                   <Typography align={"left"}
                                                         variant={"body2"}><span style={{fontSize:20, marginRight:5}}>✅</span>{wordsWithLang.header.forNewVacanciesNotification2.toUpperCase()}</Typography>
                                            {/*<Typography align={"left"} style={{paddingLeft:"23px"}}*/}
                                            {/*            variant={"body2"}>{wordsWithLang.header.forNewVacanciesNotification3.toUpperCase()}</Typography>*/}
                                <Typography align={"left"}
                                                        variant={"body2"}><span style={{fontSize:20, marginRight:5}}>✅</span>{wordsWithLang.header.forNewVacanciesNotification4.toUpperCase()}</Typography>



                                {/*<br/>*/}

                                {/*<br/>*/}
                                {/*<Typography align={"center"}*/}
                                {/*            variant={"body1"}>{wordsWithLang.header.forNewVacanciesNotification.toUpperCase()}</Typography>*/}
                                {/*<br/>*/}
                                {/*<Typography align={"left"}*/}
                                {/*            variant={"body2"}>{wordsWithLang.header.forNewVacanciesNotification1.toUpperCase()}</Typography>*/}
                                {/*<br/>*/}
                                {/*<Typography align={"left"}*/}
                                {/*            variant={"body2"}>{wordsWithLang.header.forNewVacanciesNotification2.toUpperCase()}</Typography>*/}
                                {/*<Typography align={"left"} style={{paddingLeft:"23px"}}*/}
                                {/*            variant={"body2"}>{wordsWithLang.header.forNewVacanciesNotification3.toUpperCase()}</Typography>*/}
                                {/*<br/>*/}
                                {/*<Typography align={"left"}*/}
                                {/*            variant={"body2"}>{wordsWithLang.header.forNewVacanciesNotification4.toUpperCase()}</Typography>*/}
                                {/*<br/>*/}

                                <Button fullWidth variant={"contained"} color={"violet"}
                                        style={{color:"white",marginTop: 10, marginBottom: 10, borderRadius: 20}}
                                        onClick={() => {
                                            dispatcher({type: "SHOW_REK_POPUP", payload: false})
                                            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
                                            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
                                        }}>{wordsWithLang.header.register}</Button>
                            </Grid>
                            <Typography align={"center"}
                                        variant={"h6"}>{wordsWithLang.header.followUsInSocial.toUpperCase()}</Typography>
                            <br/>
                            <br/>

                            <Grid item xs={12}>
                                <Box width={"100%"} flexWrap={"wrap"} display="flex" overflow={"wrap"} justifyContent="space-evenly" alignItems="center" mt={2}>
                                <a style={{display:"flex", justifyContent:"center", maxWidth:"160px",  minHeight:"40px"}} href={"https://www.linkedin.com/company/baku-jobs/"} target={"_blank"}>
                                    {/*<IconButton*/}
                                    {/*    style={{*/}
                                    {/*        marginRight: 15,*/}
                                    {/*        backgroundColor: useTheme().palette.secondary.main,*/}
                                    {/*        color: "white"*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => navigate("/")}*/}
                                    {/*    variant={"contained"} size="small" display={"block"} align={"right"}>*/}
                                    {/*    <LinkedIn style={{fontSize: "35px", color: "white"}}*/}
                                    {/*              titleAccess={wordsWithLang.header.mainPage}/>*/}

                                    {/*</IconButton>*/}

                                    <Button
                                        startIcon={<LinkedIn style={{fontSize: "33px",}}
                                                             titleAccess={wordsWithLang.header.mainPage}/>}
                                        style={{
                                            marginRight: 15,
                                            // backgroundColor: useTheme().palette.secondary.main,
                                            // color: "white",
                                            borderRadius:100,
                                            minWidth:"140px",
                                        }}
                                        onClick={() => navigate("/")}
                                        variant={"outlined"} color={"violet"} size="small" display={"block"} align={"right"}>
                                        LinkedIn
                                    </Button>

                                </a>
                                <a style={{display:"flex", justifyContent:"center", maxWidth:"160px",  minHeight:"40px"}} href={"https://www.instagram.com/bakujobs.az/"} target={"_blank"}>
                                    {/*<IconButton*/}
                                    {/*    style={{*/}
                                    {/*        marginRight: 15,*/}
                                    {/*        backgroundColor: useTheme().palette.secondary.main,*/}
                                    {/*        color: "white"*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => navigate("/")}*/}
                                    {/*    variant={"contained"} size="small" display={"block"} align={"right"}>*/}

                                    {/*    <Instagram style={{fontSize: "35px", color: "white"}}*/}
                                    {/*               titleAccess={wordsWithLang.header.mainPage}/>*/}

                                    {/*</IconButton>*/}

                                    <Button
                                        startIcon={<Instagram style={{fontSize: "33px",}}
                                                             titleAccess={wordsWithLang.header.mainPage}/>}
                                        style={{
                                            marginRight: 15,
                                            // backgroundColor: useTheme().palette.secondary.main,
                                            // color: "white",
                                            borderRadius:100,
                                            minWidth:"140px",
                                        }}
                                        onClick={() => navigate("/")}
                                        variant={"outlined"} color={"violet"} size="small" display={"block"} align={"right"}>
                                        Instagram

                                    </Button>
                                </a>
                                <a style={{display:"flex", justifyContent:"center", maxWidth:"160px",  minHeight:"40px"}} href={"https://www.facebook.com/people/Baku-Jobs/100087601481945/"}
                                   target={"_blank"}>
                                    <Button
                                        startIcon={<Facebook style={{fontSize: "30px",}}
                                                             titleAccess={wordsWithLang.header.mainPage}/>}
                                        style={{
                                            marginRight: 15,
                                            // backgroundColor: useTheme().palette.secondary.main,
                                            // color: "white",
                                            borderRadius:100,
                                            minWidth:"140px",
                                        }}
                                        onClick={() => navigate("/")}
                                        variant={"outlined"} color={"violet"} size="small" display={"block"} align={"right"}>
                                        Facebook

                                    </Button>
                                </a>
                                <a style={{display:"flex", justifyContent:"center", maxWidth:"160px",  minHeight:"40px"}} href={"https://t.me/bakujobs_az"} target={"_blank"}>
                                    {/*<IconButton*/}
                                    {/*    style={{*/}
                                    {/*        // marginRight: 15,*/}
                                    {/*        backgroundColor: useTheme().palette.secondary.main,*/}
                                    {/*        color: "white"*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => navigate("/")}*/}
                                    {/*    variant={"contained"} size="small" display={"block"} align={"right"}>*/}
                                    {/*    <Telegram style={{fontSize: "35px", color: "white"}}*/}
                                    {/*              titleAccess={wordsWithLang.header.mainPage}/>*/}

                                    {/*</IconButton>*/}

                                    <Button
                                        startIcon={<Telegram style={{fontSize: "32px",}}
                                                             titleAccess={wordsWithLang.header.mainPage}/>}
                                        style={{
                                            marginRight: 15,
                                            // backgroundColor: useTheme().palette.secondary.main,
                                            // color: "white",
                                            borderRadius:100,
                                            minWidth:"140px",
                                        }}
                                        onClick={() => navigate("/")}
                                        variant={"outlined"} color={"violet"} size="small" display={"block"} align={"right"}>
                                        Telegram

                                    </Button>

                                </a>
                                </Box>
                            </Grid>
                            <br/>

                        </Grid>
                    </Grid>

                </Grid>
                {/*</DialogContentText>*/}

            </DialogContent>
            <DialogActions style={{boxShadow: "0px 0 2px rgba(0, 0, 0, 0.2)"}}>
                <Button style={{borderRadius: 30, marginTop: 10, marginBottom: 10, marginRight: 10}}
                        variant={"outlined"} color={"secondary"}
                        onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
                {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                {/*        variant={"outlined"} color="primary"*/}
                {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                {/*    fontSize="small"/></Button>*/}
            </DialogActions>
        </Dialog>
    );
};

export default RekPopUp;
