import React, {useCallback, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Button, Slider, Typography} from '@mui/material';
import getCroppedImg from './cropImage'; // You'll need to implement this function based on your need.

const CropperComponent = ({imageSrc, onCropComplete, wordsWithLang, handleClose}) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCrop = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
            onCropComplete(croppedImage);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div style={{position: 'relative', width: '100%', height: 400}}>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={3 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropCompleteHandler}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                />
            </div>
            <div>
                <div>
                    <Typography style={{paddingTop: 10}}>{wordsWithLang.general.zoom}</Typography>
                    <Slider style={{color: "#4C3198FF"}} size={"medium"} value={zoom} min={1} max={3} step={0.1}
                            onChange={(e, value) => setZoom(value)}/>
                </div>
                <div>
                    <Typography>{wordsWithLang.general.rotation}</Typography>
                    <Slider style={{color: "#4C3198FF"}} size={"medium"} value={rotation} min={0} max={360} step={1}
                            onChange={(e, value) => setRotation(value)}/>
                </div>
                <Button onClick={handleCrop} variant="contained" style={{
                    marginLeft: 45,
                    backgroundColor: "#4C3198FF",
                    paddingLeft: 30,
                    paddingRight: 30,
                    float: "right"
                }}>
                    {wordsWithLang.general.crop}
                </Button>
                <Button onClick={()=>{handleClose(null)}} variant="default" color={"error"}
                        style={{ float: "left"}}>
                    {wordsWithLang.loginDialog.closeButton}
                </Button>

            </div>
        </div>
    );
};

export default CropperComponent;
