import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { keyframes } from "@emotion/react";
import { Box } from "@mui/system";

const rotateGradient = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const GradientCircularProgress = () => {
    const gradientId = "animated_gradient";

    return (
        <Box position="relative" display="inline-flex">
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#e01cd5">
                            <animate
                                attributeName="offset"
                                values="0;1"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </stop>
                        <stop offset="100%" stopColor="#1CB5E0">
                            <animate
                                attributeName="offset"
                                values="1;0"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                size={20}
                thickness={5}
                sx={{
                    "svg circle": { stroke: `url(#${gradientId})` },
                    animation: `${rotateGradient} 2s linear infinite`,
                }}
            />
        </Box>
    );
}

export default GradientCircularProgress;
