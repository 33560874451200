import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputLabel, LinearProgress, MenuItem,
    Radio,
    RadioGroup, Select, Typography, useMediaQuery
} from "@mui/material";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {ArrowDownward, ArrowUpward, Close} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {useMatch} from "react-router-dom";
import SkeletonLoader from "./SkeletonLoader";

const CvAppPreviewDetailedViewDialog = ({wordsWithLang, newCvOrder, marginDialogOpen, setMarginDialogOpen, marginWait, setMarginWait}) => {

    const isMobile = useMediaQuery( "(max-width: 768px)");
    const dispatcher = useDispatch();
    const previewRef = useRef()
    const [selectedSection, setSelectedSection] = useState("");
    const [randomTime, setRandomTime] = useState("0000000");
    const [pageCount, setPageCount] = useState(newCvOrder.pagesCount)

    const boxRef = useRef(null);

    const handleScrollToArea = () => {
        if (boxRef.current) {
            boxRef.current.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional: Adds smooth scrolling
            });
        }
    };

    useEffect(() => {
        setRandomTime(new Date().getTime().toString().substring(5))
    }, []);

    const imageMarginChangeRequest = (side) => {
        if (selectedSection === "") {
            document.getElementById("marginSectionLabelId").style.color = "red"
            return
        }
        document.getElementById("marginSectionLabelId").style.color = "black"
        setMarginWait(true)
        setRandomTime(null)
        setPageCount(0)


        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppChangeMargin
            .replace("{CvOrderId}", newCvOrder.id).replace("{section}", selectedSection).replace("{marginSide}", side)
            , {withCredentials: true}).then((resp) => {
                setPageCount(resp.data)
            setMarginWait(false)
            setRandomTime(new Date().getTime().toString().substring(5))

            // axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id),
            //     {
            //         withCredentials: true,
            //         headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            //     }).then(
            //     resp => {
            //         // dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
            //         dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
            //     }
            // )
        })

    }


    return (
        <Dialog maxWidth={"lg"} fullScreen  open={marginDialogOpen} sx={{
            "& .MuiDialogContent-root": {
                padding: "20px", // Adjust the padding value as needed
            },
        }} onClose={() => {
            setMarginDialogOpen(false)
            setMarginWait(false)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
        }} fullWidth>
            {/*<DialogTitle>*/}
            {/*    <Typography variant={"body1"}*/}
            {/*                style={{marginBottom: 15}}*/}
            {/*    >{wordsWithLang.cvApp.cvAppForms.generalPreview}</Typography>*/}
            {/*</DialogTitle>*/}
            <DialogContent >
                <Grid container justifyContent={"center"}   display={"flex"} flexDirection={"column"} alignItems={"center"}>

                    <Button
                        variant={"outlined"}
                        // className={""}
                        color={"secondary"}
                        style={{margin: 5, borderRadius: "50px", position:"absolute", top: 23, right: 20, padding:20}}
                        onClick={() => {
                            setMarginDialogOpen(false);
                            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
                        }}><Close fontSize="medium"/>{}</Button>


                    <Grid item xs={11} md={11} lg={8} width={"70vw"} minWidth={"200px"} maxWidth={"600px"}>
                        <FormControl variant="outlined" fullWidth
                                     style={{width: "100%", marginTop: 15, marginBottom: 15}}>
                            <InputLabel size={isMobile?"small":"medium"}
                                id="marginSectionLabelId">{wordsWithLang.cvApp.cvAppForms.template.defaultSelect}</InputLabel>
                            <Select
                                size={isMobile?"small":"medium"}
                                labelId="marginSectionLabelId"
                                id="marginSectionId"
                                value={selectedSection}
                                onChange={(e) => {
                                    setSelectedSection(e.target.value)
                                    document.getElementById("marginSectionLabelId").style.color = "black"
                                    document.getElementById("marginSectionId").style.color = "black"
                                }}
                                sx={{borderRadius:50, width:"90%"}}
                                label={wordsWithLang.cvApp.cvAppForms.template.defaultSelect}>
                                <MenuItem
                                    value={"workExperiences-section"}>{"💼 "+ wordsWithLang.cvApp.cvAppForms.template.workExperiencesSection}</MenuItem>
                                <MenuItem
                                    value={"educations-section"}>{"📚 "+ wordsWithLang.cvApp.cvAppForms.template.educationsSection}</MenuItem>
                                <MenuItem
                                    value={"certificates-section"}>{"🏅 "+ wordsWithLang.cvApp.cvAppForms.template.certificatesSection}</MenuItem>
                                <MenuItem
                                    value={"competencies-section"}>{"💪 "+ wordsWithLang.cvApp.cvAppForms.template.competenciesSection}</MenuItem>
                                <MenuItem
                                    value={"notes-section"}>{"📝 "+ wordsWithLang.cvApp.cvAppForms.template.notesSection}</MenuItem>


                                <MenuItem
                                    value={"languages-section"}>{"🌏 "+ wordsWithLang.cvApp.cvAppForms.template.languagesSection}</MenuItem>
                                <MenuItem
                                    value={"programs-section"}>{"🛠 "+ wordsWithLang.cvApp.cvAppForms.template.programsSection}</MenuItem>
                                <MenuItem
                                    value={"references-section"}>{"📡 "+ wordsWithLang.cvApp.cvAppForms.template.referencesSection}</MenuItem>



                            </Select>

                        </FormControl>


                        <Button variant={"contained"} color="info"
                                style={{marginLeft: 0, marginRight:20, marginBottom: 20, minWidth: "20%", borderRadius: "50px", backgroundColor:"var(--darkPurple)"}}
                                onClick={() => {
                                    handleScrollToArea()
                                    imageMarginChangeRequest("bottom")
                                }}>{wordsWithLang.cvApp.cvAppForms.template.up} <ArrowUpward/></Button>
                        <Button variant={"contained"} color="info"
                                style={{ minWidth: "20%", borderRadius: "50px", marginBottom: 20, backgroundColor:"var(--darkPurple)"}}
                                onClick={() => {
                                    handleScrollToArea()
                                    imageMarginChangeRequest("top")
                                }}>{wordsWithLang.cvApp.cvAppForms.template.down} <ArrowDownward/></Button>

                    </Grid>
                    <Grid item xs={11} md={11} >
                        <Grid container justifyContent={"center"} ref={boxRef} style={{
                            overflow: 'auto',
                            height: "77vh",
                            flexDirection: 'row',
                            width: "100%",
                            padding: isMobile ? "2rem 2rem" : "2rem 5rem",
                            maxWidth:"920px",
                            paddingTop:"1rem",
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            backgroundColor: "#ececec",
                            // display: marginWait ? "none" : "flex"
                            display: "flex"
                        }}>
                            <Typography display={"flex"} variant={"caption"} color={"black"}
                                        style={{marginTop: 5, marginLeft: 5}}>{"* " + wordsWithLang.cvApp.cvAppForms.template.descUse}</Typography>



                            <SkeletonLoader visible={marginWait}/>

                            { randomTime && (Array.from({length: pageCount}).map((_, page) => (
                                    <Box component="img"
                                         draggable={"false"}
                                         onDragStart={(e) => e.preventDefault()}
                                         ref={previewRef}
                                        // src={newCvOrder.previewUrl}
                                         key={page}
                                         src={apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", newCvOrder.id).replace("{page}", page) + "?t=" + randomTime}
                                         sx={{
                                             opacity:marginWait?"0":"1",
                                             // transition: "opacity 0.2s ease-in-out",
                                             // margin: 14,
                                             marginTop:2,
                                             marginBottom:2,
                                             // width: 595 ,
                                             position: "relative",
                                             width: "100%",
                                             objectFit: "contain",
                                             objectPosition: "top",
                                             backgroundColor: "white",
                                             backgroundSize: "contain",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "top",
                                             boxShadow: "0 6px 10px rgba(0,0,0,.4), 0 0 6px rgba(0,0,0,.05)",
                                             // display: "flex",

                                             // backgroundImage: "url('"  +"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png"+ "')",


                                         }}

                                    />
                                )))}
                            {newCvOrder.previewUrl === null && (
                                <Typography m={5} color={"error"} fontSize={20} sx={{
                                    animation: "fadeIn 2s linear infinite",
                                    animationDirection: "alternate-reverse"
                                }}>
                                    {wordsWithLang.cvApp.cvAppForms.noPreview}
                                </Typography>
                            )}




                        </Grid>


                    </Grid>
                </Grid>


            </DialogContent>

            {/*<DialogActions style={{borderTop: "1px solid #000000", borderColor:"#d4d4d4"}}>*/}
            {/*    <Button*/}
            {/*        variant={"outlined"}*/}
            {/*        // className={""}*/}
            {/*        color={"secondary"}*/}
            {/*        style={{margin: 5, borderRadius: "50px"}}*/}
            {/*        onClick={() => {*/}
            {/*            setMarginDialogOpen(false);*/}
            {/*            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})*/}
            {/*        }}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>*/}
            {/*</DialogActions>*/}

        </Dialog>
    );

    const style = {

    }
};

export default CvAppPreviewDetailedViewDialog;
