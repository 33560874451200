import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Divider,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, useMediaQuery, useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    Dashboard,
    EditNote,
    FileCopy,
    FormatAlignCenter,
    Report,
    TextDecrease,
    TextIncrease,
    VerifiedUser
} from "@mui/icons-material";
import AdminReports from "./sections/AdminReports";
import AdminCvOrders from "./sections/AdminCVOrders";
import AdminVacancy from "./sections/AdminVacancy";
import AdminForm from "./sections/AdminForm";
import AdminUsers from "./sections/AdminUsers";
import axios from "axios";
import apiUrls from "../apiUrls";

const Adminograf = ({wordsWithLang}) => {
    const theme = useTheme();
    const dispatcher = useDispatch();
    const userConfig = useSelector(state => state.userConfig)
    const [selected, setSelected] = useState(null);
    const isDownSm = useMediaQuery(theme.breakpoints.down("md"));
    const [isAdmin, setIsAdmin] = useState(false);

    const [control, setControl] = useState({callGetAllCvOrders: false, callGetAllUsers:false})


    useEffect(() => {
        // console.log(userConfig.isLogin)
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
            axios.get(apiUrls.baseApiUrl + apiUrls.amIAdmin, {withCredentials: true}).then(resp => {
                if (resp.data === true) {
                    dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
                    setIsAdmin(true);
                }
            })
        }
    }, [userConfig.isLogin]);

    return isAdmin ? (
            <div>
                <Card style={{padding: 15, borderRadius:10}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span style={{fontSize: 25, fontWeight: "bold", marginRight: 20}}>Adminograf</span>
                            <span style={{fontStyle: "italic"}}>{"Welcome, " + userConfig.fullName}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{marginBottom: 20, marginTop:10}}/>
                            {/*<Divider style={{marginBottom:20}}/>*/}

                        </Grid>
                        <Grid item xs={isDownSm?2:2}>
                            {isDownSm && isDownSmList(selected,setSelected)}
                            {!isDownSm && normalList(selected,setSelected)}
                        </Grid>
                        <Grid item xs={10} sx={{backgroundColor:"#f6f0ff", borderRadius:4, height:"75vh", overflowY:"scroll"}}>
                            {selected === 0 && <AdminReports/>}
                            {selected === 1 && <AdminCvOrders wordsWithLang={wordsWithLang} control={control} setControl={setControl} />}
                            {selected === 2 && <AdminUsers control={control} setControl={setControl} />}
                            {selected === 3 && <AdminVacancy/>}
                            {selected === 4 && <AdminForm/>}
                        </Grid>
                    </Grid>

                </Card>
            </div>)
        : (
            <h1>Not Admin</h1>
        )
};






const isDownSmList = (selected, setSelected) => {
    return(
        <List style={{paddingRight: "2rem", paddingTop: 0}}>
            <ListItemButton sx={{borderRadius:2, justifyContent:"center"}} selected={selected === 0} onClick={() => setSelected(0)}>
                <ListItemIcon style={{justifyContent:"center"}}><Dashboard color={selected === 0 ? "violet" : "default"}/></ListItemIcon>
            </ListItemButton>

            <ListItemButton sx={{borderRadius:2, justifyContent:"center"}} selected={selected === 1} onClick={() => setSelected(1)}>
                <ListItemIcon style={{justifyContent:"center"}}><FileCopy
                    color={selected === 1 ? "violet" : "default"}/></ListItemIcon>
            </ListItemButton>

            <ListItemButton sx={{borderRadius:2, justifyContent:"center"}} selected={selected === 2} onClick={() => setSelected(2)}>
                <ListItemIcon style={{justifyContent:"center"}}><VerifiedUser
                    color={selected === 2 ? "violet" : "default"}/></ListItemIcon>
            </ListItemButton>

            <ListItemButton sx={{borderRadius:2, justifyContent:"center"}} selected={selected === 3} onClick={() => setSelected(3)}>
                <ListItemIcon style={{justifyContent:"center"}}><EditNote
                    color={selected === 3 ? "violet" : "default"}/></ListItemIcon>
            </ListItemButton>

            <ListItemButton sx={{borderRadius:2, justifyContent:"center"}} selected={selected === 4} onClick={() => setSelected(4)}>
                <ListItemIcon style={{justifyContent:"center"}}><FormatAlignCenter
                    color={selected === 4 ? "violet" : "default"}/></ListItemIcon>
            </ListItemButton>


        </List>
    )
}

const normalList = (selected, setSelected) => {
  return(
      <List sx={{paddingRight: "1rem", paddingTop: 0,}}>
          <ListItemButton sx={{borderRadius:2}} selected={selected === 0} onClick={() => setSelected(0)}>
              <ListItemIcon><Dashboard color={selected === 0 ? "violet" : "default"}/></ListItemIcon>
              <ListItemText primary="Overview"/>
          </ListItemButton>

          <ListItemButton sx={{borderRadius:2}} selected={selected === 1} onClick={() => setSelected(1)}>
              <ListItemIcon><FileCopy color={selected === 1 ? "violet" : "default"}/></ListItemIcon>
              <ListItemText primary="CV Orders"/>
          </ListItemButton>

          <ListItemButton sx={{borderRadius:2}} selected={selected === 2} onClick={() => setSelected(2)}>
              <ListItemIcon><VerifiedUser color={selected === 2 ? "violet" : "default"}/></ListItemIcon>
              <ListItemText primary="Users"/>
          </ListItemButton>

          <ListItemButton sx={{borderRadius:2}} selected={selected === 3} onClick={() => setSelected(3)}>
              <ListItemIcon><EditNote color={selected === 3 ? "violet" : "default"}/></ListItemIcon>
              <ListItemText primary="Vacancy"/>
          </ListItemButton>

          <ListItemButton sx={{borderRadius:2}} selected={selected === 4} onClick={() => setSelected(4)}>
              <ListItemIcon><FormatAlignCenter
                  color={selected === 4 ? "violet" : "default"}/></ListItemIcon>
              <ListItemText primary="Form"/>
          </ListItemButton>



      </List>
  )
}

export default Adminograf;
