import React, {useEffect, useRef, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert,
    Avatar,
    Button, Chip, CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, Tooltip,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Add, ArrowBack, AutoAwesome, Delete, ExpandMore, Info} from "@mui/icons-material";
import CustomDialog from "../../miniComponents/CustomDialog";
import 'react-phone-input-2/lib/style.css'
import apiUrls from "../../apiUrls";
import axios from "axios";
import {flagUrls} from "../../flagUrls";
import {competences} from "../../competences-az";
import {languages} from "../../languages";
import {useLocation, useNavigate} from "react-router-dom";
import CropperComponent from "../../utils/cropperComponent";
import PersonalAccordion from "../../components/cvApp/personalAccordion";
import EducationAccordion from "../../components/cvApp/educationAccordion";
import WorkExperienceAccordion from "../../components/cvApp/workExperienceAccordion";
import LanguageAccordion from "../../components/cvApp/languageAccordion";
import ProgramAccordion from "../../components/cvApp/programAccordion";
import CertificatesAccordion from "../../components/cvApp/certificatesAccordion";
import CompetenceAccordion from "../../components/cvApp/competenceAccordion";
import ReferenceAccordion from "../../components/cvApp/referenceAccordion";
import NoteAccordion from "../../components/cvApp/noteAccordion";
import * as staticVars from "../../utils/staticVars";
import {Box} from "@mui/system";
import AccordionIconButton from "../../miniComponents/AccordionIconButton";


const CvOrderEdit = ({wordsWithLang,adminCvOrder}) => {
        const dispatcher = useDispatch()
        const cvAppConfig = useSelector(state => state.cvAppConfig)
        const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
        const cvAppConfigCvData = useSelector(state => state.cvAppConfig.cvData)
        const userConfig = useSelector(state => state.userConfig)
    const [isReady, setIsReady] = useState(false);


        useEffect(() => {
            if (adminCvOrder!==undefined && adminCvOrder!==null) {
                dispatcher({type:"SET_USER", payload:adminCvOrder.customUser})
                dispatcher({type:"CV_DATA_UPDATE", payload:adminCvOrder.cvData})
                dispatcher({type:"CV_INSTANCE_UPDATE", payload:adminCvOrder})
            }
        }, []);

    useEffect(() => {
        if (adminCvOrder!==undefined && adminCvOrder!==null) {
            let cond1 = userConfig.email === adminCvOrder.customUser.email ? true : false
            let cond2 = cvAppConfigCvData.id === adminCvOrder.cvData.id ? true : false
            let cond3 = newCvOrder.id === adminCvOrder.id ? true : false

            if (cond1 && cond2 && cond3
            ) {
                console.log("REDUX UPDATED, READY TO RENDER");
                setIsReady(true);
            }
        }
        else{
            setIsReady(true);
        }
    }, [userConfig.email, cvAppConfigCvData.id, newCvOrder.id, adminCvOrder]);


        // ({wordsWithLang}) => {
        const navigate = useNavigate()
        const location = useLocation();

        // useEffect(()=>{polyfillCountryFlagEmojis()},[])
        const [competencesList, setCompetencesList] = useState(competences);
        const [openCropper, setOpenCropper] = useState(false);

        const sortLanguagesByName = (languages) => {
            // Convert the object to an array of [key, value] pairs
            const entries = Object.entries(languages);

            // Sort the entries array by the language name
            entries.sort((a, b) => a[1].language.localeCompare(b[1].language));

            // Convert the sorted array back to an object
            return Object.fromEntries(entries);
        }
    const [languageList, setLanguageList] = useState(sortLanguagesByName(languages[newCvOrder.language]));


        // const [oldCvFile, setOldCvFile] = useState(null);
        const fileInputRef = useRef(null);
        const handleFileChangeOldCv = async (event) => {
            const file = event.target.files[0];
            if (!file) return;

            const formData = new FormData();
            formData.append("file", file);

            try {
                axios.post(
                    apiUrls.baseApiUrl + apiUrls.cvAppPostCvToWords,
                    formData,
                    {headers: {"Content-Type": "multipart/form-data"}}
                ).then((resp) => {
                    console.log(resp.data)
                });

                alert("File uploaded successfully!");
            } catch (error) {
                alert(error.response?.data?.message || "Failed to upload file.");
            }

        };

        const triggerFileSelect = () => {
            fileInputRef.current.click();
        };


        // const [wordsWithLang, setWordsWithLang] = useState(words);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


        const isMobile = window.screen.width <= 700
        const [avatar, setAvatar] = useState(cvAppConfigCvData.avatarUrl)
        const [expanded, setExpanded] = useState("panel1")

        const avatarDelete = useRef();
        const avatarAdd = useRef();
        const fileUpload = useRef(null);

        const [selectedFile, setSelectedFile] = useState(null);
        const [croppedImage, setCroppedImage] = useState(null);

        // titles refs
        const [educationalInfoTitleColor, setEducationalInfoTitleColor] = useState("#000000");
        const [workExperienceTitleColor, setWorkExperienceTitleColor] = useState("#000000");
        const [langTitleColor, setLangTitleColor] = useState("#000000");
        const [programTitleColor, setProgramTitleColor] = useState("#000000");
        const [certificateTitleColor, setCertificateTitleColor] = useState("#000000");
        const [referenceTitleColor, setReferenceTitleColor] = useState("#000000");
        const [competencyTitleColor, setCompetencyTitleColor] = useState("#000000");
        const [additionalNoteTitleColor, setAdditionalNoteTitleColor] = useState("#000000");
        const [personalInfoTitleColor, setPersonalInfoTitleColor] = useState("#000000");

        const [startCheckingPersonal, setStartCheckingPersonal] = useState(false);
        const [startCheckingEdu, setStartCheckingEdu] = useState(false);
        const [startCheckingWork, setStartCheckingWork] = useState(false);
        const [startCheckingCert, setStartCheckingCert] = useState(false);
        const [startCheckingRef, setStartCheckingRef] = useState(false);

        const [activeAccordions, setActiveAccordions] = useState(["personal"]);


        // error messages
        const [errorMessagesForEduData, setErrorMessagesForEduData] = useState({
            id: "",
            eduType: "",
            eduName: "",
            eduSpecialty: "",
            eduFaculty: "",
            eduStartDate: "",
            eduEndDate: "",
            eduIsContinue: ""
        })
        const [errorMessagesForWorkData, setErrorMessagesForWorkData] = useState({
            id: "",
            workWorkplace: "",
            workBranch: "",
            workPosition: "",
            workAbout: "",
            workStartDate: "",
            workEndDate: "",
            workIsContinue: ""
        })
        const [errorMessagesForCertData, setErrorMessagesForCertData] = useState({
            id: "",
            name: "",
            about: "",
            issuance: ""
        })
        const [errorMessagesForRefData, setErrorMessagesForRefData] = useState({
            id: "",
            refName: "",
            contact: ""
        })
        const [errorMessagesForPersonalData, setErrorMessagesForPersonalData] = useState({
            name: "",
            jobTitle: "",
            surname: "",
            email: "",
            birthDate: "",
            phone: "",
            address: "",
            about: "",
        })


        //avatar functions
        const [deleteAvatarDialogStatus, setDeleteAvatarDialogStatus] = useState(false)
        const onDeleteAvatar = () => {
            handleFileChange(null)
            setDeleteAvatarDialogStatus(false)
            setAvatar(null)
            let tempData = cvAppConfigCvData;
            tempData.avatarUrl = null
            newCvOrder.cvData = tempData
            dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
            axios.delete(apiUrls.baseApiUrl + apiUrls.cvAppDeleteAvatarImage.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true}).then(() => {
            })
        }
        const handleCropComplete = (croppedImage) => {
            setCroppedImage(croppedImage);
            setOpenCropper(false)
            croppedImage = croppedImage.replace("data:image/jpeg;base64,", "");
            croppedImage = croppedImage.replace("data:image/png;base64,", "");
            croppedImage = croppedImage.replace("data:image/webp;base64,", "");
            let imageObj = {}
            imageObj.base64 = croppedImage
            imageObj.fileType = fileUpload.current.files[0].name.split('.').at(-1)

            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostAvatarImage.replace("{CvOrderId}", newCvOrder.id), imageObj, {
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                }

            })
                .then(resp => {
                    setAvatar(resp.data + "?t=" + Date.now())
                    let tempData = cvAppConfigCvData;
                    tempData.avatarUrl = resp.data + "?t=" + Date.now()
                    newCvOrder.cvData = tempData
                    dispatcher({type: "CV_DATA_UPDATE", payload: tempData})
                })
        };
        const handleFileChange = (event) => {
            let fileTemp;
            let imageUrl;
            if (event === null) {
                fileUpload.current.value = null
                imageUrl = null;
                setOpenCropper(false)
                setSelectedFile(null);
                setCroppedImage(null)
                fileTemp = null
            } else {
                fileTemp = event.target.files[0]
                setSelectedFile(fileTemp)
                if (fileTemp) {
                    imageUrl = URL.createObjectURL(fileTemp);
                    setSelectedFile(imageUrl);
                    setOpenCropper(true)
                }
            }
        };

// validators
        const personalInfoValidate = (fromClick, personalData) => {
            if (fromClick || startCheckingPersonal) {
                setStartCheckingPersonal(true)
            } else {
                return false;
            }

            let errorMessages = errorMessagesForPersonalData;

            if (!personalData.jobTitle || personalData.jobTitle.length < 2) {
                errorMessages = {
                    ...errorMessages,
                    jobTitle: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.jobTitle
                }
            } else {
                errorMessages = {...errorMessages, jobTitle: ""}
            }


            if (!personalData.name || personalData.name.length < 3) {
                errorMessages = {
                    ...errorMessages,
                    name: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessages = {...errorMessages, name: ""}
            }

            if (!personalData.surname || personalData.surname.length < 3) {
                errorMessages = {
                    ...errorMessages,
                    surname: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.surname
                }
            } else {
                errorMessages = {...errorMessages, surname: ""}

            }

            if (!personalData.birthDate || personalData.birthDate.length < 3) {
                errorMessages = {
                    ...errorMessages,
                    birthDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.birthDate
                }

            } else {
                errorMessages = {...errorMessages, birthDate: ""}
            }

            if (!emailRegex.test(personalData.email)) {
                errorMessages = {
                    ...errorMessages,
                    email: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.email
                }
            } else {
                errorMessages = {...errorMessages, email: ""}
            }

            if (!personalData.phone || personalData.phone.length < 5) {
                errorMessages = {
                    ...errorMessages,
                    phone: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.phone
                }
            } else {
                errorMessages = {...errorMessages, phone: ""}
            }

            // if (!personalData.address) {
            //     // errorMessages = {
            //     //     ...errorMessages,
            //     //     phone: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.phone
            //     // }
            // } else {
            //     errorMessages = {...errorMessages, address: ""}
            // }

            if (!personalData.about || (personalData.about && personalData.about.length > 250)) {
                errorMessages = {
                    ...errorMessages,
                    about: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.about
                }
            } else {
                errorMessages = {...errorMessages, about: ""}
            }


            setErrorMessagesForPersonalData(errorMessages)

            if (Object.values(errorMessages).every(value => value === "")) {
                setPersonalInfoTitleColor("#000000")

            } else {
                setPersonalInfoTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessages).every(value => value === "") && fromClick) {
                setPersonalInfoTitleColor("#000000")

                let temp = cvAppConfigCvData

                temp.personal.jobTitle = personalData.jobTitle
                temp.personal.name = personalData.name
                temp.personal.surname = personalData.surname
                temp.personal.birthDate = personalData.birthDate
                temp.personal.email = personalData.email
                temp.personal.phone = personalData.phone
                temp.personal.address = personalData.address
                temp.personal.about = personalData.about
                temp.personal.links = personalData.links


                dispatcher({type: "CV_DATA_UPDATE", payload: temp})

                setStartCheckingPersonal(false)
                return true
            }

        }
        const eduInfoValidate = (fromClick, education) => {
            if (fromClick || startCheckingEdu) {
                setStartCheckingEdu(true)
            } else {
                return false;
            }
            let errorMessagesForEduDataTemp = errorMessagesForEduData;
            if (education.eduType.length < 3) {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduType: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduType: ""}
            }
            if (education.name.length < 3) {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduName: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduName: ""}
            }

            if (education.specialty.length < 3 && education.eduType !== "secondary") {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduSpecialty: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduSpecialty: ""}

            }
            if (education.faculty.length < 3 && education.eduType !== "secondary") {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduFaculty: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduFaculty: ""}
            }

            if (education.startDate.length < 3) {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduStartDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduStartDate: ""}
            }

            if (!education.isContinue && education.endDate.length < 3) {
                errorMessagesForEduDataTemp = {
                    ...errorMessagesForEduDataTemp,
                    eduEndDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForEduDataTemp = {...errorMessagesForEduDataTemp, eduEndDate: ""}
            }

            setErrorMessagesForEduData(errorMessagesForEduDataTemp)

            if (Object.values(errorMessagesForEduDataTemp).every(value => value === "")) {
                setEducationalInfoTitleColor("#000000")

            } else {
                setEducationalInfoTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessagesForEduDataTemp).every(value => value === "")) {
                setEducationalInfoTitleColor("#000000")

            } else {
                setEducationalInfoTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessagesForEduDataTemp).every(value => value === "") && fromClick) {
                cvAppConfigCvData.educations = [...cvAppConfigCvData.educations, education]
                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                setStartCheckingEdu(false)
                return true
            }
        }
        const workInfoValidate = (fromClick, workExperience) => {
            if (fromClick || startCheckingWork) {
                setStartCheckingWork(true)
            } else {
                return false;
            }
            let errorMessagesForWorkDataTemp = errorMessagesForWorkData;
            if (workExperience.workplace.length < 3) {
                errorMessagesForWorkDataTemp = {
                    ...errorMessagesForWorkDataTemp,
                    workWorkplace: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workWorkplace: ""}
            }
            if (workExperience.branch.length < 3) {
                errorMessagesForWorkDataTemp = {
                    ...errorMessagesForWorkDataTemp,
                    workBranch: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workBranch: ""}
            }

            if (workExperience.position.length < 3) {
                errorMessagesForWorkDataTemp = {
                    ...errorMessagesForWorkDataTemp,
                    workPosition: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workPosition: ""}

            }

            if (workExperience.startDate.length < 3) {
                errorMessagesForWorkDataTemp = {
                    ...errorMessagesForWorkDataTemp,
                    workStartDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workStartDate: ""}
            }

            if (!workExperience.isContinue && workExperience.endDate.length < 3) {
                errorMessagesForWorkDataTemp = {
                    ...errorMessagesForWorkDataTemp,
                    workEndDate: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForWorkDataTemp = {...errorMessagesForWorkDataTemp, workEndDate: ""}
            }

            setErrorMessagesForWorkData(errorMessagesForWorkDataTemp)

            if (Object.values(errorMessagesForWorkDataTemp).every(value => value === "")) {
                setWorkExperienceTitleColor("#000000")

            } else {
                setWorkExperienceTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessagesForWorkDataTemp).every(value => value === "") && fromClick) {
                cvAppConfigCvData.workExperiences = [...cvAppConfigCvData.workExperiences, workExperience]
                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})

                if (Object.values(errorMessagesForWorkDataTemp).every(value => value === "")) {
                    setWorkExperienceTitleColor("#000000")

                } else {
                    setWorkExperienceTitleColor("#e50914")
                    return false;
                }
                setStartCheckingWork(false)
                return true
            }
        }
        const certInfoValidate = (fromClick, certificate) => {
            if (fromClick || startCheckingCert) {
                setStartCheckingCert(true)
            } else {
                return false;
            }
            let errorMessagesForCertDataTemp = errorMessagesForCertData;
            if (certificate.name.length < 3) {
                errorMessagesForCertDataTemp = {
                    ...errorMessagesForCertDataTemp,
                    name: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, name: ""}
            }
            if (certificate.about.length > 1 && certificate.about.length < 3) {
                errorMessagesForCertDataTemp = {
                    ...errorMessagesForCertDataTemp,
                    about: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, about: ""}
            }

            if (certificate.issuance === "" || certificate.issuance === null) {
                errorMessagesForCertDataTemp = {
                    ...errorMessagesForCertDataTemp,
                    issuance: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }

            } else {
                errorMessagesForCertDataTemp = {...errorMessagesForCertDataTemp, issuance: ""}

            }

            setErrorMessagesForCertData(errorMessagesForCertDataTemp)

            if (Object.values(errorMessagesForCertDataTemp).every(value => value === "")) {
                setCertificateTitleColor("#000000")

            } else {
                setCertificateTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessagesForCertDataTemp).every(value => value === "") && fromClick) {
                cvAppConfigCvData.certificates = [...cvAppConfigCvData.certificates, certificate]
                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                setStartCheckingCert(false)
                return true
            }
        }
        const refInfoValidate = (fromClick, reference) => {
            if (fromClick || startCheckingRef) {
                setStartCheckingRef(true)
            } else {
                return false;
            }
            let errorMessagesTemp = errorMessagesForRefData;
            if (reference.refName.length < 3) {
                errorMessagesTemp = {
                    ...errorMessagesTemp,
                    refName: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesTemp = {...errorMessagesTemp, refName: ""}
            }
            if (reference.contact.length < 3) {
                errorMessagesTemp = {
                    ...errorMessagesTemp,
                    contact: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.name
                }
            } else {
                errorMessagesTemp = {...errorMessagesTemp, contact: ""}
            }

            setErrorMessagesForRefData(errorMessagesTemp)

            if (Object.values(errorMessagesTemp).every(value => value === "")) {
                setReferenceTitleColor("#000000")

            } else {
                setReferenceTitleColor("#e50914")
                return false;
            }

            if (Object.values(errorMessagesTemp).every(value => value === "") && fromClick) {
                cvAppConfigCvData.references = [...cvAppConfigCvData.references, reference]
                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                setStartCheckingRef(false)
                return true
            }
        }

// reference functions
        const checkAllErrors = () => {
            if (!personalInfoValidate(true, cvAppConfigCvData.personal)) {
                dispatcher({
                    type: "OPEN_SNACK",
                    payload: {
                        "isSnackOpen": true,
                        snackType: "error",
                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                    }
                })
                return false

            }
            return true
        }


        const updateCvDataInServer = (comeForUpdate, data) => {
            if(comeForUpdate==="personal"){setActiveAccordions([...activeAccordions, "educations"]);}
            if(comeForUpdate==="educations"){setActiveAccordions([...activeAccordions, "workExperiences"]);}
            if(comeForUpdate==="workExperiences"){setActiveAccordions([...activeAccordions, "langs"]);}
            if(comeForUpdate==="langs"){setActiveAccordions([...activeAccordions, "programs"]);}
            if(comeForUpdate==="programs"){setActiveAccordions([...activeAccordions, "certificates"]);}
            if(comeForUpdate==="certificates"){setActiveAccordions([...activeAccordions, "competencies"]);}
            if(comeForUpdate==="competencies"){setActiveAccordions([...activeAccordions, "references"]);}
            if(comeForUpdate==="references"){setActiveAccordions([...activeAccordions, "note"]);}


            if (data === undefined || data === null) {
                axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), cvAppConfigCvData,
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data})
                })
            } else {
                axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostCvData.replace("{CvOrderId}", newCvOrder.id).replace("{comeForUpdate}", comeForUpdate), data,
                    {
                        withCredentials: true,
                        headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                    }).then(resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data})
                })
            }

        }


        window.onpopstate = function () {
            dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "main"})

        }

        const onchangeAccordion = (panel,event) => {
            // console.log(element)
            const targetY = event.currentTarget.getBoundingClientRect().top + window.scrollY + 150; // Scroll a little bit up

            setTimeout(() => {
                window.scrollTo({
                    top: targetY,
                    behavior: 'smooth',
                });
            }, 300); // Sync with MUI animation


            if(expanded==="panel2"){
                updateCvDataInServer("educations");
            }
            if(expanded==="panel3"){
                updateCvDataInServer("workExperiences");
            }
            if(expanded==="panel4"){
                updateCvDataInServer("langs");
            }
            if(expanded==="panel5"){
                updateCvDataInServer("programs");
            }
            if(expanded==="panel6"){
                updateCvDataInServer("certificates");
            }
            if(expanded==="panel7"){
                updateCvDataInServer("competencies");
            }
            if(expanded==="panel8"){
                updateCvDataInServer("references");
            }

            setExpanded(panel)

            // -------------------------------------------------------
            // if(panel === "panel1"){
            //     // activeAccordions.includes("personal")?
            //         setExpanded("panel1")
            //         // :console.log()
            // }
            // if(panel === "panel2"){
            // // activeAccordions.includes("educations")?
            //     setExpanded("panel2")
            //     // :console.log()
            // }
            // if(panel === "panel3"){
            // // activeAccordions.includes("workExperiences")?
            //     setExpanded("panel3")
            //     // :console.log()
            // }
            // if(panel ===  "panel4"){
            // // activeAccordions.includes("langs")?
            //     setExpanded("panel4")
            //     // :console.log()
            // }
            // if(panel ===  "panel5"){
            // // activeAccordions.includes("programs")?
            //     setExpanded("panel5")
            //     // :console.log()
            // }
            // if(panel ===  "panel6"){
            // // activeAccordions.includes("certificates")?
            //     setExpanded("panel6")
            //     // :console.log()
            // }
            // if(panel ===  "panel7"){
            // // activeAccordions.includes("competencies")?
            //     setExpanded("panel7")
            //     // :console.log()
            // }
            // if(panel ===  "panel8"){
            // // activeAccordions.includes("references")?
            //     setExpanded("panel8")
            //     // :console.log()
            // }
            // if(panel ===  "panel9"){
            // // activeAccordions.includes("note")?
            //     setExpanded("panel9")
            //     // :console.log()
            // }
        }



        if(!isReady){
            return(
                <div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress/>
                </div>
            )
        }

        return (
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={11} sm={11} container justifyContent="space-between">
                    <Button
                        color="violet"
                        variant="contained"
                        size="large"
                        style={{marginLeft: 23, borderRadius: 30, color: "white"}}
                        onClick={() => {
                            navigate({pathname: '/cv', hash: ""})
                            dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "main"})
                        }
                        }
                    >
                        <ArrowBack fontSize="small"/>
                        <Typography style={{marginLeft: 3, fontSize: isMobile ? "13px" : "15px"}} variant="button">
                            {wordsWithLang.vacancyBody.back}
                        </Typography>
                    </Button>

                    <input
                        type="file"
                        accept=".doc,.docx,.pdf"
                        ref={fileInputRef}
                        style={{display: "none"}}
                        onChange={handleFileChangeOldCv}
                    />

                    {/*<Button*/}
                    {/*    color="violet"*/}
                    {/*    variant="outlined"*/}
                    {/*    size="large"*/}
                    {/*    style={{*/}
                    {/*        marginLeft: 23,*/}
                    {/*        borderRadius: 30,*/}
                    {/*        color: "#4c3198",*/}
                    {/*        display: localStorage.getItem("uploadCv") === "show" ? "flex" : "none"*/}
                    {/*    }}*/}
                    {/*    //     variant={"outlined"}*/}
                    {/*    //         color={"violet"}  className={"text-shine addButton"}*/}
                    {/*    //         sx={{*/}
                    {/*    //     backgroundImage: "linear-gradient(to right, #4c3198 0, #824fff 30%, #4c3198 60%)",*/}
                    {/*    //     borderColor: "#4c3198",*/}
                    {/*    //     color:"#4c3198",*/}
                    {/*    //     borderRadius: "20px",*/}
                    {/*    //     transition: "all 0.5s, display 0.5s allow-discrete;"*/}
                    {/*    // }}*/}
                    {/*    onClick={triggerFileSelect}*/}
                    {/*    startIcon={<AutoAwesome color={"secondary"}*/}
                    {/*                            className={"animate__pulse animate__animated  animate__infinite"}/>}>*/}
                    {/*    {"UPLOAD OLD CV"} </Button>*/}
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={11}>
                            <Button size="small" disabled
                                                                    fullWidth={isMobile}
                                                                    color={"violet"}
                                                                    style={{borderRadius: 30, marginRight: 5, justifyContent: "flex-start", marginLeft: 15, marginTop: 10, color: "#333333", borderColor: staticVars.colors.darkPurple
                                                                    }}>
                                <img src={"https://bakujobs.az/img/"+newCvOrder.language+".png"} alt="Description"
                                     style={{width: '25px', height: '25px', borderRadius: '10px'}} draggable={"false"}/>
                                <Typography variant="button"
                                            style={{
                                                paddingLeft: 10,
                                                fontSize: isMobile ? 10 : 10
                                            }}
                                            align={"left"}>
                                    {newCvOrder.language==="az"?"Azərbaycan":newCvOrder.language==="ru"?"Pусский":"English"}
                                </Typography>
                            </Button>
                        </Grid>


                        <Grid item justifyContent="center" display={"flex"} xs={12} style={{margin: "30px"}}>
                            {/*avatar functions*/}


                            <IconButton style={{height: 200, width: 200}}>
                                {avatar === null ?
                                    (<><Avatar style={{height: 180, width: 180}}
                                               onMouseEnter={() => (avatarAdd.current.style.opacity = 0.7)}
                                               onMouseLeave={() => (avatarAdd.current.style.opacity = 0)}
                                               onTouchStart={() => (avatarAdd.current.style.opacity = 0.7)}
                                               onTouchEnd={() => (avatarAdd.current.style.opacity = 0)}/>
                                            <Add ref={avatarAdd} color="primary"
                                                 style={{position: "absolute", height: 120, width: 120, opacity: 0}}
                                                 onMouseEnter={() => (avatarAdd.current.style.opacity = 0.7)}
                                                 onMouseLeave={() => (avatarAdd.current.style.opacity = 0)}
                                                 onTouchStart={() => (avatarAdd.current.style.opacity = 0.7)}
                                                 onTouchEnd={() => (avatarAdd.current.style.opacity = 0)}
                                                 onClick={() => {
                                                     fileUpload.current.click();
                                                 }}
                                            >
                                            </Add>
                                        </>
                                    ) :
                                    (<>
                                        <div style={{
                                            padding: 0,
                                            margin: 0,
                                            borderRadius: 100,
                                            height: 180,
                                            width: 180,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundImage: "url('" + apiUrls.baseApiUrl + apiUrls.cvAppGetAvatar.replace("{fileName}", avatar) + "')"
                                        }}
                                             onMouseEnter={() => (avatarDelete.current.style.opacity = 0.7)}
                                             onMouseLeave={() => (avatarDelete.current.style.opacity = 0)}
                                             onTouchStart={() => (avatarDelete.current.style.opacity = 0.7)}
                                             onTouchEnd={() => (avatarDelete.current.style.opacity = 0)}/>
                                        <Delete ref={avatarDelete} color={"secondary"}
                                                style={{position: "absolute", height: 120, width: 120, opacity: 0}}
                                                onMouseEnter={() => (avatarDelete.current.style.opacity = 0.7)}
                                                onMouseLeave={() => (avatarDelete.current.style.opacity = 0)}
                                                onTouchStart={() => (avatarDelete.current.style.opacity = 0.7)}
                                                onTouchEnd={() => (avatarDelete.current.style.opacity = 0)}
                                                onClick={() => (setDeleteAvatarDialogStatus(true))}/></>)}
                            </IconButton>

                            <input style={{opacity: 0, visibility: "hidden", display: "none"}} ref={fileUpload}
                                   onChange={handleFileChange}
                                   type="file" id="avatar" name="avatar" accept="image/png, image/jpeg"/>

                        </Grid>
                        <Grid item justifyContent="center" xs={12} style={{margin: "10px"}} display={"block"}>
                            <Typography align={"center"}>{wordsWithLang.cvApp.addYourPhoto}</Typography>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} style={{margin: "30px"}}>
                        {/*<Grid container justifyContent="center">*/}

                        {/*personal info accordion*/}
                        <Accordion expanded={ expanded === "panel1"} onChange={(e) => {
                            onchangeAccordion("panel1",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={
                                <AccordionIconButton/>
                            }>
                                <Typography
                                    color={personalInfoTitleColor}>{wordsWithLang.cvApp.cvAppForms.personalInfo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {cvAppConfigCvData.personal && (
                                    <PersonalAccordion isMobile={isMobile}
                                        wordsWithLang={wordsWithLang} expanded={expanded}
                                                       setExpanded={setExpanded} cvAppConfigCvData={cvAppConfigCvData}
                                                       newCvOrder={newCvOrder}
                                                       personalInfoValidate={personalInfoValidate}
                                                       updateCvDataInServer={updateCvDataInServer}
                                                       startCheckingPersonal={startCheckingPersonal}
                                                       errorMessagesForPersonalData={errorMessagesForPersonalData}/>)}
                            </AccordionDetails>
                        </Accordion>


                        {/*educational info accordion*/}
                        <Accordion expanded={ expanded === "panel2"} onChange={(e) => {
                            onchangeAccordion("panel2",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={educationalInfoTitleColor}>{wordsWithLang.cvApp.cvAppForms.educationalInfo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                {cvAppConfigCvData.educations && (
                                    <EducationAccordion wordsWithLang={wordsWithLang}
                                                        expanded={expanded}
                                                        setExpanded={setExpanded}
                                                        cvAppConfigCvData={cvAppConfigCvData}
                                                        errorMessagesForEduData={errorMessagesForEduData}
                                                        eduInfoValidate={eduInfoValidate}
                                                        updateCvDataInServer={updateCvDataInServer}
                                                        startCheckingEdu={startCheckingEdu}
                                                        language={newCvOrder.language}/>)}
                            </AccordionDetails>


                        </Accordion>

                        {/*workExperience accordion*/}
                        <Accordion expanded={ expanded === "panel3"} onChange={(e) => {
                            onchangeAccordion("panel3",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={workExperienceTitleColor}>{wordsWithLang.cvApp.cvAppForms.workExperience}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <WorkExperienceAccordion
                                    cvAppConfigCvData={cvAppConfigCvData}
                                    wordsWithLang={wordsWithLang}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    updateCvDataInServer={updateCvDataInServer} workInfoValidate={workInfoValidate}
                                    errorMessagesForWorkData={errorMessagesForWorkData}
                                    startCheckingWork={startCheckingWork}

                                />

                            </AccordionDetails>
                        </Accordion>

                        {/*langKnowledge accordion*/}
                        <Accordion expanded={ expanded === "panel4"} onChange={(e) => {
                            onchangeAccordion("panel4",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={langTitleColor}>{wordsWithLang.cvApp.cvAppForms.langKnowledge}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {cvAppConfigCvData && flagUrls &&
                                    <LanguageAccordion cvAppConfigCvData={cvAppConfigCvData}
                                                       wordsWithLang={wordsWithLang}
                                                       expanded={expanded}
                                                       setExpanded={setExpanded}
                                                       updateCvDataInServer={updateCvDataInServer}
                                                       languageList={languageList}
                                                       flagUrls={flagUrls}/>}

                            </AccordionDetails>
                        </Accordion>

                        {/*program accordion*/}

                        <Accordion expanded={ expanded === "panel5"} onChange={(e) => {
                            onchangeAccordion("panel5",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={programTitleColor}>{wordsWithLang.cvApp.cvAppForms.programKnowledge}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <ProgramAccordion wordsWithLang={wordsWithLang} expanded={expanded}
                                                  setExpanded={setExpanded} cvAppConfigCvData={cvAppConfigCvData}
                                                  newCvOrder={newCvOrder} updateCvDataInServer={updateCvDataInServer}/>
                            </AccordionDetails>
                        </Accordion>

                        {/*certificate accordion*/}

                        <Accordion expanded={ expanded === "panel6"} onChange={(e) => {
                            onchangeAccordion("panel6",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={certificateTitleColor}>{wordsWithLang.cvApp.cvAppForms.certificateTitle}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CertificatesAccordion updateCvDataInServer={updateCvDataInServer}
                                                       cvAppConfigCvData={cvAppConfigCvData}
                                                       wordsWithLang={wordsWithLang}
                                                       errorMessagesForCertData={errorMessagesForCertData}
                                                       expanded={expanded} startCheckingCert={startCheckingCert}
                                                       setExpanded={setExpanded} certInfoValidate={certInfoValidate}/>
                            </AccordionDetails>
                        </Accordion>


                        {/*Competence accordion*/}

                        <Accordion expanded={ expanded === "panel7"} onChange={(e) => {
                            onchangeAccordion("panel7",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={competencyTitleColor}>{wordsWithLang.cvApp.cvAppForms.competenceTitle}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CompetenceAccordion updateCvDataInServer={updateCvDataInServer}
                                                     cvAppConfigCvData={cvAppConfigCvData}
                                                     wordsWithLang={wordsWithLang}
                                                     competencesList={competencesList}
                                                     expanded={expanded}
                                                     setExpanded={setExpanded}/>
                            </AccordionDetails>
                        </Accordion>


                        {/*reference accordion*/}

                        <Accordion expanded={ expanded === "panel8"} onChange={(e) => {
                            onchangeAccordion("panel8",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    color={referenceTitleColor}>{wordsWithLang.cvApp.cvAppForms.referenceTitle}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReferenceAccordion cvAppConfigCvData={cvAppConfigCvData}
                                                    wordsWithLang={wordsWithLang}
                                                    expanded={expanded} setExpanded={setExpanded}
                                                    updateCvDataInServer={updateCvDataInServer}
                                                    refInfoValidate={refInfoValidate}
                                                    errorMessagesForRefData={errorMessagesForRefData}/>

                            </AccordionDetails>
                        </Accordion>

                        {/*note accordion*/}

                        <Accordion expanded={ expanded === "panel9"} onChange={(e) => {
                            onchangeAccordion("panel9",e)
                        }} style={{borderRadius: 10, margin: 10}}>
                            <AccordionSummary expandIcon={<AccordionIconButton/>}>
                                <Typography
                                    style={{color: additionalNoteTitleColor}}>{"📝 " + wordsWithLang.cvApp.cvAppForms.additionalNotesTitle}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <NoteAccordion cvAppConfigCvData={cvAppConfigCvData}
                                               wordsWithLang={wordsWithLang}
                                               updateCvDataInServer={updateCvDataInServer}
                                               checkAllErrors={checkAllErrors}
                                               cvLanguage={newCvOrder.language}
                                               aiBalance={userConfig.aiBalance}/>

                            </AccordionDetails>
                        </Accordion>
                        {/*</Grid>*/}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </Grid>
                    {/*accordions end*/}

                </Grid>
                <CustomDialog wordsWithLang={wordsWithLang} open={deleteAvatarDialogStatus} onClose={() => (setDeleteAvatarDialogStatus(false))}
                              onYes={() => (onDeleteAvatar())}
                              question={wordsWithLang.cvApp.deleteConfirmQuestionAvatar}
                              header={wordsWithLang.cvApp.deleteConfirm}/>

                <Dialog open={openCropper} onClose={() => (setOpenCropper(false))} maxWidth={"sm"} fullWidth={true}>
                    <DialogTitle>{wordsWithLang.general.cropImage}</DialogTitle>
                    <DialogContent>
                        <CropperComponent imageSrc={selectedFile} onCropComplete={handleCropComplete}
                                          wordsWithLang={wordsWithLang} handleClose={handleFileChange}/>
                    </DialogContent>
                </Dialog>
            </Grid>
        );
    }


export default CvOrderEdit;



