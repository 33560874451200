export const flagUrls = [{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇪🇸", "flagurl": "https://flagcdn.com/w80/es.png"},
{"flag": "🇬🇧", "flagurl": "https://flagcdn.com/w80/gb.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇸🇦", "flagurl": "https://flagcdn.com/w80/sa.png"},
{"flag": "🇧🇩", "flagurl": "https://flagcdn.com/w80/bd.png"},
{"flag": "🇵🇹", "flagurl": "https://flagcdn.com/w80/pt.png"},
{"flag": "🇷🇺", "flagurl": "https://flagcdn.com/w80/ru.png"},
{"flag": "🇯🇵", "flagurl": "https://flagcdn.com/w80/jp.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇹🇷", "flagurl": "https://flagcdn.com/w80/tr.png"},
{"flag": "🇰🇷", "flagurl": "https://flagcdn.com/w80/kr.png"},
{"flag": "🇫🇷", "flagurl": "https://flagcdn.com/w80/fr.png"},
{"flag": "🇩🇪", "flagurl": "https://flagcdn.com/w80/de.png"},
{"flag": "🇻🇳", "flagurl": "https://flagcdn.com/w80/vn.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇵🇰", "flagurl": "https://flagcdn.com/w80/pk.png"},
{"flag": "🇮🇩", "flagurl": "https://flagcdn.com/w80/id.png"},
{"flag": "🇮🇹", "flagurl": "https://flagcdn.com/w80/it.png"},
{"flag": "🇵🇰", "flagurl": "https://flagcdn.com/w80/pk.png"},
{"flag": "🇹🇭", "flagurl": "https://flagcdn.com/w80/th.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇮🇷", "flagurl": "https://flagcdn.com/w80/ir.png"},
{"flag": "🇵🇱", "flagurl": "https://flagcdn.com/w80/pl.png"},
{"flag": "🇦🇫", "flagurl": "https://flagcdn.com/w80/af.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇮🇩", "flagurl": "https://flagcdn.com/w80/id.png"},
{"flag": "🇳🇬", "flagurl": "https://flagcdn.com/w80/ng.png"},
{"flag": "🇲🇲", "flagurl": "https://flagcdn.com/w80/mm.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇺🇦", "flagurl": "https://flagcdn.com/w80/ua.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇵🇭", "flagurl": "https://flagcdn.com/w80/ph.png"},
{"flag": "🇳🇬", "flagurl": "https://flagcdn.com/w80/ng.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇺🇿", "flagurl": "https://flagcdn.com/w80/uz.png"},
{"flag": "🇵🇰", "flagurl": "https://flagcdn.com/w80/pk.png"},
{"flag": "🇪🇹", "flagurl": "https://flagcdn.com/w80/et.png"},
{"flag": "🇸🇳", "flagurl": "https://flagcdn.com/w80/sn.png"},
{"flag": "🇷🇴", "flagurl": "https://flagcdn.com/w80/ro.png"},
{"flag": "🇪🇹", "flagurl": "https://flagcdn.com/w80/et.png"},
{"flag": "🇳🇬", "flagurl": "https://flagcdn.com/w80/ng.png"},
{"flag": "🇦🇿", "flagurl": "https://flagcdn.com/w80/az.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇵🇭", "flagurl": "https://flagcdn.com/w80/ph.png"},
{"flag": "🇳🇱", "flagurl": "https://flagcdn.com/w80/nl.png"},
{"flag": "🇹🇷", "flagurl": "https://flagcdn.com/w80/tr.png"},
{"flag": "🇷🇸", "flagurl": "https://flagcdn.com/w80/rs.png"},
{"flag": "🇲🇬", "flagurl": "https://flagcdn.com/w80/mg.png"},
{"flag": "🇵🇰", "flagurl": "https://flagcdn.com/w80/pk.png"},
{"flag": "🇳🇵", "flagurl": "https://flagcdn.com/w80/np.png"},
{"flag": "🇱🇰", "flagurl": "https://flagcdn.com/w80/lk.png"},
{"flag": "🇧🇩", "flagurl": "https://flagcdn.com/w80/bd.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇰🇭", "flagurl": "https://flagcdn.com/w80/kh.png"},
{"flag": "🇸🇴", "flagurl": "https://flagcdn.com/w80/so.png"},
{"flag": "🇲🇻", "flagurl": "https://flagcdn.com/w80/mv.png"},
{"flag": "🇮🇩", "flagurl": "https://flagcdn.com/w80/id.png"},
{"flag": "🇭🇺", "flagurl": "https://flagcdn.com/w80/hu.png"},
{"flag": "🇧🇮", "flagurl": "https://flagcdn.com/w80/bi.png"},
{"flag": "🇨🇿", "flagurl": "https://flagcdn.com/w80/cz.png"},
{"flag": "🇬🇷", "flagurl": "https://flagcdn.com/w80/gr.png"},
{"flag": "🇸🇪", "flagurl": "https://flagcdn.com/w80/se.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇿🇼", "flagurl": "https://flagcdn.com/w80/zw.png"},
{"flag": "🇨🇳", "flagurl": "https://flagcdn.com/w80/cn.png"},
{"flag": "🇧🇫", "flagurl": "https://flagcdn.com/w80/bf.png"},
{"flag": "🇿🇦", "flagurl": "https://flagcdn.com/w80/za.png"},
{"flag": "🇧🇾", "flagurl": "https://flagcdn.com/w80/by.png"},
{"flag": "🇵🇰", "flagurl": "https://flagcdn.com/w80/pk.png"},
{"flag": "🇮🇳", "flagurl": "https://flagcdn.com/w80/in.png"},
{"flag": "🇸🇳", "flagurl": "https://flagcdn.com/w80/sn.png"},
{"flag": "🇿🇦", "flagurl": "https://flagcdn.com/w80/za.png"},
{"flag": "🇪🇷", "flagurl": "https://flagcdn.com/w80/er.png"},
{"flag": "🇮🇱", "flagurl": "https://flagcdn.com/w80/il.png"},
{"flag": "🇧🇼", "flagurl": "https://flagcdn.com/w80/bw.png"},
{"flag": "🇸🇰", "flagurl": "https://flagcdn.com/w80/sk.png"},
{"flag": "🇪🇸", "flagurl": "https://flagcdn.com/w80/es.png"},
{"flag": "🇱🇻", "flagurl": "https://flagcdn.com/w80/lv.png"},
{"flag": "🇱🇸", "flagurl": "https://flagcdn.com/w80/ls.png"},
{"flag": "🇲🇼", "flagurl": "https://flagcdn.com/w80/mw.png"},
{"flag": "🇧🇮", "flagurl": "https://flagcdn.com/w80/bi.png"},
{"flag": "🇲🇳", "flagurl": "https://flagcdn.com/w80/mn.png"},
{"flag": "🇰🇿", "flagurl": "https://flagcdn.com/w80/kz.png"},
{"flag": "🇧🇩", "flagurl": "https://flagcdn.com/w80/bd.png"},
{"flag": "🇳🇴", "flagurl": "https://flagcdn.com/w80/no.png"},
{"flag": "🇱🇦", "flagurl": "https://flagcdn.com/w80/la.png"},
{"flag": "🇪🇪", "flagurl": "https://flagcdn.com/w80/ee.png"},
{"flag": "🇲🇹", "flagurl": "https://flagcdn.com/w80/mt.png"},
{"flag": "🇹🇯", "flagurl": "https://flagcdn.com/w80/tj.png"},
{"flag": "🇱🇸", "flagurl": "https://flagcdn.com/w80/ls.png"}]
