import React from 'react';
import {Box} from "@mui/material";

const SkeletonLoader = ({visible}) => {
    const boxStyle = {
        // paddingTop: "16px",
        marginTop:visible?"16px":"0px",
        position: 'relative',
        // zIndex: -1,
        // width: '582px',
        width: '100%',
        height: visible?'auto':'0px',
        // height: '822px',
        // backgroundImage: 'url("https://bakujobs.az/img/cv_skleton.jpg")', // Replace with your image URL
        backgroundSize: 'cover',
        overflow: 'hidden',
        boxShadow: "0 6px 10px rgba(0,0,0,.4), 0 0 6px rgba(0,0,0,.05)",
        // visibility:visible?"visible":"hidden",
        opacity:visible?"1":"0",
        transition: "opacity 0.5s ease-in-out, height 0.5s ease-in-out",
        objectFit: "contain",
        objectPosition: "top",
    };

    const lineStyle = {
        position: 'absolute',
        width: '120px',
        height: '100%',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
        animation: 'moveLine 2s infinite'
    };

    const keyframes = `
    @keyframes moveLine {
      0% {
        left: 0;
      }
      50% {
        left: calc(100% - 20px);
      }
      100% {
        left: 0;
      }
    }
  `;

    return (
        <div style={boxStyle}>
            <style>{keyframes}</style>
            <div style={lineStyle}></div>
            <Box style={{
                width: '100%',
                height: '100%',
                objectFit: "contain",
                objectPosition: "top",
            }} component="img" src={"https://bakujobs.az/img/cv_skleton.jpg"} />
        </div>
    );
};

export default SkeletonLoader;
