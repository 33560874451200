import React from 'react';
import {Grid, Button, Typography, Divider, Avatar, useMediaQuery} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import * as staticVars from "../../utils/staticVars";
import {CheckCircle} from "@mui/icons-material";

export default function CVLanguageSelection({ wordsWithLang, selectLanguage, createNewCv, selectedLanguage }) {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return (
        <Grid container justifyContent={"center"} alignContent={isMobile ? "center" : "flex-start"} direction={ isMobile ? "column" : "row"} style={{marginBottom:"1rem"}}>
            {/*<Card style={{marginTop:17, padding:15}}>*/}
            <Button  size="large"
                     fullWidth={isMobile}
                     variant={selectedLanguage === 'az' ? "contained" : "outlined"}
                     color={"violet"}
                     style={{borderRadius:30,marginRight:5, justifyContent: "flex-start", marginLeft: 15, marginTop: 10, color: "#333333", borderColor:staticVars.colors.darkPurple}}
                     onClick={() => {
                         selectLanguage('az')
                     }}>
                {/*{newCvOrder.language === 'az' ? <CheckCircle fontSize="large" style={{color: '#00ce49',transition: "all .3s ease-in-out"}}/> :*/}
                {/*    <CheckCircle fontSize="large" style={{color: 'gray'}}/>}*/}
                <img src="https://bakujobs.az/img/az.png" alt="Description"
                     style={{width: '45px', height: '45px', borderRadius: '10px'}} draggable={false}/>
                <Typography variant="button"
                            style={{color:selectedLanguage!=='az'? "black":"white", paddingLeft: 10, fontSize: isMobile ? 20 : 20}}
                            align={"left"}>
                    Azərbaycan
                </Typography>
            </Button>
            <Button  size="large"
                     fullWidth={isMobile}
                     variant={selectedLanguage === 'ru' ? "contained" : "outlined"}
                     color={"violet"}
                     style={{borderRadius:30,justifyContent: "flex-start", marginLeft: 15, marginTop: 10, color: "#333333", borderColor:staticVars.colors.darkPurple}}
                     onClick={() => selectLanguage('ru')}>
                {/*{newCvOrder.language === 'ru' ? <CheckCircle fontSize="large" style={{color: '#00ce49', transition: "all .3s ease-in-out"}}/> :*/}
                {/*    <CheckCircle fontSize="large" style={{color: 'gray'}}/>}*/}
                <img src="https://bakujobs.az/img/ru.png" alt="Description"
                     style={{width: '45px', height: '45px', borderRadius: '10px'}} draggable={false}/>
                <Typography variant="button"
                            style={{color:selectedLanguage!=='ru'? "black":"white", paddingLeft: 10, fontSize: isMobile ? 20 : 20}} align={"left"}>
                    Pусский
                </Typography>

            </Button>
            <Button  size="large"
                     fullWidth={isMobile}
                     variant={selectedLanguage === 'en' ? "contained" : "outlined"}
                     color={"violet"}
                     style={{borderRadius:30,justifyContent: "flex-start", marginLeft: 15, marginTop: 10, color: "#333333", borderColor:staticVars.colors.darkPurple}}
                     onClick={() => selectLanguage('en')}>
                {/*{selectedLanguage === 'en' ? <CheckCircle fontSize="large" style={{color: '#00ce49', transition: "all .3s ease-in-out"}}/> :*/}
                {/*    <CheckCircle fontSize="large" style={{color: 'gray'}}/>}*/}
                <img src="https://bakujobs.az/img/en.png" alt="Description"
                     style={{width: '45px', height: '45px', borderRadius: '10px'}} draggable={false}/>
                <Typography variant="button"
                            style={{color:selectedLanguage!=='en'? "black":"white", paddingLeft: 10, fontSize: isMobile ? 20 : 20}} align={"left"}>
                    English
                </Typography>

            </Button>
            {/*</Card>*/}
        </Grid>
    );
}
