import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Chip,
    CircularProgress,
    Divider,
    Grid, IconButton,
    Pagination,
    TextField,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {CancelOutlined, CheckCircleOutline, CloseOutlined, DeleteForever} from "@mui/icons-material";

const AdminCvUserTab = ({control, setControl}) => {
    const [search, setSearch] = useState("")

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(5);
    const [userList, setUserList] = useState([]);
    const [userCount, setUserCount] = useState(0);

    const getAllUsers = (from,data) => {
        if(from===undefined){
            setUserList([])
            axios.get(apiUrls.baseApiUrl + apiUrls.getAdminUsers.replace("{page}",page), {withCredentials: true}).then((resp) => {
                if (resp.data !== [] || resp.data !== null) {
                    setUserList(resp.data)
                } else {
                    alert("Results ended!")
                }
            })
        }
        if(from==="email"){
            setUserList([])
            axios.get(apiUrls.baseApiUrl + apiUrls.getAdminUsers.replace("{page}",page)+"?email="+data, {withCredentials: true}).then((resp) => {
                if (resp.data !== [] || resp.data !== null) {
                    setUserList(resp.data)
                } else {
                    alert("Results ended!")
                }
            })
        }

    }

    useEffect(() => {
        console.log(control)
        getAllUsers();
        setCount(page + 3)
    }, [page, control.callGetAllUsers]);

    useEffect(() => {
        axios.get(apiUrls.baseApiUrl + apiUrls.getAdminUsersCount, {withCredentials: true}).then((resp) => {
        setUserCount(resp.data)
        })
    }, []);



    return (
        <Box pt={1} pl={2}>
            <Grid item xs={12} alignItems={"center"} display={"flex"}>
                <Typography variant={"h5"}>{userCount}</Typography>
            </Grid>
            <Grid item xs={12} alignItems={"center"} display={"flex"}>
                {/*<Box display={"flex"} flexWrap={"wrap"} width={"100%"}>*/}
                    <TextField size={"small"} placeholder={"Email/ID"} value={search} onChange={(e) => {
                        setSearch(e.target.value)
                    }}/>
                    <Button onClick={()=>{getAllUsers("email",search)}} sx={{marginLeft: ".5rem"}} size={"small"} variant={"outlined"} >email</Button>
                    <Button sx={{marginLeft: ".5rem"}} size={"small"} variant={"outlined"}>id</Button>
                    <Button sx={{marginLeft: ".5rem"}} size={"small"} variant={"outlined"}>number</Button>
            </Grid>
                {/*</Box>*/}
                <Divider orientation="horizontal" variant={"fullWidth"} sx={dividerSx}/>
                <Grid item xs={12} p={2}>
                    {userList.length > 0 ? userList.map((item, index) => {
                            return <UserListItem
                                key={index}
                                setControl={setControl}
                                control={control}
                                index={"u"+index}
                                item={item}
                                // showMore={showMore}
                                // setShowMore={setShowMore}
                                // setShowAsUserDialog={setShowAsUserDialog}
                                // setDialogCvOrder={setDialogCvOrder}
                                />
                        }
                    ) : <Typography sx={{padding: 5}} align={"center"} variant={"h4"}>
                        <CircularProgress size={50} color={"violet"}/>
                    </Typography>}
                </Grid>

            <Grid item xs={12} margin={5}>
                <Pagination onChange={(e, value) => {
                    setPage(value)
                }} count={count} variant="outlined"/>
            </Grid>




        </Box>
    );
};



const UserListItem = ({index, item, control, setControl}) => {
    const [userBalance, setUserBalance] = useState("notSet");
    const deleteUser = (id) => {
        console.log(id)
        axios.get(apiUrls.baseApiUrl+apiUrls.getAdminDeleteUser.replace("{id}", id),{withCredentials: true}).then((resp)=>{
            console.log(resp.data)
            alert("REMOVED!")
        })
    }
    const increaseUserBalance = () =>{
        axios.get(apiUrls.baseApiUrl+apiUrls.getAdminIncreaseUserBalance.replace("{email}", item.email),{withCredentials: true}).then((resp)=>{
            console.log(resp.data)
            alert("INCREASED !")
        })
    }
    const decreaseUserBalance = () =>{
        axios.get(apiUrls.baseApiUrl+apiUrls.getAdminIncreaseUserBalance.replace("{email}", item.email).replace("/1","/-1"),{withCredentials: true}).then((resp)=>{
            console.log(resp.data)
            alert("DECREASED !")
        })
    }

    const getUserBalance = () => {
        axios.get(apiUrls.baseApiUrl+apiUrls.getAdminUserBalance.replace("{email}", item.email),{withCredentials: true}).then((resp)=>{
            console.log(resp.data)
            setUserBalance(resp.data.balance)
        })
    }

    const nameOfField = (name) => {
        return <span style={{ fontSize: "14px" }}>{name}<br /></span>;
    };

    useEffect(() => {
        getUserBalance()
    }, []);

    return(<>

        <Card style={{
            // borderBottom: ".5px solid gray",
            flexWrap: "wrap",
            boxShadow: "",
            borderRadius: 10,
            margin: 7,
            padding: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly"
        }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                minHeight:60,
                fontSize: "12px",
                zoom: 0.8,
            }}>
                <Box  mr={1} ml={1} width={35} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"id"}</Typography>
                    <Typography>{item.id}</Typography>
                </Box>
                <Box mr={1} ml={1} width={150} sx={{overflowWrap:"anywhere"}} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"name"}</Typography>
                    <Typography>{item.fullName}</Typography>
                </Box>
                <Box mr={1} ml={1} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"newsletters"}</Typography>
                    <Typography>{item.newsletterCategories.length!==0?item.newsletterCategories:(<CancelOutlined color={"warning"}/>)}</Typography>
                </Box>
                <Box mr={1} ml={1} width={230} sx={{overflowWrap:"anywhere"}} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"email"}</Typography>
                    <Typography>{item.email}</Typography>
                </Box>
                <Box mr={1} ml={1} width={150} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"phone"}</Typography>
                    <Typography>{item.phoneNumber}</Typography>
                </Box>
                <Box mr={1} ml={1} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"verify"}</Typography>
                    <Typography>{item.emailIsVerify?(<CheckCircleOutline color={"success"}/>):(<CancelOutlined color={"warning"}/>)}</Typography>
                </Box>
                {/*<Box  mr={1} ml={1} width={"100px"} display={"flex"} item.status==="active"?(<Chip label={"active"} sx={{height:20, color:"white"}} color={"success"}/>):(<CancelOutlined color={"warning"}/>)}</Box>*/}
                <Box  mr={1} ml={1} width={100} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"created"}</Typography>
                    <Typography>{item.createdAt.split("T")[0]}</Typography>
                </Box>
                <Box mr={1} ml={1} width={200} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"cvFile"}</Typography>
                    <Typography sx={{overflowWrap:"anywhere"}}>{item.cvFileName}</Typography>
                </Box>
                <Box  mr={1} ml={1} width={180} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
                    <Typography display={"flex"} fontSize={10} color={"gray"} flexWrap={"wrap"} flexDirection={"column"}>{"actions"}</Typography>
                    <Box display={"flex"} size="small"  flexDirection={"row"} sx={{height:30}}>

                        <ButtonGroup color={"secondary"} variant={"outlined"} size={"small"} display={"flex"}>
                            <Button sx={{borderRadius:"26px 0px 0px 26px"}} key="plusone" onClick={()=>{if(window.confirm("sure?")) {increaseUserBalance()}}}>+1</Button>
                            <Button disabled key="balance">{userBalance+" CV"}</Button>
                            <Button sx={{borderRadius:"0px 26px 26px 0px"}} key="minusone" onClick={()=>{if(window.confirm("sure?")) {decreaseUserBalance()}}}>-1</Button>
                        </ButtonGroup>
                        <IconButton sx={{marginLeft:1}} variant={"outlined"} onClick={()=>{if(window.confirm("sure?")) {deleteUser(item.id)}}} key="del">{<DeleteForever/>}</IconButton>

                {/*<Button mr={1} ml={1} sx={{overflowWrap:"anywhere", borderRadius:10}} color={"secondary"} variant={"outlined"} size={"small"} display={"flex"}*/}
                {/*         onClick={()=>{if(window.confirm("sure?")) {increaseUserBalance()}}}>*/}
                {/*    +1 CV</Button>*/}
                {/*<Typography>{userBalance}</Typography>*/}
                {/*<Button mr={1} ml={1} sx={{overflowWrap:"anywhere", borderRadius:10}} color={"secondary"} variant={"outlined"} size={"small"} display={"flex"}*/}
                {/*        onClick={()=>{if(window.confirm("sure?")) {decreaseUserBalance()}}}>*/}
                {/*    -1 CV</Button>*/}
                    </Box>
                {/*<Button  mr={1} ml={1} sx={{overflowWrap:"anywhere", borderRadius:10}} color={"secondary"} variant={"outlined"} size={"small"} display={"flex"} >+1 CV</Button>*/}
                </Box>
            </Box>
            </Card>
        </>
    )
}

const dividerSx = {
    height: "1rem",
    // width: "100%",
    marginRight: 2,
    marginLeft: 1,
    display: "flex",
    verticalAlign: "middle",
};

export default AdminCvUserTab;

