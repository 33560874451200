import {useEffect, useState} from 'react';
import './GradientButton.css';
import {AutoAwesome, DocumentScanner} from "@mui/icons-material";

const GradientButton = ({ wordsWithLang, fromBlog }) => {
    const [hover, setHover] = useState(false);
    useEffect(() => {
        console.log(fromBlog)
    }, []);
     return fromBlog? (
         <a
            style={{width:"auto",height:20, fontWeight:"bold"}}
            className="addButton gradient-border"
            href={"/cv"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
      <span style={{display:"flex", alignItems:"center", justifyContent:"center"}} className={hover ? 'hidden' : ''}>
        <DocumentScanner sx={{marginRight:1}} /> {wordsWithLang.cvApp.createCv}
      </span>
      {/*      <span style={{display:"flex", alignItems:"center", justifyContent:"center"}} className={hover ? '' : 'hidden'}>*/}
      {/*          <AutoAwesome sx={{marginRight:1}}/>{wordsWithLang.cvApp.cvAppHeader}</span>*/}
        </a>
    ):
         (
             <a
                 style={{width:"auto"}}
                 className="button gradient-border"
                 href={"/cv"}
                 onMouseEnter={() => setHover(true)}
                 onMouseLeave={() => setHover(false)}
             >
      <span style={{display:"flex", alignItems:"center", justifyContent:"center"}} className={hover ? 'hidden' : ''}>
        <DocumentScanner sx={{marginRight:1}} /> {wordsWithLang.cvApp.createCv}
      </span>
                 <span style={{display:"flex", alignItems:"center", justifyContent:"center"}} className={hover ? '' : 'hidden'}>
                <AutoAwesome sx={{marginRight:1}}/>{wordsWithLang.cvApp.cvAppHeader}</span>
             </a>
         )


};

export default GradientButton;

// GradientButton.css

