import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Grid, Typography} from "@mui/material";
import VacancyItem from "../components/vacancyItem";
import axios from "axios";
import apiUrls from "../apiUrls";
import {ArrowBack, Home, SearchTwoTone} from "@mui/icons-material";
import {Link} from "react-router-dom";

const Favorites = ({wordsWithLang}) => {
    const userConfig = useSelector(state=>state.userConfig)
    const vacancySelector = useSelector(state => state.vacancy)
    const dispatcher = useDispatch()

    const checkForLogin = () => {
        document.title = wordsWithLang.general.documentTitleFromFavorite
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
        else{
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
            if(userConfig.favorites && userConfig.favorites.length>0){
                axios.post(apiUrls.baseApiUrl + apiUrls.getFavorites, {favorites:userConfig.favorites},{withCredentials:true})
                    .then(resp=>{
                        dispatcher({type:"SET_FAV_VACANCIES",payload:resp.data})
                    })
            }

        }
    }

    useEffect(checkForLogin,[userConfig.email])

    return (
        <div>
            {userConfig.isLogin?(
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Link to={-1}><Button style={{ margin:"20px 0px", borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                            <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
                    </Grid>

                    {userConfig.favorites.length>0 ? vacancySelector.favVacancies.map((item, k) => {
                        return (<VacancyItem  wordsWithLang={wordsWithLang} key={k} item={item}/>)
                    }): (<Box display={"flex"} justifyContent={"center"} style={{width:"100%", marginBottom:10, verticalAlign:"middle", marginTop:30}} dir={"column-reverse"} >
                        <Typography display={"block"} variant={"h5"}>{wordsWithLang.header.favVacancyNotFound}</Typography>
                        <SearchTwoTone fontSize="large"/>
                    </Box>)}
                </Grid>
            ):(<Typography style={{marginLeft:50}} display={"block"} align={"left"} variant={"h5"}>{wordsWithLang.header.pleaseLoginFirst}</Typography>)}
        </div>
    );
};

export default Favorites;
