import React, {useEffect, useState} from 'react';
import {words} from "../words";
import {setDocDesc, setDocTitle, setOgImage} from "../utils/Utils";
import {Button, Grid, Typography} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import axios from "axios";
import apiUrls from "../apiUrls";
import {Link, useParams} from "react-router-dom";
import GoUpButton from "../miniComponents/goUpButton";
import BlogCartItem from "../components/blogCartItem";
import GradientButton from "../miniComponents/GradientButton";



const Blog = ({wordsWithLang}) => {

    const {slug} = useParams();
    const {lang} = useParams();
    const [blogPost, setBlogpost] = useState({
        id: "",
        blogPost: "",
        blogPostImageSlug: "",
        gtitle: "",
        gdesc: "",
        category: "",
        slug: "",
        viewCount: "",
        addDate: "",
        keywords: "",
    })
    const [moreBlogs, setMoreBlogs] = useState(null)

    useEffect(() => {
        axios.get(apiUrls.baseApiUrl + apiUrls.getBlog.replace(":slug", slug), {withCredentials: true})
            .then(resp => {
                console.log(resp.data)
                setBlogpost(resp.data)
            })

    }, [])

    useEffect(() => {
        let lang = window.localStorage.pageLang?window.localStorage.pageLang:"az"
        // let lang = "tr"

        axios.get(apiUrls.baseApiUrl + apiUrls.allBlogPosts.replace("{lang}",lang), {withCredentials: true})
            .then(resp => {
                setMoreBlogs(resp.data)
            })

    }, [])

    useEffect(() => {
        if (blogPost.id !== "") {
            setDocTitle(blogPost.gtitle);
            setDocDesc(blogPost.gdesc);
            setOgImage(blogPost.blogPostImageSlug);
        }
    }, [blogPost])

    return (

        <Grid container justifyContent={"center"} alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            <br/>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
            {/*<Link to={"/blog"}><Button color="primary" variant={"contained"}><ArrowBack/><Typography*/}
            {/*    style={{marginLeft: 3, borderRadius:20}}*/}
            {/*    variant="button">{words.vacancyBody.back}</Typography></Button></Link>*/}
                <Link to={-1}><Button style={{ margin:"20px 0px", borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                    <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>

            </Grid>

           <br/>
            <br/>
            <br/>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                {/*<Link to={-1}><Button color={"secondary"} variant={"contained"}><Note/><Typography*/}
                {/*    style={{marginLeft: 3}}*/}
                {/*    variant="button">{words.header.vacancies}</Typography></Button></Link>*/}
                <GradientButton fromBlog={true} wordsWithLang={wordsWithLang}/>

                <div id="contentBlog" style={{display:"flex", justifyContent:"center", overlay:"auto", flexDirection:"column", width:"auto"}} dangerouslySetInnerHTML={{__html: blogPost.blogPost}}></div>
                {/*<div><br/><span style={{fontSize: 8, color: "" + "gray"}}>{"ID: "+blogPost.id}</span><br/></div>*/}
                {/*<div><br/><span>Daha çox məlumatı saytımızdan əldə edə bilərsiniz. ❤️ </span><br/></div>*/}
                {/*<div><br/><a style={{fontWeight:"bold"}} href={"https://bakujobs.az"}>BAKUJOBS.AZ</a><br/></div>*/}
                <br/>
                <br/>
                <GradientButton fromBlog={true} wordsWithLang={wordsWithLang}/>
                {/*<div><br/><span>Digər yazılarımız 👇</span><br/></div>*/}
                {moreBlogs ? (moreBlogs.map((item, i) => (
                    // <a style={{fontSize: 20, fontWeight: "bold"}} href={item.slug} key={i}>{"- " + item.gtitle}<br/></a>
                    <BlogCartItem key={i} blogItem={item}/>
                ))) : null}
                <br/>
                <br/>
                {/*<BlogCartItem blog={moreBlogs[0]}/>*/}
                <br/>
                <br/>
                <br/>
                {/*{useMediaQuery(theme => theme.breakpoints.down("sm"))*/}
                {/*    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :*/}
                {/*    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}*/}
                {/*{useMediaQuery(theme => theme.breakpoints.down("sm"))*/}
                {/*    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :*/}
                {/*    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}*/}
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}

            <GoUpButton/>

        </Grid>


    );
};

export default Blog;
