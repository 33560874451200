import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, Grid, Paper, Typography} from "@mui/material";
import {AccessTime, ArrowBack, LockClock} from "@mui/icons-material";
import BlogCartItem from "../components/blogCartItem";
import MiniBlogCartItem from "../components/miniBlogCartItem";
import axios from "axios";
import {Link} from "react-router-dom";

const BlogMain = ({wordsWithLang}) => {
    const [blogItem, setBlogItem] = useState(
        {
            "id": 1,
            "blogPost": "Günümüzde iş bulmak, birçok kişi için heyecan verici ancak zorlu bir süreç olabilir. Neyse ki, dijital çağın getirdiği avantajlar sayesinde iş arama deneyimi daha da kolaylaşıyor. Bu noktada, Bakujobs.az gibi öncü iş ilanı siteleri devreye giriyor.  Bakujobs.az, iş arayanlar için bir vaha gibi, işverenler için ise nitelikli adaylara ulaşmanın etkili bir yolu olarak öne çıkıyor. Ancak başarılı bir platform olmanın sırrı sadece iş ilanlarını listelemekle sınırlı değil; aynı zamanda etkileyici bir SEO stratejisiyle de desteklenmelidir.",
            "category": "home / jobs / jobs in Baku",
            "blogPostImageSlug": "bakujobs-is-ilanlari.jpg",
            "slug": "bakujobs-is-ilanlari",
            "viewCount": 10,
            "addDate": "2025-03-25",
            "keywords": "bakü,iş ilanları, site, çalışma, para",
            "lang": "tr",
            "gdesc": "Günümüzde iş bulmak, birçok kişi için heyecan verici ancak zorlu bir süreç olabilir. Neyse ki, dijital çağın getirdiği avantajlar sayesinde iş arama deneyimi daha da kolaylaşıyor",
            "gtitle": "Baküde iş ilanları sitesi"
        }
    );

    const [blogs,setBlogs] = useState([])

    const lang = window.localStorage.pageLang ? window.localStorage.pageLang : "az";

// Month translations
    const monthNames = {
        en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        az: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "İyun", "İyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"],
        ru: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        tr: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
    };

// Function to get month name based on selected language
    const getMonthName = (date) => {
        const monthIndex = new Date(date).getMonth();
        return `${monthNames[lang][monthIndex]} ${new Date(date).getFullYear()}`;
    };



    // Group blogs by month
    const groupBlogsByMonth = (blogs) => {
        return blogs.reduce((acc, blog) => {
            const monthYear = getMonthName(blog.addDate);
            if (!acc[monthYear]) acc[monthYear] = [];
            acc[monthYear].push(blog);
            return acc;
        }, {});
    };
    const groupedBlogs = groupBlogsByMonth(blogs);

    useEffect(() => {
        let lang = window.localStorage.pageLang?window.localStorage.pageLang:"az"
        // let lang = "tr"
        axios.get(`https://bakujobs.az/api/public/blog/allBlogPosts/${lang}/1`).then((reps)=>{
            setBlogs(reps.data)
        })
    }, []);



    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Link to={-1}><Button style={{ margin:"20px 0px", borderRadius:30, color:"white"}} color="violet" variant={"contained"}><ArrowBack/>
                        <Typography style={{marginLeft: 3,fontSize: "15px",}} variant="button">{wordsWithLang.vacancyBody.back}</Typography></Button></Link>
                </Grid>
                <Grid item xs={12}>
                    {/*{search}*/}
                </Grid>
                <Grid item xs={12}>
                    {/*{blogs.length>0 && blogs.map((item,index)=>{*/}
                    {/*    return (<BlogCartItem blogItem={item}/>)*/}
                    {/*})}*/}

                    {Object.entries(groupedBlogs).map(([month, monthBlogs]) => (
                        <Grid item xs={12} key={month} style={{ marginTop: 20 }}>
                            <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: 10 }}>
                                {month} {/* Month name is now translated */}
                            </Typography>
                            {monthBlogs.map((blog, index) => (
                                <BlogCartItem key={index} blogItem={blog} />
                            ))}
                        </Grid>
                    ))}
                    {/*<MiniBlogCartItem/>*/}
                </Grid>
            </Grid>

        </div>
    );
};

export default BlogMain;
