import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {
    Add,
    ArrowDownward,
    AutoAwesome,
    CheckBox,
    Clear,
    Info,
    LinkSharp,
    LockClock,
    ModeEdit
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import GradientCircularProgress from "../../miniComponents/GradientCircularProgress";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {apiGetUserInfo} from "../../utils/apiInterface";
import CustomDialog from "../../miniComponents/CustomDialog";
// import {checkForLogin, updateUser} from "../../utils/apiInterface";

const PersonalAccordion = ({
    isMobile,
                               newCvOrder,
                               wordsWithLang,
                               cvAppConfigCvData,
                               updateCvDataInServer,
                               expanded,
                               setExpanded, personalInfoValidate, startCheckingPersonal,
                               errorMessagesForPersonalData
                           }) => {
    const userConfig = useSelector(state => state.userConfig)
    const [personalData, setPersonalData] = useState(cvAppConfigCvData.personal)
    const [link, setLink] = useState('')
    const dispatcher = useDispatch();
    const [showAddress, setShowAddress] = useState(false)
    const [aiButtonClicked, setAiButtonClicked] = useState(false)

    const [aiSumData, setAiSumData] = useState({
        "sumType": "ABOUT_ME",
        // "summary": "NO_BALANCE",
        "language": newCvOrder.language,
        "message": "",
    })


    useEffect(() => {
        personalInfoValidate(false, personalData)
    }, [personalData, startCheckingPersonal])

    useEffect(() => {
        if (personalData.address && personalData.address !== "") {
            setShowAddress(true)
        }
    }, [])

    useEffect(() => {
        setCvOrderStatus(newCvOrder.status === "DONE" || newCvOrder.status === "DRAFT2"|| newCvOrder.status === "")
        // setCvOrderStatus(true)
    }, [newCvOrder.status]);


    const getAiSum =  () => {
        aiSumData.message = personalData.about
        console.log(userConfig.aiBalance)
        axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostAISummary, aiSumData, {withCredentials: true}).then(async resp => {
            setAiSumData(resp.data)
            if (resp.data.summary !== "NO_BALANCE" && resp.data.summary !== "") {
                setPersonalData({...personalData, about: resp.data.summary})
                apiGetUserInfo(dispatcher)
                setAiButtonClicked(false)
            }
        })
    }

    const renderAIButtons = () => {
        // console.log(aiSumData)
        if (aiSumData.summary === "NO_BALANCE") {
            return (<Button className={"text-shine"} disabled variant={"outlined"} color="violet" sx={{
                minHeight:"26.5px",
                opacity: aiSumData.summary === "NO_BALANCE" ? "1" : "0",
                borderRadius: "20px",
                transition: "all 0.5s, display 0.5s allow-discrete;"
            }} startIcon={<LockClock/>}><Typography>{wordsWithLang.cvApp.cvAppForms.correctWithAiLimit}</Typography></Button>)
        }
        if (aiButtonClicked) {
            return (<Button variant={"outlined"} disabled color="violet"  onClick={(e) => {
                setAiButtonClicked(false)
            }} sx={{
                color:"black",
                minHeight:"26.5px",
                opacity: !aiButtonClicked ? "0" : "1",
                borderRadius: "20px",
                transition: "all 0.5s, display 0.5s allow-discrete;"
            }} startIcon={<GradientCircularProgress/>}><Typography
                className={"animate__animated animate__flash animate__slower animate__infinite"}>
                {wordsWithLang.cvApp.cvAppForms.correctWithAiWait}</Typography></Button>)
        }
        return (<Button color="violet"
                        variant="outlined"
                        onClick={(e) => {
            getAiSum();
            setAiButtonClicked(true)
        }} className={"text-shine"}
                        sx={{
                            // marginLeft: 23, borderRadius: 30, color: "#4c3198",
            // backgroundImage: "linear-gradient(to right, #4c3198 0, #824fff 30%, #4c3198 60%)",
            borderColor: "#4c3198",
            color:"#4c3198",
            opacity: aiButtonClicked ? "0" : "1",
            borderRadius: "20px",
            transition: "all 0.5s, display 0.5s allow-discrete;"
        }} startIcon={<AutoAwesome color={"secondary"} className={"animate__pulse animate__animated  animate__infinite"}/>}>
            {wordsWithLang.cvApp.cvAppForms.correctWithAi} <Tooltip title={"qalan limit sayi"}><Chip variant={"outlined"} color={"default"}
                                                                              style={{marginLeft: 10, height: 24}}
                                                                              label={userConfig.aiBalance ? userConfig.aiBalance.aiBalance : 10}/></Tooltip></Button>)
    }
    const [birthDateInputType, setBirthDateInputType] = useState("text")

    const [openActiveEdit, setOpenActiveEdit] = useState(false)

    const [cvOrderStatus, setCvOrderStatus] = useState(newCvOrder.status === "SUCCESS" || newCvOrder.status === "DRAFT2")


    return (
        <div>
            <Button color={"secondary"} variant={"outlined"} sx={{borderRadius:20,marginBottom:1,display:cvOrderStatus?"flex":"none"}} startIcon={<ModeEdit/>}
            onClick={()=>{setOpenActiveEdit(true)}}>{wordsWithLang.cvApp.cvAppForms.activateEditPersonal}</Button>

            <CustomDialog wordsWithLang={wordsWithLang} open={openActiveEdit} onYes={()=>{
                axios.get(apiUrls.baseApiUrl+apiUrls.updateCvOrderStatus.replace("{id}",newCvOrder.id),{withCredentials: true}).then(resp=>{
                    if(resp.data === "OK"){
                        setCvOrderStatus(false)
                        setOpenActiveEdit(false)
                        dispatcher({type: "CV_INSTANCE_UPDATE", payload: {...newCvOrder, status: "DRAFT", paymentStatus:"pending"}})
                    }
                })
            }} header={wordsWithLang.cvApp.cvAppForms.activateEditPersonal} question={wordsWithLang.cvApp.cvAppForms.activateEditPersonalInside} onClose={()=>{setOpenActiveEdit(false)}  }/>

            <Alert fontSize={isMobile ? "1rem" : "0.6rem"} variant={"outlined"}
                   icon={<Info sx={{color: "#4C3198"}}/>}
                   sx={{
                       '& .MuiAlert-message': {textWrap: "auto", fontWeight: "500"},
                       color: "#4C3198",
                       borderColor: "#4C3198",
                       display:!cvOrderStatus?"flex":"none"
                   }}
                   style={{
                       borderRadius: '1rem',
                       width: "auto",
                       maxWidth: "100%",
                       marginTop: "15px",
                       marginBottom: "15px",
                       alignSelf: "flex-start"
                   }}>
                {wordsWithLang.cvApp.cvAppForms.warningCanNotEditAfterBuy}
            </Alert>

            < div className={cvOrderStatus?"disabledSection":""}>
            <TextField id={"jobTitle"}

                // disabled={newCvOrder.status==="DRAFT2"}

                       value={personalData.jobTitle} onChange={(e) => {
                setPersonalData({...personalData, jobTitle: e.target.value})
            }} helperText={errorMessagesForPersonalData.jobTitle}
                       error={errorMessagesForPersonalData.jobTitle !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.jobTitle} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"name"}
                // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.name} onChange={(e) => {
                setPersonalData({...personalData, name: e.target.value})
            }} helperText={errorMessagesForPersonalData.name}
                       error={errorMessagesForPersonalData.name !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.name} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"surname"}
                // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.surname} onChange={(e) => {
                setPersonalData({...personalData, surname: e.target.value})
            }} helperText={errorMessagesForPersonalData.surname}
                       error={errorMessagesForPersonalData.surname !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.surname} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"birthDate"}
                // disabled={newCvOrder.status==="DRAFT2"}
                placeholder={"dd/mm/yyyy"}
                       // type={birthDateInputType}
                       // onFocus={() => setBirthDateInputType("date")} // Change to date on focus
                       // onBlur={() => setBirthDateInputType("text")} // Change back to text on blur

                       onClick={(e)=>{e.target.showPicker && e.target.showPicker();}}
                       value={personalData.birthDate} onChange={(e) => {
                setPersonalData({...personalData, birthDate: e.target.value})
            }} helperText={errorMessagesForPersonalData.birthDate}
                       error={errorMessagesForPersonalData.birthDate !== ""}
                       type={"date"}
                       data-date-format="dd/mm/yyyy"
                       fullWidth
                       margin="normal" label={wordsWithLang.cvApp.cvAppForms.birthDate} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}, inputProps: {max: new Date().toISOString().split('T')[0]}}}
                       InputLabelProps={{shrink: true}}/>

                {/*<DatePicker label={'"month" and "year"'} views={['month', 'year']} />*/}
            <TextField id={"email"}
                // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.email} onChange={(e) => {
                setPersonalData({...personalData, email: e.target.value})
            }} helperText={errorMessagesForPersonalData.email}
                       error={errorMessagesForPersonalData.email !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.email} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <label htmlFor="phone" style={{display: 'block', marginBottom: '10px', fontSize: '16px'}}>
                {wordsWithLang.cvApp.cvAppForms.phone}
            </label>
            <PhoneInput value={personalData.phone}
                // disabled={newCvOrder.status==="DRAFT2"}
                        onChange={(phone) => {
                            setPersonalData({...personalData, phone: phone})
                        }} fullWidth margin="normal"
                        country={'az'}
                        enableAreaCodes={true}
                        specialLabel={wordsWithLang.cvApp.cvAppForms.phone}
                        variant="outlined"
                        inputProps={{
                            id: "phone",
                            name: wordsWithLang.cvApp.cvAppForms.phone,
                            required: true,
                            label: wordsWithLang.cvApp.cvAppForms.phone,
                            sx: {borderRadius: 15},
                            // helperText: errorMessagesForPersonalData.phone,
                            error: errorMessagesForPersonalData.phone !== ""
                        }
                        }
                        inputStyle={{
                            padding: '25px',
                            fontSize: '20px',
                            borderRadius: 15,
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            width: "95%",
                            right: "-5%",
                            borderColor: errorMessagesForPersonalData.phone !== "" ? "#e50914" : null,
                        }}
            />


            <FormControlLabel control={
                <Checkbox
                    // disabled={newCvOrder.status==="DRAFT2"}
                    size={"medium"} id={"addressBox"} onClick={(e) => {
                    setShowAddress(!showAddress);
                    setPersonalData({...personalData, address: ""})
                }} checked={showAddress} label={wordsWithLang.cvApp.cvAppForms.address}/>
            } label={<Typography fontWeight={500}>{wordsWithLang.cvApp.cvAppForms.address}</Typography>}
                              style={{marginTop: "1rem"}} sx={{fontWeight: "600"}}/>

            <TextField id={"address"} focused={showAddress} style={{display: showAddress ? "block" : "none"}}
                       onChange={(e) => {
                           setPersonalData({...personalData, address: e.target.value})
                       }} disabled={showAddress === false}
                       value={personalData.address}
                       helperText={personalData.address !== null && personalData.address !== "" ? personalData.address.length + "/100 " + errorMessagesForPersonalData.about : null}
                       error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                       variant="outlined" multiline minRows={2}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 100}}}/>
            <Divider style={{margin: "1rem 0"}}/>
            <TextField id={"about"}
                // disabled={newCvOrder.status==="DRAFT2"}
                       onChange={(e) => {
                           setPersonalData({...personalData, about: e.target.value})
                       }}
                       value={personalData.about}
                       helperText={personalData.about !== null ? personalData.about.length + "/250 " + errorMessagesForPersonalData.about : null}
                       error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.about} variant="outlined" multiline minRows={4}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 250}}}/>


            {renderAIButtons()}


            {cvAppConfigCvData.personal.links.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <LinkSharp fontSize="small"
                                   style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noLinkAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.personal.links.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                            fontStyle: "italic"
                        }}>
                            <LinkSharp fontSize="small"
                                       style={{marginRight: 5}}/>
                            {value}
                        </Box>
                    </Box>
                )))}
            <TextField
                // disabled={newCvOrder.status==="DRAFT2"}
                fullWidth id={"link"} InputProps={{sx: {borderRadius: 5}}} margin="normal"
                value={link}
                onChange={(e) => {
                    setLink(e.target.value)
                }}
                label={wordsWithLang.cvApp.cvAppForms.link} variant="outlined"
                style={{marginLeft: 8}}/>
            </div>
            <br/>
            <br/>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={6} className={cvOrderStatus?"disabledSection":""}>

                    <Button className={"addButton cleanButton"} size="small" style={{marginLeft: 8}}
                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                            onClick={() => {
                                setPersonalData({...personalData, links: []})
                                cvAppConfigCvData.personal.links = []
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                            }}>
                        {wordsWithLang.cvApp.cvAppForms.clean}
                    </Button>

                    <Button className={link === "" ? "addButton disabledButton" : "addButton"}
                            size="small" style={{marginLeft: 8}}
                            startIcon={<Add fontSize={"medium"} style={{color: "white"}}/>}
                            onClick={() => {
                                if (link !== "") {
                                    let temp = personalData
                                    temp.links = [...personalData.links, link]
                                    cvAppConfigCvData.personal = temp
                                    dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                    setPersonalData(temp)
                                    setLink("")
                                }
                            }}>
                        {wordsWithLang.cvApp.cvAppForms.addLink}
                    </Button>


                </Grid>
                <Grid item xs={12} md={4} lg={1}>
                </Grid>

                <Grid item xs={12} md={8} lg={5}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                        className="addButton continueButton animate__animated animate__slow animate__pulse animate__infinite"
                        size="small"
                        style={{width: "100%", float: "right"}}
                        onClick={() => {
                            if (personalInfoValidate(true, personalData)) {
                                // dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                cvAppConfigCvData.personal = personalData
                                updateCvDataInServer("personal", cvAppConfigCvData)
                                setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                            } else {
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        "isSnackOpen": true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                                    }
                                })
                            }
                        }
                        }
                    >
                        {wordsWithLang.cvApp.cvAppForms.continue}
                    </Button>
                </Grid>
            </Grid>


            <br/>
            <br/>

        </div>
    );
};

export default PersonalAccordion;
