import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as staticVars from "../../utils/staticVars";

const CvAppFAQ = ({}) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const accordionItemsAz = [
        {
            title: 'BU SAYT NƏ ÜÇÜNDÜR?',
            content: '<p>Bu sayt sizə peşəkar CV yaratmağa kömək edir. Bunun üçün zəruri məlumatlarınızı daxil edir və şablon forma seçirsiniz. İşə qəbul imkanlarınızı artıracaq yüksək keyfiyyətli CV-ə sahib olursunuz.</p>'
        },
        {
            title: 'CV NECƏ YARADILIR?',
            content: '<p>CV yaratmaq üçün sadəcə aşağıdakı addımları izləyin:</p>' +
                '<ul>' +
                '<li>Qeydiyyatdan keçin və ya hesabınıza daxil olun;</li>' +
                '<li>Məlumatlarınızı daxil edin: şəxsi məlumatlar, iş təcrübəsi, təhsil, bacarıqlar və s.;</li>' +
                '<li>Özünüzə uyğun olan şablonu seçin;</li>' +
                '<li>Hissələri, göstəriciləri və rəngləri istəyinizə uyğun düzəldin;</li>' +
                '<li>Yüklə düyməsini klik edərək CV-nizi PDF formatında əldə edin.</li>' +
                '</ul>'
        },
        {
            title: 'CV DİZAYNI NECƏ SEÇİLİR?',
            content: '<p>Saytımızda müxtəlif şablon dizaynları mövcuddur. CV-nizi yaradarkən istədiyiniz dizaynı seçə bilərsiniz. Hər bir dizayn müasir, peşəkar və işəgötürənlərin diqqətini cəlb edəcək şəkildə hazırlanmışdır.</p>'
        },
        {
            title: 'CV -Nİ HANSI FORMATDA YÜKLƏYƏ BİLƏRƏM?',
            content: '<p>Ümumilikdə işə götürənlərə CV-lərin PDF fomatda göndərilməsi daha məqsədəuyğundur. Buna uyğun olaraq saytımızdan CV-nizi yükləmə imkanını PDF formatında sizə təqdim edirik. Bu format həm dizaynın qorunmasını, həm də peşəkar təqdimatınızı təmin edir.</p>'
        },
        {
            title: 'CV - NI HANSI DİLDƏ DÜZƏLDƏ BİLƏRƏM?',
            content: '<p>CV-nizi azərbaycan, rus və ingilis dillərində yarada bilərsiniz. Saytımız qeyd olunan hər 3 dildə CV yaratmağa imkan verir. Bu, sizin daha geniş bir auditoriyaya xitab etməyinizə və beynəlxalq iş bazarında daha yaxşı mövqe tutmağınıza kömək edəcək.</p>'
        },
        {
            title: 'CV YARADAN ZAMAN TEXNİKİ ÇƏTİNLİKLƏRLƏ QARŞILAŞDIQDA NƏ ETMƏLİYƏM?',
            content: '<p>Əgər CV yaradarkən hər hansı bir texniki problemlə qarşılaşırsınızsa, bizimlə əlaqə saxlamaq üçün aşağıdakı əlaqə vasitələrimiz mövcuddur:</p>' +
                '<ul>' +
                '<li>Mobil nömrə: (055) 5800898</li>' +
                '<li>E-mail: info@bakujobs.az</li>' +
                '</ul>' +
                '<p>Sizə ən qısa zamanda dəstək olacağıq.</p>'
        },
    ];
    const accordionItemsEn = [
        {
            title: 'WHAT IS THIS SITE FOR?',
            content: '<p>This site helps you create a professional CV. You can enter your necessary information and choose a template form. You will have a high-quality CV that will increase your chances of getting hired.</p>'
        },
        {
            title: 'HOW TO CREATE A CV?',
            content: '<p>To create a CV, simply follow the steps below:</p>' +
                '<ul>' +
                '<li>Register or log in to your account;</li>' +
                '<li>Enter your information: personal details, work experience, education, skills, etc.;</li>' +
                '<li>Choose a template that suits you;</li>' +
                '<li>Customize sections, indicators, and colors according to your preferences;</li>' +
                '<li>Click the download button to get your CV in PDF format.</li>' +
                '</ul>'
        },
        {
            title: 'HOW TO CHOOSE A CV DESIGN?',
            content: '<p>Our site offers various template designs. When creating your CV, you can choose the design you like. Each design is modern, professional, and tailored to attract the attention of employers.</p>'
        },
        {
            title: 'IN WHICH FORMAT CAN I DOWNLOAD MY CV?',
            content: '<p>In general, it is more appropriate to send CVs to employers in PDF format. Accordingly, our site allows you to download your CV in PDF format. This format ensures both the preservation of the design and a professional presentation.</p>'
        },
        {
            title: 'IN WHICH LANGUAGES CAN I CREATE MY CV?',
            content: '<p>You can create your CV in Azerbaijani, Russian, and English. Our site allows you to create a CV in all 3 languages. This will help you reach a wider audience and secure a better position in the international job market.</p>'
        },
        {
            title: 'WHAT SHOULD I DO IF I ENCOUNTER TECHNICAL ISSUES WHILE CREATING A CV?',
            content: '<p>If you encounter any technical issues while creating your CV, you can contact us using the following methods:</p>' +
                '<ul>' +
                '<li>Phone number: (055) 5800898</li>' +
                '<li>Email: info@bakujobs.az</li>' +
                '</ul>' +
                '<p>We will assist you as soon as possible.</p>'
        },
    ];
    const accordionItemsRu = [
        {
            title: 'ДЛЯ ЧЕГО ЭТОТ САЙТ?',
            content: '<p>Этот сайт помогает вам создать профессиональное резюме. Вы можете ввести необходимую информацию и выбрать шаблон. Вы получите качественное резюме, которое повысит ваши шансы на трудоустройство.</p>'
        },
        {
            title: 'КАК СОЗДАТЬ РЕЗЮМЕ?',
            content: '<p>Чтобы создать резюме, просто следуйте следующим шагам:</p>' +
                '<ul>' +
                '<li>Зарегистрируйтесь или войдите в свой аккаунт;</li>' +
                '<li>Введите свои данные: личная информация, опыт работы, образование, навыки и т.д.;</li>' +
                '<li>Выберите подходящий шаблон;</li>' +
                '<li>Настройте разделы, показатели и цвета по своему усмотрению;</li>' +
                '<li>Нажмите кнопку загрузки, чтобы получить резюме в формате PDF.</li>' +
                '</ul>'
        },
        {
            title: 'КАК ВЫБРАТЬ ДИЗАЙН РЕЗЮМЕ?',
            content: '<p>На нашем сайте представлены различные шаблоны дизайнов. При создании резюме вы можете выбрать понравившийся дизайн. Каждый дизайн современный, профессиональный и разработан для привлечения внимания работодателей.</p>'
        },
        {
            title: 'В КАКОМ ФОРМАТЕ Я МОГУ СКАЧАТЬ РЕЗЮМЕ?',
            content: '<p>Как правило, отправлять резюме работодателям предпочтительнее в формате PDF. Соответственно, наш сайт позволяет скачать резюме в формате PDF. Этот формат обеспечивает сохранение дизайна и профессиональное представление.</p>'
        },
        {
            title: 'НА КАКИХ ЯЗЫКАХ Я МОГУ СОЗДАТЬ РЕЗЮМЕ?',
            content: '<p>Вы можете создать резюме на азербайджанском, русском и английском языках. Наш сайт позволяет создать резюме на всех 3 языках. Это поможет вам охватить более широкую аудиторию и занять лучшую позицию на международном рынке труда.</p>'
        },
        {
            title: 'ЧТО ДЕЛАТЬ, ЕСЛИ Я СТОЛКНУСЬ С ТЕХНИЧЕСКИМИ ПРОБЛЕМАМИ ПРИ СОЗДАНИИ РЕЗЮМЕ?',
            content: '<p>Если у вас возникнут технические проблемы при создании резюме, вы можете связаться с нами следующими способами:</p>' +
                '<ul>' +
                '<li>Телефон: (055) 5800898</li>' +
                '<li>Электронная почта: info@bakujobs.az</li>' +
                '</ul>' +
                '<p>Мы поможем вам в кратчайшие сроки.</p>'
        },
    ];
    const [accordionItems, setAccordionItems] = useState(accordionItemsAz);

    useEffect(() => {
        switch (window.localStorage.getItem("pageLang")){
            case "az":
                setAccordionItems(accordionItemsAz);
                break;
            case "ru":
                setAccordionItems(accordionItemsRu);
                break;

            case "en":
                setAccordionItems(accordionItemsEn);
                break;
            default:
                break;

        }
    }, []);




    return (
        <Box sx={{ width: '100%', maxWidth: 700, margin: '0 auto', mb: 4 }}>
            {accordionItems.map((item, index) => {
                const panel = `panel${index + 1}`;
                return (
                    <Accordion
                        key={panel}
                        expanded={expanded === panel}
                        onChange={handleChange(panel)}
                        style={{marginTop: '5px', marginBottom: '10px'}}
                        sx={{
                            '&.Mui-expanded': {
                                margin: 0,
                                // marginTop: '5px',
                                // marginBottom: '5px',
                                boxShadow: 3,
                                borderRadius: 5,
                                backgroundColor: staticVars.colors.bgPurple,
                            },
                            '&:not(.Mui-expanded)': {
                                marginBottom: '1px',
                                borderRadius: 5,
                                boxShadow: 1,
                            },
                            '&:hover': {
                                boxShadow: 4,
                            },
                            '::before':{
                                backgroundColor:"transparent",
                            },
                            '& .MuiAccordionSummary-content': { // Target the inner content of AccordionSummary
                                margin: 0, // Set margin to 0
                            },
                            '& .Mui-expanded': {
                                paddingLeft: "8px",
                                paddingTop:"5px",
                                paddingBottom:"5px",
                                minHeight: '48px !important',

                                // marginTop: '5px',
                                // marginBottom: '5px',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: staticVars.colors.darkPurple }} />}
                            aria-controls={`${panel}-content`}
                            id={`${panel}-header`}
                            sx={{
                                backgroundColor: staticVars.colors.bgPurple,
                                borderRadius: 5,
                                '&:hover': {
                                    backgroundColor: staticVars.colors.lightPurple,
                                    '& .MuiTypography-root': { // Target the Typography when AccordionSummary is hovered
                                        color: 'white !important',
                                    },
                                },
                                '& .Mui-expanded': {
                                    margin: "0 !important",
                                    minHeight: '0 !important',

                                    // marginTop: '5px',
                                    // marginBottom: '5px',
                                },
                            }}
                        >
                            <Typography
                                variant="button"
                                sx={{
                                    color: staticVars.colors.darkPurple,
                                    fontWeight: 'bold',
                                }}
                            >
                                {item.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: staticVars.colors.bgPurple,
                                borderRadius: 5,
                                // padding: 3,
                            }}
                        >
                            <Typography dangerouslySetInnerHTML={{ __html: item.content }} variant="body2" sx={{ color: staticVars.colors.darkPurple }}>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

export default CvAppFAQ;
