import React from 'react';
import {ExpandMore} from "@mui/icons-material";
import {IconButton} from "@mui/material";

const AccordionIconButton = () => {
    return (
        <IconButton
            color="violet"
            size="medium"
            sx={{
                margin:1.5,
                border: '1px solid', // Outlined style
                borderColor: 'violet.main', // Primary color outline
                borderRadius: '50%', // Circular shape
                width: 42, // Fixed size circle
                height: 42,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'all 0.3s',
                '&:hover': {
                    backgroundColor: 'violet.main',
                    color: 'white',
                    boxShadow: 3
                }
            }}
        >
            <ExpandMore />
        </IconButton>
    );
};

export default AccordionIconButton;
