import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import apiUrls from "../../apiUrls";
import Slide from '@mui/material/Slide';

import {
    Alert,
    Button,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import CvAppFAQ from "./cvAppFAQ";
import CVLanguageSelection from "./cVLanguageSelection";
import {AutoAwesome, Info, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import TemplatesCarousel from "./TemplatesCarousel";


const CvAppMainCreateNewCV = ({updateCvOrder, wordsWithLang}) => {
    const dispatcher = useDispatch();
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const userConfig = useSelector(state => state.userConfig)
    const navigate = useNavigate()
    const [selectedLanguage, setSelectedLanguage] = useState('az');
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const createNewCv = () => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostNewCvOrder, {language: selectedLanguage},
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    updateCvOrder()
                    dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
                    navigate({pathname: '/cv', hash: "edit"})

                }
            )
        }
    }

    const selectLanguage = (lang) => {
        setSelectedLanguage(lang)
        let temp = newCvOrder
        temp.language = selectedLanguage
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})
    };

    const [isFAQVisible, setFAQVisible] = useState(false);

    useEffect(() => {
        dispatcher({type: "CV_DATA_UPDATE", payload: {}})
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: {}})
    }, []);

    // const closeSnack = () => {
    //   return;
    // }


    return (
        // <CVLanguageSelection wordsWithLang={wordsWithLang} selectLanguage={selectLanguage} isMobile={isMobile} newCvOrder={newCvOrder} createNewCv={createNewCv}/>

        <Grid container justifyContent={"center"} style={{marginBottom: 50}}>
            <Grid item sm={11} xs={11}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginLeft: 30,
                        marginBottom: 10,
                        marginTop: 10,
                    }}
                    onClick={() => {
                        setFAQVisible(!isFAQVisible)
                    }}>
                    <Typography
                        variant={"caption"}
                        className={"violetColor"}
                        fontSize={20}
                        align={"left"}
                        style={{
                            fontWeight: "600",
                            marginRight: 10, // Add spacing between text and icon
                        }}
                    >
                        <span style={{fontSize: "1.5rem"}}>📖 </span>
                        {wordsWithLang.cvApp.faq}
                    </Typography>
                    <IconButton size="medium" color="violet" onClick={() => {
                        setFAQVisible(!isFAQVisible)
                    }}>
                        {isFAQVisible ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </div>


                <Collapse in={isFAQVisible}>
                    <CvAppFAQ/>
                </Collapse>
            </Grid>
            <Grid item sm={11} xs={11} gap={1}>
                <Grid item xs={12}>
                    <Divider variant="middle" size="large"
                             style={{margin: 20, marginTop: 20, fontSize: 15, border: ".5px solid rgb(212 212 212)"}}/>
                </Grid>
                <Typography variant={"caption"} className={"violetColor"} fontSize={20} align={"left"} display={"block"}
                            style={{
                                // width: "100%",
                                marginLeft: 30,
                                fontWeight: "600",
                                marginBottom: 10
                                // }}>{wordsWithLang.cvApp.makeNewCV}</Typography>
                            }}>
                    <span style={{fontSize: "1.5rem"}}>🌎 </span>
                    {wordsWithLang.cvApp.selectLanguage}</Typography>
                <CVLanguageSelection selectedLanguage={selectedLanguage} wordsWithLang={wordsWithLang} selectLanguage={selectLanguage}
                                     newCvOrder={newCvOrder} createNewCv={createNewCv}/>
            </Grid>
            <Grid item xs={9} maxWidth={"fit-content"} justifyContent={"center"}>
                <Snackbar
                    sx={{width: "96vw", maxWidth:"800px"}}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    open={["ru", "en"].includes(selectedLanguage)}
                    TransitionComponent={Slide}
                    TransitionProps={{direction: "up"}}
                    // onClose={closeSnack}
                    // message={wordsWithLang.cvApp.warningForLang}
                    key={"warningForLang"}
                    autoHideDuration={10000}
                >
                    <Alert
                        severity="warning"
                        variant="filled"
                        sx={{display: "flex", alignItems: "center", width: '100%', borderRadius:"1REM"}}
                    >
                        {wordsWithLang.cvApp.warningForLang}
                    </Alert>

                </Snackbar>
                {/*<Chip fontSize={isMobile ? "1rem" : "0.6rem"} variant={"outlined"} color={"warning"} icon={<Info/>}*/}
                {/*      label={wordsWithLang.cvApp.warningForLang} laguage={newCvOrder.language}*/}
                {/*    style={{*/}
                {/*        width: "90vw",*/}
                {/*        transition: "height 0.3s ease-in-out, visibility 0.3s ease-in-out",*/}
                {/*        display: "flex",*/}
                {/*        textWrap: "auto",*/}
                {/*        // flexWrap: "wrap",*/}
                {/*        overflow: "hidden",*/}
                {/*        visibility: ["ru", "en"].includes(newCvOrder.language) ? "visible" : "hidden",*/}
                {/*        height: ["ru", "en"].includes(newCvOrder.language) ? "revert-layer" : "0px",*/}
                {/*        margin: 20,*/}
                {/*        position: "relative",*/}
                {/*        marginLeft: "1vw",*/}
                {/*        marginTop: "20px"}}*/}
                {/*    // style={styleShowWarningLang(newCvOrder.language)}*/}
                {/*    //   className={`flex overflow-hidden relative m-5 ml-[1vw] mt-5 transition-[height,visibility] duration-300 ease-in-out ${["ru", "en"].includes(newCvOrder.language) ? "visible" : "invisible"} ${["ru", "en"].includes(newCvOrder.language) ? "h-auto" : "h-0"}`}*/}
                {/*    sx={{'& .MuiChip-label': {textWrap: "auto"}}}*/}
                {/*/>*/}
            </Grid>
            <Grid item xs={11} lg={4} md={6}>
                <Button variant={"contained"}
                        fullWidth
                        className={"btn"}
                        style={{height: 50, marginTop: 25}}
                        startIcon={<AutoAwesome fontSize="medium" style={{color: 'white'}}/>}
                        onClick={() => {
                            createNewCv()
                        }}
                >
                    <Typography justifyContent={"center"} fontWeight={600} fontSize={20}>{wordsWithLang.cvApp.start}</Typography>
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Divider variant="middle" size="large"
                         style={{margin: 20, marginTop: 50, fontSize: 15, border: ".5px solid rgb(212 212 212)"}}/>
            </Grid>
        </Grid>

    )
}


// const styleShowWarningLang = (language) => {
//     return {
//         transition: "height 0.3s ease-in-out, visibility 0.3s ease-in-out",
//         display: "flex",
//         overflow: "hidden",
//         visibility: ["ru", "en"].includes(language) ? "visible" : "hidden",
//         height: ["ru", "en"].includes(language) ? "revert-layer" : "0px",
//         margin: 20,
//         position: "relative",
//         marginLeft: "1vw",
//         marginTop: "20px"
//     }
// }


export default CvAppMainCreateNewCV
