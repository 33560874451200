import React, {useState} from 'react';
import {Grid, Tab, Tabs} from "@mui/material";
import AdminCvOrdersTab from "../tabs/AdminCvOrdersTab";
import AdminHtmlEditor from "../tabs/AdminHtmlEditor";
import AdminFeedbackTab from "../tabs/AdminFeedbackTab";

const AdminReports = () => {
    const [tabVal, setTabVal] = useState("one")
    return (
        <div>
            <Grid container>
                <Grid item xs={12} mt={0} mb={1}>
                    <Tabs
                        value={tabVal}
                        onChange={(e, val) => {setTabVal(val);}}
                    >
                        <Tab value="one" label="FEEDBACKS"/>
                        <Tab value="two" label="NONE"/>
                        <Tab value="three" label="OTHER"/>
                    </Tabs>
                </Grid>

                <Grid container display={tabVal === "one" ? "grid" : "none"}>
                    <AdminFeedbackTab/>
                    {/*<AdminCvOrdersTab setControl={setControl} control={control} wordsWithLang={wordsWithLang} />*/}
                </Grid>
                <Grid container display={tabVal === "two" ? "grid" : "none"}>
                    {/*<AdminCvUserTab control={control} setControl={setControl} />*/}
                    {/*{<AdminHtmlEditor/>}*/}
                </Grid>
            </Grid>

        </div>
    );
};

export default AdminReports;
