import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Avatar,
    Badge,
    Button,
    Grid,
    ListItemAvatar,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    AccountCircle, AutoAwesome,
    ElectricalServices,
    Email,
    Favorite,
    Info,
    Language,
    Logout,
    Person, SearchSharp, SupervisedUserCircle,
    VerifiedUser,
    Work
} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import MiniContact from "../miniComponents/miniContact";
import InfoDialog from "../miniComponents/InfoDialog";
import Cookies from "js-cookies/src/cookies";
import Animate from 'animate.css-react'
import 'animate.css'
import MenuWithIcons from "./menuWithIcons";
import VioletOcean from "../miniComponents/VioletOcean";

const NavigationBar = ({wordsWithLang}) => {
    const isTablet = useMediaQuery('(max-width:950px)');
    // const isMobile = useMediaQuery('(max-width:480px)');
    const [openBurger, setOpenBurger] = useState(false)
    const [anchorElLang, setAnchorElLang] = useState(null);
    const [anchorElInfo, setAnchorElInfo] = useState(null);
    const navigate = useNavigate();



    const mainConfig = useSelector(state => state.mainConfig)
    const userConfig = useSelector(state => state.userConfig)
    const dispatcher = useDispatch()

    const location = useLocation();
    const palette = useTheme().palette;


    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const avatarMenuClick = (event) => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const avatarMenuClose = () => {
        setAnchorEl(null);
    };
    const [openLogOut, setOpenLogOut] = useState(false)

    const toggleBurger = () => {
        setOpenBurger(!openBurger)
        // setAnchorEl(null)
        // setAnchorElInfo(null)
        // setNavbarMobile({...navbarMobile, display: openBurger});
    }

    const logOut = () => {
        dispatcher({type: "CHANGE_LOGIN", payload: false})
        dispatcher({type: "SET_USER", payload: {}})
        setOpenLogOut(true)
        setAnchorEl(null);
        axios.post(apiUrls.baseApiUrl + apiUrls.logout, userConfig, {withCredentials: "include"}).then(resp => {
            if (resp.data) {
                dispatcher({type: "SET_USER", payload: null})
                dispatcher({type: "CHANGE_LOGIN", payload: false})
                dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})

                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";
                window.location.reload();
            }

        })



    }

    const handleClickLang = (event) => {
        anchorElLang ? setAnchorElLang(null) : setAnchorElLang(event.currentTarget);
    };
    const handleClickInfo = (event) => {
        anchorElInfo ? setAnchorElInfo(null) : setAnchorElInfo(event.currentTarget);
    };

    const handleCloseLang = () => {
        setAnchorElLang(null);
    };

    const handleCloseInfo = () => {
        setAnchorElInfo(null);
    };
    const handleLanguageChange = (lang) => {
        window.localStorage.setItem("pageLang", lang)
        handleCloseLang();
        window.location.reload();
    };


    const checkAuthAndNavigateTo = (path) => {
        toggleBurger()

        if (userConfig.isLogin) {
            navigate(path)
        } else {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
    }

    const vacancyHeader = (wordsWithLang) => {
        return (<MiniContact viewType={"onlyLinks"} wordsWithLang={wordsWithLang}/>)
    }

    const [navbar, setNavbar] = useState({
        height: "100px",
        // backgroundColor: "blue",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "1256",
        position: "absolute",
        top: "0",


    })
    const [navbarMenu, setNavbarMenu] = useState({
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        // backgroundColor: "#f4f1ff",
        // backgroundColor: "rgba(255, 255, 255, 1)",
        // -webkit-backdrop-filter: blur(10px),
        WebkitBackdropFilter: 'blur(10px)',
        backdropFilter: "blur(25px)",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "1256",
        position: "fixed",
        top: "-100%",
        width: "100%",
        transition: "all 0.3s ease-in-out",
    })
    const [navbarDesktop, setNavbarDesktop] = useState({
        marginTop: "0px",
        height: "100px",
        // backgroundColor: "blue",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "1256",
        position: "absolute",
        top: "0",

    })
    const [closeButtonStyle, setCloseButtonStyle] = useState({
        borderRadius: "100%",
        border: "1px solid",
        marginRight: "1rem"
    })

    useEffect(() => {
        setNavbarMenu({...navbarMenu, top: openBurger ? "0" : "-100%"});

    }, [openBurger])

    useEffect(() => {
        setNavbarMenu({...navbarMenu, display: isTablet ? "flex" : "none"});
        setNavbar({...navbar, display: isTablet ? "flex" : "none"});
        setNavbarDesktop({...navbarDesktop, display: !isTablet ? "flex" : "none"});
    }, [isTablet])

    useEffect(() => {
        if (openBurger) {
            document.body.classList.add("cs-no-scroll");
        } else {
            document.body.classList.remove("cs-no-scroll");
        }
    }, [openBurger]);

    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <Grid container style={navbarDesktop} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={3} display={"flex"} alignContent={"center"}
                      justifyContent={"flex-start"}>
                    {/*<Grid item xs={6} md={9} xl={9} lg={9}>*/}
                    {/*    <Grid container justifyContent={"center"} alignItems={"flex-start"}>*/}
                    {/*<PullToRefresh onRefresh={()=>{window.location.reload()}} pullingContent={"Yeniləmək üçün çəkin..."}>*/}

                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} onClick={() => {
                            navigate("/");
                            window.location.reload();
                        }} className={"bigLogo"}/>
                    </Grid>
                    <VioletOcean/>
                    {/*</PullToRefresh>*/}

                </Grid>
                {/*</Grid>*/}
                <Grid item xs={6} display={"flex"} justifyContent={"center"} alignSelf={"center"}>
                    {/*new appbar*/}
                    {/*<MenuWithIcons/>*/}

                    <Tabs value={tabIndex} onChange={handleTabChange} sx={{top:"-50px"}} TabIndicatorProps={{
                        style: {display: 'none', flexDirection: "row"}, // Hide the bottom indicator line
                    }}>
                        {/*icon={<Work fontSize={"small"}*/}
                        {/*            icon={<ElectricalServices fontSize={"small"}*/}
                        {/*                                      icon={<Info fontSize={"small"}*/}
                        {/*<Tab sx={{'&.MuiTab-root': {borderBottom: 'none'}}}*/}
                        {/*     icon={<Work className={"animate__animated hoverAnimateSwing"} fontSize={"small"}/>}*/}
                        {/*     className={`headerTab ${tabIndex === 0 ? "selected" : ""}`}*/}
                        {/*     label={wordsWithLang.header.vacancies}*/}
                        {/*     onClick={() => {*/}
                        {/*         navigate("/")*/}
                        {/*     }}/>*/}
                        {/*<Tab  sx={{ '&.MuiTab-root': { borderBottom: 'none' } }} className={"headerTab"} icon={<AutoAwesome fontSize={"small"}/>} label={wordsWithLang.cvApp.createCv}*/}
                        {/*     onClick={() => {*/}
                        {/*         navigate("cv")*/}
                        {/*     }}/>*/}
                        {/*<Tab sx={{'&.MuiTab-root': {borderBottom: 'none'}}}*/}
                        {/*     icon={<ElectricalServices className={"animate__animated hoverAnimateSwing"}*/}
                        {/*                               fontSize={"small"}/>}*/}
                        {/*     className={`headerTab ${tabIndex === 0 ? "selected" : ""}`}*/}
                        {/*     label={wordsWithLang.header.services}*/}
                        {/*     onClick={() => {*/}
                        {/*         navigate("services")*/}
                        {/*     }}/>*/}
                        {/*<Tab sx={{'&.MuiTab-root': {borderBottom: 'none'}}}*/}
                        {/*     icon={<Info className={"animate__animated hoverAnimateSwing"} fontSize={"small"}/>}*/}
                        {/*     className={`headerTab ${tabIndex === 0 ? "selected" : ""}`}*/}
                        {/*     label={wordsWithLang.header.aboutUs}*/}
                        {/*     onClick={() => {*/}
                        {/*         navigate("aboutUs")*/}
                        {/*     }}/>*/}


                    </Tabs>

                </Grid>
                <Grid item xs={3} justifyContent={"flex-end"} display={"flex"} alignContent={"center"}>

                    {/*<IconButton onClick={handleClickLang}>*/}
                    {/*    <Badge overlap="rectangular" style={{padding: "0px 5px"}} color="secondary" title="12">*/}
                    {/*        <SearchSharp id={"test3"} style={{fontSize: "2rem"}}/>*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}

                    {/*<Menu*/}
                    {/*    anchorEl={anchorElLang}*/}
                    {/*    open={anchorElLang !== null}*/}
                    {/*    onClose={handleCloseLang}*/}
                    {/*>*/}
                    {/*    <MenuItem onClick={() => handleLanguageChange('az')}>*/}
                    {/*        <Box component="img"*/}
                    {/*             src={"https://flagcdn.com/az.svg"}*/}
                    {/*             sx={{width: 30, height: 20}}*/}
                    {/*        />*/}
                    {/*    </MenuItem>*/}
                    {/*    <MenuItem onClick={() => handleLanguageChange('en')}>*/}
                    {/*        <Box component="img"*/}
                    {/*             src={"https://flagcdn.com/gb.svg"}*/}
                    {/*             sx={{width: 30, height: 20}}*/}
                    {/*        />*/}
                    {/*    </MenuItem>*/}
                    {/*    <MenuItem onClick={() => handleLanguageChange('ru')}>*/}
                    {/*        <Box component="img"*/}
                    {/*             src={"https://flagcdn.com/ru.svg"}*/}
                    {/*             sx={{width: 30, height: 20}}*/}
                    {/*        />*/}
                    {/*    </MenuItem>*/}
                    {/*    /!* Add more languages as needed *!/*/}
                    {/*</Menu>*/}

                    <IconButton onClick={handleClickInfo}>
                        <Badge overlap="rectangular" style={{padding: "0px 5px"}} color="secondary" title="12">
                            <Info id={"test3"} style={{fontSize: "2rem"}}/>
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorElInfo}
                        open={anchorElInfo !== null && !isTablet}
                        onClose={handleCloseInfo}
                    >
                        <MenuItem onClick={() => {
                            // toggleBurger();
                            handleCloseInfo();
                            navigate("/aboutUs")
                        }}>
                            <Info fontSize={"medium"} style={{marginRight: ".4rem"}}/>
                            {wordsWithLang.header.aboutUs}
                        </MenuItem>

                        <MenuItem onClick={() => {
                            // toggleBurger();
                            handleCloseInfo();
                            navigate("/services")
                        }}>
                            <ElectricalServices fontSize={"medium"} style={{marginRight: ".4rem"}}/>
                            {wordsWithLang.header.services}
                        </MenuItem>

                        {/* Add more languages as needed */}
                    </Menu>


                    <IconButton onClick={handleClickLang}>
                        <Badge overlap="rectangular" style={{padding: "0px 5px"}} color="secondary" title="12">
                            <Language id={"test3"} style={{fontSize: "2rem"}}
                                      titleAccess={wordsWithLang.header.changeLang}/>
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorElLang}
                        open={anchorElLang !== null}
                        onClose={handleCloseLang}
                    >
                        <MenuItem onClick={() => handleLanguageChange('az')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/az.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('en')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/gb.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('ru')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/ru.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        {/* Add more languages as needed */}
                    </Menu>


                    {/*<IconButton onClick={() => {*/}
                    {/*    // toggleBurger()*/}
                    {/*    checkAuthAndNavigateTo("/favorites")*/}
                    {/*}}>*/}
                    {/*    <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}*/}
                    {/*           overlap="rectangular" color={"secondary"} title={"12"}>*/}
                    {/*        <Favorite style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                    {/*                  titleAccess={wordsWithLang.header.likes}/>*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    {/*<IconButton onClick={() => {*/}
                    {/*    // toggleBurger()*/}
                    {/*    checkAuthAndNavigateTo("/subscriptions")*/}
                    {/*}}>*/}
                    {/*    <Badge overlap="rectangular" color={"secondary"}*/}
                    {/*           title={"12"}>*/}
                    {/*        <Email style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                    {/*               titleAccess={wordsWithLang.header.subscription}/>*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <IconButton
                        style={{marginRight: "1rem"}}
                        id="avatar-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={avatarMenuClick}
                    >
                        <Avatar
                            style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>
                            <Person/>
                        </Avatar>
                    </IconButton>
                    <Menu
                        id="avatar-menu"
                        anchorEl={anchorEl}
                        open={open && !isTablet}
                        onClose={avatarMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{borderRadius: "15px"}}
                    >
                        <MenuItem style={{display: "flex",flexDirection: "column", alignItems:"flex-start"}}>
                            <Typography display={"flex"}><AccountCircle sx={{mr:1}}/> Hesabim</Typography>
                            <Typography fontSize={"0.8rem"} marginLeft={"33px"}>{userConfig.email}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            avatarMenuClose()
                            checkAuthAndNavigateTo("/favorites")
                        }} >
                            {/*<Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}*/}
                            {/*       overlap="rectangular" color={"secondary"} title={"12"}>*/}
                            {/*    <Favorite style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                            {/*              titleAccess={wordsWithLang.header.likes}/>*/}
                            {/*</Badge>*/}
                            <Favorite style={{marginRight:10}}/>{wordsWithLang.header.likes}
                        </MenuItem>



                        <MenuItem onClick={() => {
                            avatarMenuClose()

                            checkAuthAndNavigateTo("/subscriptions")
                        }}>
                            {/*<Badge overlap="rectangular" color={"secondary"}*/}
                            {/*       title={"12"}>*/}
                            {/*    style={{fontSize: "2rem", padding: "0px 5px"}}/>*/}
                            {/*</Badge>*/}

                            <Email style={{marginRight:10}} />{wordsWithLang.header.subscription}
                        </MenuItem>

                        <MenuItem onClick={logOut}><Logout style={{marginRight:10}}/>{wordsWithLang.header.logout}</MenuItem>
                    </Menu>
                </Grid>
            </Grid>


            <Grid container style={navbar} alignItems={"center"}>
                <Grid item xs={7} marginRight={"auto"} minWidth={"1rem"}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} sm={9} onClick={() => {
                            navigate("/");
                            window.location.reload();
                        }} className={"bigLogo-m"}/>
                    </Grid>
                    <VioletOcean/>

                </Grid>

                <Grid item display={"flex"} minWidth={"3rem"} justifyContent={"center"} xs={1}>
                    <IconButton onClick={handleClickLang}>
                        <Badge overlap="rectangular" color="secondary">
                            <Language style={{fontSize: "2rem", padding: "0px 0px"}}
                                      titleAccess={wordsWithLang.header.changeLang}/>
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorElLang}
                        open={Boolean(anchorElLang)}
                        onClose={handleCloseLang}
                    >
                        <MenuItem onClick={() => handleLanguageChange('az')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/az.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('en')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/gb.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('ru')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/ru.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        {/* Add more languages as needed */}
                    </Menu>
                </Grid>
                <Grid item display={"flex"} minWidth={"3rem"} justifyContent={"center"} xs={1}>
                    <IconButton
                        id="avatar-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={avatarMenuClick}
                    >
                        <Avatar
                            style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>
                            <Person/>
                        </Avatar>
                    </IconButton>
                    <Menu
                        id="avatar-menu"
                        anchorEl={anchorEl}
                        open={open && isTablet}
                        onClose={avatarMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{borderRadius: "15px"}}
                    >
                            <MenuItem style={{display: "flex",flexDirection: "column", alignItems:"flex-start"}}>
                                <Typography display={"flex"}><AccountCircle sx={{mr:1}}/> Hesabim</Typography>
                                <Typography fontSize={"0.8rem"} marginLeft={"33px"}>{userConfig.email}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseInfo();
                                checkAuthAndNavigateTo("/favorites")
                            }} >
                                {/*<Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}*/}
                                {/*       overlap="rectangular" color={"secondary"} title={"12"}>*/}
                                {/*    <Favorite style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                                {/*              titleAccess={wordsWithLang.header.likes}/>*/}
                                {/*</Badge>*/}
                                <Favorite style={{marginRight:10}}/>{wordsWithLang.header.likes}
                            </MenuItem>



                            <MenuItem onClick={() => {
                                handleCloseInfo();
                                checkAuthAndNavigateTo("/subscriptions")
                            }}>
                                {/*<Badge overlap="rectangular" color={"secondary"}*/}
                                {/*       title={"12"}>*/}
                                {/*    style={{fontSize: "2rem", padding: "0px 5px"}}/>*/}
                                {/*</Badge>*/}

                                <Email style={{marginRight:10}} />{wordsWithLang.header.subscription}
                            </MenuItem>

                            <MenuItem onClick={logOut}><Logout style={{marginRight:10}}/>{wordsWithLang.header.logout}</MenuItem>
                        </Menu>

                </Grid>
                <Grid item display={"flex"} minWidth={"4rem"} justifyContent={"center"} xs={1}>
                    <IconButton onClick={toggleBurger} size={"large"}><MenuIcon fontSize={"large"}/></IconButton>
                </Grid>

            </Grid>

            <Grid container style={navbarMenu} paddingLeft={"2rem"} alignItems={"flex-start"} direction={"column"}>
                <Grid item xs={2} alignSelf={"flex-end"} alignContent={"center"}>
                    <IconButton style={closeButtonStyle} onClick={toggleBurger} size={"large"}><CloseIcon
                        sx={{fontSize: '3rem'}}/></IconButton>
                </Grid>

                <Grid item xs={1} onClick={() => {
                    toggleBurger();
                    navigate("/")
                }}>
                    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>
                        <Badge overlap="rectangular" color={"secondary"}
                               title={"12"}>
                            <Work style={{fontSize: "2rem"}}/>
                        </Badge>
                    </IconButton>
                    <Typography display={"inline-flex"} fontWeight={"500"}
                                fontSize={"30px"}>{wordsWithLang.header.vacancies.charAt(0) + wordsWithLang.header.vacancies.slice(1).replace("İ", "i").toLowerCase()}</Typography>
                </Grid>
                {/*<Grid item xs={1}></Grid>*/}

                {/*<Grid item xs={1} onClick={() => {*/}
                {/*    toggleBurger()*/}
                {/*    checkAuthAndNavigateTo("/favorites")*/}
                {/*}}>*/}
                {/*    <IconButton style={{marginBottom: "1rem"}}>*/}
                {/*        <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}*/}
                {/*               overlap="rectangular" color={"secondary"} title={"12"}>*/}
                {/*            <Favorite style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                {/*                      titleAccess={wordsWithLang.header.likes}/>*/}
                {/*        </Badge>*/}
                {/*    </IconButton>*/}
                {/*    <Typography display={"inline-flex"} fontWeight={"500"}*/}
                {/*                fontSize={"30px"}>{wordsWithLang.header.likes}</Typography>*/}

                {/*</Grid>*/}
                {/*<Grid item xs={1}*/}
                {/*      onClick={() => {*/}
                {/*          toggleBurger()*/}
                {/*          checkAuthAndNavigateTo("/subscriptions")*/}
                {/*      }}>*/}
                {/*    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>*/}
                {/*        <Badge overlap="rectangular" color={"secondary"}*/}
                {/*               title={"12"}>*/}
                {/*            <Email style={{fontSize: "2rem", padding: "0px 5px"}}*/}
                {/*                   titleAccess={wordsWithLang.header.subscription}/>*/}
                {/*        </Badge>*/}
                {/*    </IconButton>*/}
                {/*    <Typography display={"inline-flex"}*/}
                {/*                fontSize={"30px"} fontWeight={"500"}>{wordsWithLang.header.subscription}</Typography>*/}


                {/*</Grid>*/}


                <Grid item xs={1} onClick={()=>{
                    toggleBurger()
                    navigate("/cv")
                }}>
                    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>
                        <Badge overlap="rectangular" color={"secondary"}
                               title={"12"}>
                            <AutoAwesome style={{fontSize: "2rem"}}/>
                        </Badge>
                    </IconButton>
                    <Typography display={"inline-flex"}  fontWeight={"500"} fontSize={"30px"}>{wordsWithLang.cvApp.createCv}</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/*cv-ni duzel*/}
                </Grid>

                <Grid item xs={1}>
                    <Box onClick={() => {
                        toggleBurger();
                        navigate("/services")
                    }} display={"inline-flex"} style={{marginRight: "5rem", textDecoration: "underline"}}
                         fontSize={"1.2rem"}>
                        <ElectricalServices fontSize={"medium"} style={{marginRight: ".4rem"}}/>
                        {wordsWithLang.header.services}
                    </Box>
                    <Box onClick={() => {
                        toggleBurger();
                        navigate("/aboutUs")
                    }} display={"inline-flex"} style={{marginRight: "5rem", textDecoration: "underline"}}
                         fontSize={"1.2rem"}>
                        <Info fontSize={"medium"} style={{marginRight: ".4rem"}}/>
                        {wordsWithLang.header.aboutUs}
                    </Box>
                </Grid>


            </Grid>


            <InfoDialog open={openLogOut} onClose={() => {
                setOpenLogOut(false)
                window.location.reload();
            }} message={wordsWithLang.header.logouted}/>
        </>

    );
}

export default NavigationBar;
