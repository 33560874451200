import React, {useEffect} from 'react';
import {words} from "../words";
import {setDocDesc, setDocTitle} from "../utils/Utils";
import {Divider, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import DynamicForm from "../components/DynamicForm";

const FormPage = ({wordsWithLang}) => {
    const {id} = useParams();

    useEffect(() => {
        setDocTitle(wordsWithLang.ge);
        setDocDesc(words.content.aboutUs)
    }, [])
    return (
        <Grid container justifyContent={"center"} alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            {/*<Grid item xs={1}></Grid>*/}
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                {/*<MiniContact viewType={"onlyContact"} wordsWithLang={wordsWithLang} />*/}
                {/*{useMediaQuery(theme => theme.breakpoints.down("sm"))*/}
                {/*    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :*/}
                {/*    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}*/}
                {/*<Box*/}
                {/*    display="flex"*/}
                {/*    justifyContent="center"*/}
                {/*    alignItems="center"*/}
                {/*    height="12vh"  // Full viewport height for vertical centering*/}
                {/*>*/}
                <Typography variant={"caption"} color={"gray"} align={"center"} justifyContent={"center"} style={{ fontSize:"10px"}}>
                    {/*Öz formunuzu yaratmaq üçün sadəcə email göndərin. info@bakujobs.az*/}
                    👍 Öz formunuzu yaratmaq üçün sadəcə email göndərin <a href="mailto:info@bakujobs.az">info@bakujobs.az</a>. ✅
                </Typography>
                <br/>
                <br/>
                <Divider></Divider>
                <DynamicForm formId={id} wordsWithLang={wordsWithLang} />
                    <Typography variant={"caption"} color={"gray"} align={"center"} justifyContent={"center"} style={{ fontStyle:"italic", fontSize:"10px"}}>
                    Form ID: {id}
                    </Typography>
                <br/>
                <br/>
                <Divider></Divider>
                <Typography variant={"caption"} color={"gray"} align={"center"} justifyContent={"center"} style={{ fontSize:"10px"}}>
                    {/*Öz formunuzu yaratmaq üçün sadəcə email göndərin. info@bakujobs.az*/}
                    👍 Öz formunuzu yaratmaq üçün sadəcə email göndərin <a href="mailto:info@bakujobs.az">info@bakujobs.az</a>. ✅
                </Typography>
                <br/>
                <br/>
                <br/>
                {/*</Box>*/}
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}

        </Grid>


    );
};

export default FormPage;
