import axios from "axios";
import apiUrls from "../apiUrls";
import {words} from "../words";



export const apiGetUserInfo = (dispatcher) => {
    axios.get(apiUrls.baseApiUrl + apiUrls.getUserByToken,
        {withCredentials: true}).then(resp => {
            if (resp.data!==null && resp.data!=="") {
                dispatcher({type: "SET_USER", payload: resp.data})
                dispatcher({type: "CHANGE_LOGIN", payload: true})
                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";

            } else {
                let popUpShowCount = localStorage.getItem("showPopupCount")
                if (popUpShowCount === null) {
                    localStorage.setItem("showPopupCount", "0")
                    dispatcher({type:"SHOW_REK_POPUP", payload:true})
                } else {
                    localStorage.setItem("showPopupCount", parseInt(popUpShowCount)+1)
                    if (popUpShowCount !== "0" && popUpShowCount % 3 === 0) {
                        dispatcher({type:"SHOW_REK_POPUP", payload:true})
                    }
                }
            }
        }
    )
}




export const companyLogo = (vacancyItem) => {
    return apiUrls.baseApiUrl + apiUrls.getCompanyLogo
        .replace(":logoName",
            vacancyItem.company.miniLogo.split("/")[
            vacancyItem.company.miniLogo.split("/").length - 1
                ])
}

export const favoriteHandler = (id, dispatcher, userConfig) => {
    id = id.toString()
    if (!userConfig.isLogin) {
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
    } else {
        if (userConfig.favorites && userConfig.favorites.includes(id)) {
            axios.get(apiUrls.baseApiUrl + apiUrls.removeFromFavorite + id, {withCredentials: true}).then((resp) => {
                if (resp.data) {
                    dispatcher({type: "ADD_TO_FAVORITES", payload: id})
                }
            })
        } else {
            if (userConfig.favorites && !userConfig.favorites.includes(id)) {
                axios.get(apiUrls.baseApiUrl + apiUrls.addToFavorite + id, {withCredentials: true}).then((resp) => {
                    if (resp.data) {
                        dispatcher({type: "ADD_TO_FAVORITES", payload: id})
                    }
                })
            }
        }
    }
}

export const shareHandler = (dispatcher,url) => {
    window.navigator.clipboard.writeText(url)
    dispatcher({
        type: "OPEN_SNACK",
        payload: {"isSnackOpen": true, snackType: "success", snackMessage: words.vacancyItem.shared}
    })
}

export const copyDeadline = (dispatcher, deadline) => {
    window.navigator.clipboard.writeText(deadline)
    dispatcher({
        type: "OPEN_SNACK",
        payload: {"isSnackOpen": true, snackType: "success", snackMessage: words.vacancyItem.copyDeadline}
    })
}


const thisMonth = new Date().getMonth() + 1 >= 10 ? (new Date().getMonth() + 1).toString().trim() : "0".concat((new Date().getMonth() + 1).toString()).trim()
const today = new Date().getDate() >= 10 ? new Date().getDate().toString().trim() : "0".concat((new Date().getDate()).toString()).trim()
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).getDate() >= 10 ? new Date(new Date().setDate(new Date().getDate() - 1)).getDate().toString().trim() : "0".concat((new Date(new Date().setDate(new Date().getDate() - 1)).getDate()).toString()).trim()
export const dateCorrector = (date) => {
    let correctedDate = "";
    if(today==="01"){
        correctedDate = date.split("-")[2] === today ? words.vacancyItem.today : date.split("-")[2] === yesterday ? words.vacancyItem.yesterday : date.split("-")[1] + "/" + date.split("-")[2];
    }
    else if (date.split("-")[1] === thisMonth) {
        correctedDate = date.split("-")[2] === today ? words.vacancyItem.today : date.split("-")[2] === yesterday ? words.vacancyItem.yesterday : date.split("-")[1] + "/" + date.split("-")[2];
    }
    else{
        correctedDate =  date.split("-")[1] + "/" + date.split("-")[2]
    }

    return correctedDate;

}

export const setDocTitle = (data)=>{
    // console.log("")
    document.title = data;
}
export const setDocDesc = (data)=>{
    // console.log("")
    data = data.replace(/<[^>]*>/g, '').replace(/\n/g,' ').replace(/\t/g,' ').substring(0,160)
    document.querySelector('meta[name="description"]').setAttribute("content", data);
}






