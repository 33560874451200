import { useState, useEffect, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const images = [
    "https://bakujobs.az/img/cvPreviews/1-.jpg",
    "https://bakujobs.az/img/cvPreviews/2-.jpg",
    "https://bakujobs.az/img/cvPreviews/11437-.jpg",
    "https://bakujobs.az/img/cvPreviews/9396-.jpg",
    "https://bakujobs.az/img/cvPreviews/9585-.jpg",
    "https://bakujobs.az/img/cvPreviews/11453-.jpg",
    "https://bakujobs.az/img/cvPreviews/10272-.jpg",
    "https://bakujobs.az/img/cvPreviews/11441-.jpg",
];
const lang = window.localStorage.getItem("pageLang")?window.localStorage.getItem("pageLang"):"az"
const TemplatesCarousel = () => {
    const [index, setIndex] = useState(0);
    const scrollRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        startAutoScroll();
        return () => stopAutoScroll();
    }, []);

    const startAutoScroll = () => {
        stopAutoScroll(); // Reset interval to prevent overlaps
        intervalRef.current = setInterval(() => {
            setIndex((prev) => {
                const newIndex = (prev + 1) % images.length;
                scrollToIndex(newIndex);
                return newIndex;
            });
        }, 2500);
    };

    const stopAutoScroll = () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
    };

    const handlePrev = () => {
        stopAutoScroll();
        setIndex((prev) => {
            const newIndex = prev === 0 ? images.length - 1 : prev - 1;
            scrollToIndex(newIndex);
            startAutoScroll();
            return newIndex;
        });
    };

    const handleNext = () => {
        stopAutoScroll();
        setIndex((prev) => {
            const newIndex = (prev + 1) % images.length;
            scrollToIndex(newIndex);
            startAutoScroll();
            return newIndex;
        });
    };

    const scrollToIndex = (i) => {
        scrollRef.current?.scrollTo({
            left: i * scrollRef.current.clientWidth,
            behavior: "smooth",
        });
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "70%",
                maxWidth: 400,
                // height: 570,
                overflow: "visible",
                borderRadius: 2,
                // boxShadow: 3,
                margin: "auto",
                padding: "5px 60px",

            }}
        >
            {/* Scrollable Images */}
            <Box
                ref={scrollRef}
                sx={{
                    display: "flex",
                    overflowX: "auto",
                    scrollSnapType: "x mandatory",
                    scrollbarWidth: "none",
                    boxShadow: "0px 0px 12px 4px rgba(217,217,217,1)",

                    "-ms-overflow-style": "none",
                    "&::-webkit-scrollbar": { display: "none" },
                }}
            >
                {images.map((src, i) => (
                    <Box
                        key={i}
                        component="img"
                        src={src.replace(".jpg",lang+".jpg")}
                        sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            flexShrink: 0,
                            scrollSnapAlign: "start",
                        }}
                    />
                ))}
            </Box>

            {/* Navigation Buttons */}
            <IconButton
                onClick={handlePrev}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: 10,
                    transform: "translateY(-50%)",
                    bgcolor: "rgba(204,176,255,0.6)",
                    "&:hover": {bgcolor: "rgb(152,118,255)"},
                }}
            >
                <ChevronLeft />
            </IconButton>

            <IconButton
                onClick={handleNext}
                sx={{
                    position: "absolute",
                    top: "50%",
                    right: 10,
                    transform: "translateY(-50%)",
                    bgcolor: "rgba(204,176,255,0.6)",
                    "&:hover": {bgcolor: "rgb(152,118,255)"},
                }}
            >
                <ChevronRight />
            </IconButton>
        </Box>
    );
};

export default TemplatesCarousel;
