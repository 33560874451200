import { useState } from "react";
import {Button} from "@mui/material";
// import { format } from "prettier/standalone";
// import htmlParser from "prettier/parser-html";

function AdminHtmlEditor() {
    const [code, setCode] = useState("<h1>Hello, World!</h1>");
    const [search, setSearch] = useState("");

    const handleDownload = () => {
        const blob = new Blob([code], { type: "text/html" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "edited.html";
        link.click();
    };

    const handleFormat = () => {
        try {
            // const formatted = format(code, { parser: "html", plugins: [htmlParser] });
            // setCode(formatted);
        } catch (error) {
            console.error("Formatting error", error);
        }
    };

    const handleSearch = () => {
        const textarea = document.getElementById("code-editor");
        const index = code.indexOf(search);
        if (index !== -1) {
            textarea.focus();
            textarea.setSelectionRange(index, index + search.length);
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "70vh", padding: "16px", backgroundColor: "#f3f4f6" }}>
            <div style={{ display: "flex", flexGrow: 1 }}>
                <div style={{ width: "50%", padding: "8px" }}>
          <textarea
              id="code-editor"
              style={{ width: "100%", height: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
          />
                </div>
                <div style={{ width: "50%", padding: "8px", borderLeft: "1px solid #ccc" }}>
                    <iframe
                        title="preview"
                        style={{ width: "100%", height: "100%", border: "1px solid #ccc", borderRadius: "4px" }}
                        srcDoc={code}
                    />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "8px", marginTop: "8px" }}>
                <Button
                    style={{ padding: "8px 16px", backgroundColor: "#007bff", color: "white", borderRadius: "4px", border: "none", cursor: "pointer" }}
                    onClick={handleDownload}
                >
                    Download HTML
                </Button>
                {/*<button*/}
                {/*    style={{ padding: "8px 16px", backgroundColor: "#28a745", color: "white", borderRadius: "4px", border: "none", cursor: "pointer" }}*/}
                {/*    onClick={handleFormat}*/}
                {/*>*/}
                {/*    Format HTML*/}
                {/*</button>*/}
            </div>
        </div>
    );
}

export default AdminHtmlEditor;
