import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {words} from "../words";

const CustomDialog = ({wordsWithLang, open, onClose, onYes, header,question}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                {question}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {wordsWithLang.cvApp.cancel}
                </Button>
                <Button onClick={onYes} color="secondary">
                    {wordsWithLang.cvApp.yes}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
